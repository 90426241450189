import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from "reactjs-localstorage";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { readyException } from 'jquery';





function HbMeter() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  $('#example').DataTable().destroy();

  $(document).ready(function () {


    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
      responsive: true
    });
  });

  useEffect(() => {
    document.title = "All HB Meter Request";
    getAllUsersData();
  }, [1]
  );
  /************Get all register user********************** */
  let getAllUsersData = async () => {
    let datas = { utype: "anm_staff" };
    var url = "";
    if (reactLocalStorage.get('userLoginType') == 'Admin') {
      url = 'admin/getAllHbMeterReq';
    } else {
      url = 'chc/getAllHbMeterReq';
      datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
    }

    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        console.log(ress.data);

        setUserData(ress.data.Rdata);

        setIsLoading(false);
      });

  }
  /******************Delete user****************************** */
  let deleteData = async (vals) => {
    const dataDel = { "user_id": vals };

    const res = await axios.post(API_URL + 'chc/deleteData', dataDel)
      .then(ress => {
        if (ress.status == "200") {
          swal("User Deleted");
        } else {
          swal("Some error occured, please try after some time. ");
        }
        getAllUsersData();

      });

  }
  /************Update CHC Activation status ********************** */
  let updateStatus = async (vals, status) => {
    const dataStatusUpdate = { 'record_id': vals, 'status': status }
    const res = await axios.post(API_URL + 'admin/updateAcceptHbMeterReq', dataStatusUpdate)
      .then(ress => {
        if (ress.status == '200') {
          // swal("Status Changed.");
          swal("Request accepted successfully.");
          getAllUsersData();
        }

      });

  }
  /******************Redirecting to edit page***************************** */
  let editUser = (data) => {
    //console.warn("id="+data);
    return navigat('/editChcReg/' + data);
  }


  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">


              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">All Hemoglobinometer & Other Item Request List</h4>
                  
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered display responsive nowrap" id="example">
                          {/* <table className="table table-bordered display  nowrap" id="example"> */}
                          <thead>
                            <tr>
                              {/* <th className="nosort"> S.No.</th> */}
                              <th></th>
                              <th> Req Status </th>
                              <th> Sub Center </th>
                              <th> CHC/PHC </th>
                              <th> User Type </th>
                              <th> Name </th>
                              <th> Mobile </th>

                              <th> Requested Item</th>
                              <th> Request </th>
                              <th> User Status </th>

                              <th> Block </th>
                              <th> District </th>
                              <th> Requested on </th>
                              <th className="nosort"> Device Received Date </th>
                            </tr>
                          </thead>
                          <tbody>

                            {userData.map((udata, index) => (
                              <tr key={index} >
                                <td></td>
                                <td>
                                  <button className={(udata.req_status == 1) ? 'btn btn-danger btn-sm cust_btn' : (udata.req_status == 2) ? 'btn btn-success btn-sm cust_btn' : (udata.req_status == 3) ? 'btn btn-warning btn-sm cust_btn' : ''} onClick={() => { if (udata.req_status == 1) { if (window.confirm(udata.req_status == 1 ? 'Are you sure to Accept Request?' : '')) { updateStatus(udata.mr_r_id, udata.status == 1 ? '1' : '') } } }}  >{(udata.req_status == 1) ? 'Pending' : (udata.req_status == 2) ? 'Accepted' : (udata.req_status == 3) ? 'Completed' : ''}</button>
                                </td>
                                <td> {udata.sub_center} </td>
                                <td> {udata.chc_name} </td>
                                <td> {udata.user_type != null ? udata.user_type.toUpperCase() : ""}</td>
                                <td> {udata.name}</td>
                                <td> {udata.mobile_no}</td>

                                <td> {udata.item} </td>
                                <td>{udata.request_desc}</td>

                                <td>
                                  <button className={udata.status ? 'btn btn-success btn-sm cust_btn' : 'btn btn-danger btn-sm cust_btn'}   >{udata.status ? 'Active' : 'Inactive'}</button>
                                </td>

                                <td> {udata.block_name}</td>
                                <td> {udata.district_name} </td>
                                <td><Moment format="DD-MM-YYYY">{udata.created_on}</Moment> </td>
                                <td>{udata.device_received_date}</td>
                              </tr>

                            ))}

                          </tbody>
                        </table>
                      </div>
                   
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>

    </div>




  );

}

export default HbMeter;