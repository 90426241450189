import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { reactLocalStorage } from "reactjs-localstorage";
import { API_URL } from "../constant";
import swal from "sweetalert";
const ResetModal = (props) => {
  // const [loginModalShow, setLoginModalShow] = useState(props.showModal);
  /******************pass & Cpass************************** */
  const [passData, setPassData] = useState();
  const [cpassData, setCpassData] = useState();
  /********************************************************** */

  const submitUser = async (events) => {

    events.preventDefault();
    if (validate(events)) {
      const data = new FormData(events.target);

      let formData = {

        'mobile': data.get('mobile'),
        'new_password': data.get('password'),

      };

      //console.log(formData); 

      const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      const getResps = await fetch(API_URL + 'users/restPassword', regDatas);
      const getDatas = await getResps.json();

      if (getDatas.status == "200") {
        props.close();
      }
      if((reactLocalStorage.get("userLoginType") == "District")){
        swal("Password updated successfully.");
      }else{
        swal(getDatas.msg);
      }
      
    }
  }


  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    // console.log(data.get);
    let validat = true;

    if (data.get('password') == '') {
      swal('Please enter password');
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('cpassword') == '') {
      swal('Please enter confirm password');
      return validat = false;
    } else {
      validat = true;
    }

    if (passData != cpassData) {
      swal("Password  and confirm pasword not matched");
      return validat = false;
    } else {
      validat = true;
    }
    
    return validat;
  }


  // console.log("PROPS in MODAL", props);
  return (
    <>
      <Modal
        show={props.show}
        cancel={props.close}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header closeButton onClick={props.close} >
          <Modal.Title id="contained-modal-title-vcenter">Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure to reset the password of {props.data} ?</h4>
          <form className="form-sample" onSubmit={submitUser}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-12">Password *</label>
                  <div className="col-sm-12">
                    <input type="hidden" name="mobile" value={props.data} required />
                    <input type="password" className="form-control" id="exampleInputPassword1" onChange={(e) => { setPassData(e.target.value); }} minLength="4" maxLength="10" name="password" placeholder="Password" required />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-12">Confirm Password *</label>
                  <div className="col-sm-12">
                    <input type="password" className="form-control" id="exampleInputConfirmPassword1" onChange={(e) => { setCpassData(e.target.value) }} minLength="4" maxLength="10" name="cpassword" placeholder="Confirm Password" required />
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary mr-2">Submit</button>
            <button type="button" onClick={props.close} className="btn btn-primary mr-2">Cancel</button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.close}>Cancel</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResetModal;
