import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import axios from 'axios';
import { API_URL } from "../constant";
import Moment from 'react-moment';
function Hor_header() {
  const navigate = useNavigate();
  const isLogin = reactLocalStorage.get('isLogin');
  useEffect(() => {
    if (typeof isLogin === 'undefined') {
      navigate('/')
    }
  });

  const logout = () => {
    reactLocalStorage.clear();
    return navigate('/');
  }


  useEffect(() => {
    getAllSettingData();
  }, [1]
  );
  /************Get all state data********************** */
  const [appSetting, setAppSetting] = useState([]);

  let getAllSettingData = async () => {

    const res = await axios.post(API_URL + 'admin/getAllSettingData')
      .then(ress => {
        setAppSetting(ress?.data?.Rdata[0]);

      });

  }



  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/dashboard"><img src={process.env.PUBLIC_URL + "/images/logo.png"} style={{
          "maxWidth": "44%",
          "height": "54px"
        }} alt="logo" /></Link>
        <Link className="navbar-brand brand-logo-mini" to="/dashboard"><img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="logo" /></Link>
        <button className="navbar-toggler navbar-toggler align-self-center d-none d-lg-flex" type="button" data-toggle="minimize">
          <span className="typcn typcn-th-menu"></span>
        </button>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <ul className="navbar-nav mr-lg-2">
          <li className="nav-item upMr d-none d-lg-flex">

            <span className="nav-link fnt">
              Updated On <Moment format='D MMM YYYY h:mm A'>{appSetting?.updated_on}</Moment>
            </span>
          </li>
          <li className="  d-none d-lg-flex">
            <a className="nav-link" href={appSetting.link}>
              <img className="gplay" src={process.env.PUBLIC_URL + "/images/gplay.png"} alt="images" />
            </a>
          </li>
          {/* <li className="nav-item  d-none d-lg-flex">
      <a className="nav-link" href="#">
      Shortcut3
      </a>
    </li> */}
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          {/* <li className="nav-item d-none d-lg-flex  mr-2">
      <a className="nav-link" href="#">
      Shortcut4
      </a>
    </li> */}





          {/* <li className="nav-item dropdown d-flex">
      <a className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center" id="messageDropdown" href="#" data-toggle="dropdown">
        <i className="typcn typcn-message-typing"></i>
        <span className="count bg-success">2</span>
      </a>
      <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
        <p className="mb-0 font-weight-normal float-left dropdown-header">Messages</p>
        <a className="dropdown-item preview-item">
          <div className="preview-thumbnail">
            <img src={process.env.PUBLIC_URL+"images/faces/face4.jpg"} alt="image" className="profile-pic"/>
          </div>
          <div className="preview-item-content flex-grow">
            <h6 className="preview-subject ellipsis font-weight-normal">David Grey
            </h6>
            <p className="font-weight-light small-text mb-0">
              The meeting is cancelled
            </p>
          </div>
        </a>
        <a className="dropdown-item preview-item">
          <div className="preview-thumbnail">
            <img src={process.env.PUBLIC_URL+"images/faces/face2.jpg"} alt="image" className="profile-pic" />
          </div>
          <div className="preview-item-content flex-grow">
            <h6 className="preview-subject ellipsis font-weight-normal">Tim Cook
            </h6>
            <p className="font-weight-light small-text mb-0">
              New product launch
            </p>
          </div>
        </a>
        <a className="dropdown-item preview-item">
          <div className="preview-thumbnail">
            <img src={process.env.PUBLIC_URL+"images/faces/face3.jpg"} alt="image" className="profile-pic" />
          </div>
          <div className="preview-item-content flex-grow">
            <h6 className="preview-subject ellipsis font-weight-normal"> Johnson
            </h6>
            <p className="font-weight-light small-text mb-0">
              Upcoming board meeting
            </p>
          </div>
        </a>
      </div>
    </li> */}


          {/* <>Bell icon</> */}
          {/* <li className="nav-item dropdown  d-flex">
      <a className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center" id="notificationDropdown" href="#" data-toggle="dropdown">
        <i className="typcn typcn-bell mr-0"></i>
        <span className="count bg-danger">2</span>
      </a>
      <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
        <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
        <a className="dropdown-item preview-item">
          <div className="preview-thumbnail">
            <div className="preview-icon bg-success">
              <i className="typcn typcn-info-large mx-0"></i>
            </div>
          </div>
          <div className="preview-item-content">
            <h6 className="preview-subject font-weight-normal">Application Error</h6>
            <p className="font-weight-light small-text mb-0">
              Just now
            </p>
          </div>
        </a>
        <a className="dropdown-item preview-item">
          <div className="preview-thumbnail">
            <div className="preview-icon bg-warning">
              <i className="typcn typcn-cog mx-0"></i>
            </div>
          </div>
          <div className="preview-item-content">
            <h6 className="preview-subject font-weight-normal">Settings</h6>
            <p className="font-weight-light small-text mb-0">
              Private message
            </p>
          </div>
        </a>
        <a className="dropdown-item preview-item">
          <div className="preview-thumbnail">
            <div className="preview-icon bg-info">
              <i className="typcn typcn-user-outline mx-0"></i>
            </div>
          </div>
          <div className="preview-item-content">
            <h6 className="preview-subject font-weight-normal">New user registration</h6>
            <p className="font-weight-light small-text mb-0">
              2 days ago
            </p>
          </div>
        </a>
      </div>
    </li>  */}



          <li className="nav-item nav-profile dropdown">
            <a className="nav-link dropdown-toggle  pl-0 pr-0" href="#" data-toggle="dropdown" id="profileDropdown">
              <i className="typcn typcn-user-outline mr-0"></i>
              <span className="nav-profile-name">{reactLocalStorage.get('user_name')}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              {/* <a className="dropdown-item">
        <i className="typcn typcn-cog text-primary"></i>
        Settings
        </a> */}
              <button className="dropdown-item" onClick={logout}>
                <i className="typcn typcn-power text-primary"></i>
                Logout
              </button>
            </div>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="typcn typcn-th-menu"></span>
        </button>
      </div>
    </nav>
  );
}
export default Hor_header;   