import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { API_URL } from "../constant";
import swal from "sweetalert";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { param, readyException } from 'jquery';


const ViewInv = (props) => {
  // const [loginModalShow, setLoginModalShow] = useState(props.showModal);

  const [userData, setUserData] = useState([]);
  //setUserData(props.data);
  //console.log(props.data.length);

  useEffect(() => {

    // if(props.data!=undefined){
    // submitUser(props.data);
    // }
  }, [1]
  );



  /********************************************************** */
  const navigat = useNavigate();


  const mainPage = () => {
    window.location.reload();
    // return navigat('/view_item_list');

  }


  // console.log("PROPS in MODAL", props);
  return (
    <>
      <Modal
        show={props.show}
        /*cancel={props.close}*/
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header closeButton onClick={props.close} >
          <Modal.Title id="contained-modal-title-vcenter">Inventory list of {props.invUser}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4></h4>

          <div className="row">

            <div className="col-md-12">
              <div className="form-group row">
                <div className="table-responsive pt-3">
                  {/* <table className="table table-bordered display responsive nowrap" id="example"> */}
                  <table className="table table-bordered display  nowrap" id="example">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Available Quantity</th>
                        {/* <th>Issued Quantity</th>
                            <th>Consumed Quantity</th>
                            <th>Wasted Quantity</th>
                         
                               <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>

                      {props.data.map((udata, index) => (
                        <tr key={index}>
                          <td> {udata.item_name}</td>
                          <td> {udata.avail_qty}</td>
                          {/* <td> {udata.issued_items}</td>		
                                 <td> {udata.used}</td>		
                                 <td> {udata.waste}</td>		 */}

                        </tr>
                      )
                      )}

                    </tbody>
                  </table>
                </div>

              </div>
            </div>



          </div>

        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.close}>Cancel</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewInv;
