import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
/************get parameter from url******************* */
import { useParams, useNavigate } from 'react-router-dom';
/*******************Date Picker**************************** */
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { reactLocalStorage } from 'reactjs-localstorage';
function AddUser() {

  /********************************************* */
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [chcData, setChcData] = useState([]);
  const [villageData, setVillageData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);

  const [subcenterData, setSubcenterData] = useState([]);

  const [userFormData, setUserFormData] = useState([]);



  /******************pass & Cpass************************** */
  //  const [passData,setPassData] = useState();
  //  const [cpassData,setCpassData] = useState();

  /********************medical team Hide Show************************* */

  const [hideDiv, setHideDiv] = useState(false);
  const [hideQuest, setHideQuest] = useState(false);

  const [hideDivAnm, setHideDivAnm] = useState(false);
  /*****************Date****************************** */
  const [startDate, setStartDate] = useState(new Date());

  const [lTCHO, setLTCHO] = useState(0);

  /****************************radio************************************* */
  const [dhAvi, setDhAvi] = useState(true);
  const [dhFun, setDhFun] = useState(true);
  const [dhTri, setDhTri] = useState(true);
  const [consum, setConsum] = useState(true);


  /********************back***************************************** */
  const navegate = useNavigate();
  let clickCancel = () => {
    return navegate('/all_users');
  }
  /*********************get parameter from url*********************************** */
  const params = useParams();
  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    document.title = 'Edit User';

    getDataOfUser();
    getStateData();

  }, [1]
  );

  //console.log(userFormData.gender);

  let getDataOfUser = async () => {
    const data = { 'user_id': params.id };
    var res = await axios.post(API_URL + "admin/getUserDetails", data).then((ress) => {
      //  console.warn(ress.data.Rdata[0]);
      setUserFormData(ress.data.Rdata[0]);
      getDistrictList(ress.data.Rdata[0].state_id);
      getBlock(ress.data.Rdata[0].district_id);
      getChc(ress.data.Rdata[0].block_id);
      getSubcenter(ress.data.Rdata[0].chc_phc_id);

      // (ress.data.Rdata[0].user_type == 'anm') ? setHideDivAnm(true) : setHideDivAnm(false);
      (ress.data.Rdata[0].dh_available == 'yes') ? setDhAvi(true) : setDhAvi(false);
      (ress.data.Rdata[0].dh_functional == 'yes') ? setDhFun(true) : setDhFun(false);
      (ress.data.Rdata[0].dh_training == 'yes') ? setDhTri(true) : setDhTri(false);
      //console.log(dhAvi,dhFun,dhTri);
      (ress.data.Rdata[0].dh_available == 'yes') ? setHideQuest(true) : setHideQuest(false);
      (ress.data.Rdata[0].consumable == 'yes') ? setConsum(true) : setConsum(false);

      if (ress.data.Rdata[0].user_type == 'LT' || ress.data.Rdata[0].user_type == 'CHO') {
        // setLTCHO(1);
      }

    });

  }
  //console.log(userFormData);


  let handleUserType = (e) => {
    //getting state name   

    //on select of medical team show two more feilds
    if (e.target.value === 'medicalteam') {
      setHideDiv(true);
    } else {
      setHideDiv(false);
      //resetting values
      setSchoolData([]);
      setVillageData([]);
    }
    // if (e.target.value === 'anm') {
    //   setHideDivAnm(true);
    // } else {
    //   setHideDivAnm(false);
    //   //resetting values
    //   setSubcenterData([]);
    // }
    /*if(e.target.value=='LT' || e.target.value=='CHO'){
      setLTCHO(1); 
   }else{
     setLTCHO(0); 
   }
     */

  }


  let hideShow = (e) => {
    if (e === 'yes') {
      setHideQuest(true);
    } else {
      setHideQuest(false);
    }
  }


  /********State*********** */
  let getStateData = async () => {
    const regDataState = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' }
    }
    const getState = await fetch(API_URL + 'admin/state', regDataState);
    const getdatastate = await getState.json();
    setStateData(getdatastate);
  }

  /********District based on state*********** */
  let getDistrictList = async (e) => {
    setDistrictData([]);
    let state_id = e;
    const regDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    }
    const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
    const getdataDistrict = await getDistrict.json();
    // console.log(getdataDistrict.Rdata);
    if (getdataDistrict.Rdata.length > 0) {
      setDistrictData(getdataDistrict.Rdata);
    }
  }
  /********Block based on district*********** */
  let getBlock = async (e) => {
    setBlockData([]);
    let district_id = e;
    //console.log(district_id);
    const reDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'district_id': district_id })
    }
    const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
    const getBlockData = await getBlock.json();
    //console.log(getBlockData);
    if (getBlockData.Rdata.length > 0) {
      setBlockData(getBlockData.Rdata);
    }
    // else{
    //   const data=[{'id':'','name':''}];
    //   setBlockData(data);
    // }
  }



  /****************Get Chc ,school,village based on block**************************** */

  let getChc = async (e) => {
    setChcData([]);
    setVillageData([]);
    setSchoolData([]);
    let block_id = e;
    //console.log(district_id);
    const reDataBlock = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'block_id': block_id })
    }
    /************chc************** */
    const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
    const getChcData = await getChc.json();
    //console.log(getBlockData);
    if (getChcData.Rdata.length > 0) {
      setChcData(getChcData.Rdata);
    }
    /***********village************** */
    /* const getVillage = await fetch(API_URL+"admin/getVillage",reDataBlock);
     const getVillageData = await getVillage.json();
     //console.log(getBlockData);
     if(getVillageData.Rdata.length > 0){
     setVillageData(getVillageData.Rdata);
   
     }   */

    /***********School************** */
    /*const getSchool = await fetch(API_URL+"admin/getSchool",reDataBlock);
    const getSchoolData = await getSchool.json();
    //console.log(getBlockData);
    if(getSchoolData.Rdata.length > 0){
    setSchoolData(getSchoolData.Rdata);
    } */
    //return true;
  }


  /*********************get sub center based on chc************************************** */

  let getSubcenter = async (e) => {
    setSubcenterData([]);
    let chc_phc_id = e;
    //console.log(district_id);
    const reDatachc = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'chc_phc_id': chc_phc_id })
    }
    const getSubCet = await fetch(API_URL + "admin/sub_center", reDatachc);
    const getSbCData = await getSubCet.json();
    //console.log(getBlockData);
    if (getSbCData.Rdata.length > 0) {
      setSubcenterData(getSbCData.Rdata);
    }

  }


  /*****************submit******************************* */
  const submitUser = async (events) => {
    events.preventDefault();
    if (validate(events)) {
      const data = new FormData(events.target);
      //  var schoolDa='';  var villageDa='';
      var schoolDa = 0; var villageDa = 0;
      var subCenterDa = 0;
      /*
      if(data.get('school')=='Select'){
           schoolDa = 0;
        }else{
           schoolDa=data.get('school');
        }
        if(data.get('village')=='Select'){
           villageDa = 0;
        }else{
           villageDa=data.get('school');
        }
       */
      if (data.get('sub_center') === 'Select') {
        subCenterDa = 0;
      } else {
        subCenterDa = data.get('sub_center');
      }

      let formData = {
        'user_type': data.get('user_type'),
        'name': data.get('name'),
        'gender': data.get('gender'),
        'dob': data.get('dob'),
        'mobile_no': data.get('mobile_no'),
        'state_id': data.get('state'),
        'district_id': data.get('district'),
        'block_id': data.get('block'),
        'chc_phc_id': data.get('chc'),
        'village_id': villageDa,
        'school_id': schoolDa,
        'sub_center_id': subCenterDa,
        'user_id': params.id,
        'dh_available': data.get('dh_available'),
        'dh_functional': data.get('dh_functional'),
        'dh_training': data.get('dh_training'),
        'consumable': data.get('consumable')
      };
      //   console.log(formData); 

      const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      const getResps = await fetch(API_URL + 'admin/editUserData', regDatas);
      const getDatas = await getResps.json();

      swal(getDatas.msg);

    }
  }


  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    // console.log(data.get);
    let validat = true;


    if (data.get('name') == '') {
      swal('Please enter name');
      validat = false;
    } else {
      let nameRegExp = "^[a-zA-Z]";
      //let testemail= emailRegExp.test(data.get('email'));
      let testname = data.get('name').match(nameRegExp);
      if (testname) {
        validat = true;
      } else {
        swal('invalid name');
        return validat = false;
      }
    }

    if (data.get('mobile_no') == '') {
      swal('Please enter mobile');
      return validat = false;
    } else {
      let mobRegExp = "^[0-9]{10}";
      //let testemail= emailRegExp.test(data.get('email'));
      let mobname = data.get('mobile_no').match(mobRegExp);
      if (mobname) {
        validat = true;
      } else {
        swal('Invalid mobile');
        return validat = false;
      }
    }
    if (data.get('state') == 'Select') {
      swal('Please select state');
      return validat = false;
    } else {
      validat = true;
    }

    if (data.get('district') == "Select") {
      swal('Please select district');
      return validat = false;
    } else {
      validat = true;
    }
    if (lTCHO != 1) {
      if (data.get('block') == "Select") {
        swal('Please select block');
        return validat = false;
      } else {
        validat = true;
      }
    }
    if (lTCHO != 1) {
      if (data.get('chc') == "Select") {
        swal('Please select Chc');
        return validat = false;
      } else {
        validat = true;
      }
    }
    if (data.get('user_type') == "Select") {
      swal("Please select user type");
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('user_type') == "anm") {
      if (data.get('sub_center') == 'Select') {
        // disable on 14-09-2022
        //  swal('Please select Sub Center');
        //  return validat = false;  
        return validat = true;
      }
    } else {
      validat = true;
    }
    //  if(data.get('rch_id')==""){
    //   swal("Please enter RCH Id");
    //   return validat = false;
    //  }else{
    //   validat = true;
    //  }

    return validat;
  }


  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Update ANM/Medical Team Registration</h4>
                    <form className="form-sample" onSubmit={submitUser}>
                      <p className="card-description">

                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12"> User Type *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="user_type" value={userFormData.user_type} onChange={(e) => { setUserFormData({ "user_type": e.target.value }); handleUserType(e); }}>
                                <option value="">Select</option>
                                <option value="anm">ANM</option>
                                <option value="medicalteam">RBSK Medical Team</option>
                                <option value="nurse">Staff Nurse</option>
                                <option value="LT">LT</option>
                                <option value="CHO">CHO</option>
                                <option value="others">Others</option>
                                {/* <option value="asha">Asha</option> */}
                              </select>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">RCH Id *</label>
                          <div className="col-sm-12">
                            <input name="rch_id" type="text"  className="form-control" required />
                          </div>
                        </div>
                      </div> */}

                      </div>

                      <p className="card-description">
                        Personal info
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Name *</label>
                            <div className="col-sm-12">
                              <input name='name' type="text" className="form-control" value={userFormData.name} onChange={e => setUserFormData({ name: e.target.value })} required />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Mobile Number *</label>
                            <div className="col-sm-12">
                              <input name="mobile_no" type="tel" pattern="[0-9]{10}" className="form-control" value={userFormData.mobile_no} onChange={e => setUserFormData({ mobile_no: e.target.value })} required />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12"> Gender *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="gender" value={userFormData.gender} onChange={(e) => { setUserFormData({ "gender": e.target.value }); }}>
                                <option value="">Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Date of Birth *</label>
                            <div className="col-sm-12">
                              {/* <input name="dob" type="date"className="form-control" placeholder="dd/mm/yyyy" required/> */}
                              <DatePicker
                                selected={startDate}
                                onChange={(e) => { setStartDate(e); setUserFormData({ "dob": e }); }}
                                name="dob"
                                dateFormat="dd-MM-yyyy"
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                                minDate={new Date('01-01-1950')}
                                maxDate={new Date()}
                                placeholderText="Date of Birth"
                                className="form-control"
                                id="dobpicker"
                                tabIndex="5"
                                onKeyDown={e => e.preventDefault()}
                                value={userFormData.dob}

                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <p className="card-description">
                        Geographical Data
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select State *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="state" onChange={(e) => { setUserFormData({ "state": e.target.value }); getDistrictList(e.target.value); }} value={userFormData.state_id} required>
                                <option>Select</option>
                                {stateData.map(states => (
                                  <option value={states.id}>{states.state}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select District *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="district" onChange={(e) => { setUserFormData({ "district": e.target.value }); getBlock(e.target.value); }} value={userFormData.district_id} required>
                                <option>Select</option>
                                {districtData.map(district => (
                                  ((reactLocalStorage.get("userLoginType") == "Chc") || (reactLocalStorage.get("userLoginType") == "Block") || (reactLocalStorage.get("userLoginType") == "District")) ? ((reactLocalStorage.get("district") == district.id) ? <option value={district.id}>{district.name} </option> : "") : <option value={district.id}>{district.name} </option>
                                  //  <option value={district.id}>{district.name}  { district.code } </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select Block *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="block" onChange={(e) => { setUserFormData({ "block": e.target.value }); getChc(e.target.value); }} value={userFormData.block_id} required>
                                <option>Select</option>
                                {blockData.map(blocks => (
                                  ((reactLocalStorage.get("userLoginType") == 'Chc') || (reactLocalStorage.get("userLoginType") == 'Block')) ? (reactLocalStorage.get("block") == blocks.id) ? <option value={blocks.id}>{blocks.name}</option> : "" : <option value={blocks.id}>{blocks.name}</option>
                                  //  <option value={blocks.id}>{blocks.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select CHC/PHC *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="chc" onChange={(e) => { setUserFormData({ "chc": userFormData.chc_phc_id }); getSubcenter(e.target.value); }} value={userFormData.chc_phc_id} required>
                                <option>Select</option>
                                {chcData.map(chc => (
                                  (reactLocalStorage.get("userLoginType") == 'Chc') ? (reactLocalStorage.get("userType") == chc.id) ? <option value={chc.id}>{chc.name}</option> : "" : <option value={chc.id}>{chc.name}</option>
                                  //  <option value={chc.id}>{chc.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                      </div>
                      {/* <div className="row" style={{ "display": (hideDivAnm) ? "" : "none" }}> */}
                      <div className="row" >
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select Sub Center </label>
                            <div className="col-sm-12">
                              <select className="form-control" name="sub_center" value={userFormData.sub_center_id} onChange={e => setUserFormData({ sub_center: e.target.value })} required>
                                <option>Select</option>
                                {subcenterData.map(sb => (
                                  <option value={sb.id}>{sb.sub_center}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="card-description">
                        Hemoglobinometer
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Digital hemoglobinometer is available *</label>
                            <div className="col-sm-12">

                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_available" id="membershipRadios1" onClick={(e) => { hideShow(e.target.value) }} value="yes" checked={(dhAvi) ? true : false} onChange={(e) => { setDhAvi(!dhAvi); setUserFormData({ "dh_available": e.target.value }) }} />
                                  Yes
                                  <i className="input-helper"></i></label>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_available" id="membershipRadios2" onClick={(e) => { hideShow(e.target.value) }} value="no" checked={(dhAvi) ? false : true} onChange={(e) => { setDhAvi(!dhAvi); setDhFun(false); setDhTri(false); setUserFormData({ "dh_available": e.target.value, "dh_functional": "no", "dh_training": "no" }) }} />
                                  No
                                  <i className="input-helper"></i></label>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-6" style={{ "display": (hideQuest) ? "" : "none" }}>
                          {/* <div className="col-md-6"> */}
                          <div className="form-group row">
                            <label className="col-sm-12">Digital hemoglobinometer is functional*</label>
                            <div className="col-sm-12">

                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_functional" id="membershipRadios3" value="yes" checked={(dhFun) ? true : false} onChange={(e) => { setDhFun(!dhFun); setUserFormData({ "dh_functional": e.target.value }) }} />
                                  Yes
                                  <i className="input-helper"></i></label>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_functional" id="membershipRadios4" value="no" checked={(dhFun ? false : true)} onChange={(e) => { setDhFun(!dhFun); setUserFormData({ "dh_functional": e.target.value }) }} />
                                  No
                                  <i className="input-helper"></i></label>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" >
                        {/* <div className="row" > */}
                        <div className="col-md-6" style={{ "display": (hideQuest) ? "" : "none" }}>
                          <div className="form-group row">
                            <label className="col-sm-12">Have you received training to use the hemoglobinometer*</label>
                            <div className="col-sm-12">

                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_training" id="membershipRadios5" value="yes" checked={(dhTri) ? true : false} onChange={(e) => { setDhTri(!dhTri); setUserFormData({ "dh_training": e.target.value }) }} />
                                  Yes
                                  <i className="input-helper"></i></label>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_training" id="membershipRadios6" value="no" checked={(dhTri) ? false : true} onChange={(e) => { setDhTri(!dhTri); setUserFormData({ "dh_training": e.target.value }) }} />
                                  No
                                  <i className="input-helper"></i></label>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Do you have Consumable item*</label>
                            <div className="col-sm-12">

                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="consumable" id="membershipRadios7" value="yes" checked={(consum) ? true : false} onChange={(e) => { setConsum(!consum); setUserFormData({ "consumable": e.target.value }) }} />
                                  Yes
                                  <i className="input-helper"></i></label>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="consumable" id="membershipRadios8" value="no" checked={(consum) ? false : true} onChange={(e) => { setConsum(!consum); setUserFormData({ "consumable": e.target.value }) }} />
                                  No
                                  <i className="input-helper"></i></label>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>


                      {/* <div className="row" style={{"display":(hideDiv)?"":"none"}}>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select School *</label>
                          <div className="col-sm-12">
                            <select className="form-control" name="school"  onChange={ (e) =>{setUserFormData({"school_id":userFormData.school_id});}} value={userFormData.school_id} required>
                            <option>Select</option>
                            {schoolData.map( sch => (
                               <option value={sch.id}>{sch.name}</option>
                             ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select Village *</label>
                          <div className="col-sm-12">
                            <select className="form-control" name="village" onChange={ (e) =>{setUserFormData({"village_id":userFormData.village_id});}} value={userFormData.village_id}  required>
                            <option>Select</option>
                            {villageData.map( vil => (
                               <option value={vil.id}>{vil.name}</option>
                             ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      
                    </div> */}


                      <button type="submit" className="btn btn-primary mr-2">Submit</button>
                      <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}

export default AddUser;