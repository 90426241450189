import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from "reactjs-localstorage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { readyException } from 'jquery';

import ResetModal from "./resetPasswordModel";


function AllUser() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [districtData, setDistrictData] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [mobid, setMobid] = useState();
  const [cbox, setCbox] = useState(0);
  const [lenCkBox, setLenCkBox] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const [endDate1, setEndDate1] = useState(null);

  const [typ, setTyp] = useState({ ftype: "all", id: "" });

  //  $('#example').DataTable().destroy();

  $(document).ready(function () {


    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
      responsive: true
    });
  });
  /***************useRef***************************** */
  const mainCheckBx = useRef([]);
  const rowCheckBx = useRef([]);




  /******************************************** */
  useEffect(() => {
    document.title = "All Users";
    getAllUsersData();
    getDistrictList();
  }, []);

  /*************************Get District Data************************ */

  let getDistrictList = async (e) => {

    let state_id = 1;
    const regDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    }
    const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
    const getdataDistrict = await getDistrict.json();
    // console.log(getdataDistrict.Rdata);
    if (getdataDistrict.Rdata.length > 0) {
      setDistrictData(getdataDistrict.Rdata);

    }

  }


  /************Get all register user********************** */
  let getAllUsersData = async () => {

    let datas = { ftype: typ.ftype, data: typ.id, fromDate: startDate1, toDate: endDate1 };
    console.log("dataas--->", datas)
    if (reactLocalStorage.get('userLoginType') == 'Admin') {
      const res = await axios.post(API_URL + 'admin/allRegUserData', datas)
        .then(ress => {
          setUserData(ress.data.Rdata);
          setIsLoading(false);
        });
    } else {

      datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
      const res = await axios.post(API_URL + 'chc/allRegUserData', datas)
        .then(ress => {
          console.log(ress.data);
          setUserData(ress.data.Rdata);
          setIsLoading(false);
        });
    }


    // console.log(res.data);EXIT;
  }
  /******************Delete user****************************** */
  let deleteData = async (vals) => {
    const dataDel = { "user_id": vals };
    const res = await axios.post(API_URL + 'admin/deleteData', dataDel)
      .then(ress => {
        if (ress.status == '200') {
          swal("User Deleted");
        } else {
          swal("Some error occured, please try after some time. ");
        }
        getAllUsersData();

      });

  }
  /************Update anm teacher Activation status ********************** */
  let updateStatus = async (vals, status) => {
    const dataStatusUpdate = { 'user_id': vals, 'status': status }
    const res = await axios.post(API_URL + 'admin/updateAnmTeacherStatus', dataStatusUpdate)
      .then(ress => {
        if (ress.status == '200') {
          swal("Status Changed.");
          getAllUsersData();
        }

      });

  }
  /******************Redirecting to edit page***************************** */
  let editUser = (data) => {
    //console.warn("id="+data);
    return navigat('/edit_user/' + data);
  }
  /************************************************ */

  const unSelect = () => {
    //  $(".selAll").prop("checked",false);
    //    $(".selBtn").prop("checked",false);
    mainCheckBx.current.checked = false;

    rowCheckBx.current.map((ckb) => {
      if (ckb != undefined) {
        ckb.checked = false;
      }
    });
  }

  const getCkBoxCnt = () => {
    // let lenOfCkBox =  $('.selAll:checkbox:checked').length;

    const lenOfCkBox = rowCheckBx.current.filter((ckb) => {

      if (ckb != undefined) {
        return ckb;
      }
    });


    let cnt = lenOfCkBox?.filter((x) => { return x.checked == true });

    setLenCkBox(cnt.length);
    // console.log("ckbox",cnt)

    if (cnt.length == 0) {
      // $(".selBtn").prop("checked",false);
      mainCheckBx.current.checked = false
      setCbox(0);
    }
  }

  const allCheckData = () => {
    // console.log( mainCheckBx);
    // console.log( rowCheckBx);
    //let v = rowCheckBx.current.filter((data)=>{ return data != null})
    let lenOfCkBox = 0;
    for (let i = 0; i < rowCheckBx.current.length; i++) {
      //console.log( rowCheckBx.current[i]);
      if (rowCheckBx.current[i] != undefined) {
        rowCheckBx.current[i].checked = !cbox;
        if (!cbox) {
          lenOfCkBox = !cbox
        }
      }
    }
    setCbox(!cbox);

    setLenCkBox(lenOfCkBox);
  }



  const approveSelectedUser = async () => {

    const chkval = rowCheckBx.current.filter((ckb) => {
      // console.log("ckb",ckb.value)
      if (ckb != undefined) {
        return ckb;
      }
    });
    //console.log("test",chkval);
    let cnt = chkval?.filter((x) => { return x.checked == true });
    const checkedVals = cnt.map((res) => {
      return res.value;
    });

    //  console.log(checkedVals);

    //console.log(checkedVals.join(","));
    const dataStatusUpdate = { 'user_ids': checkedVals, 'status': true }
    const res = await axios.post(API_URL + 'admin/updateManyAnmTeacherStatus', dataStatusUpdate)
      .then(ress => {
        if (ress.status == '200') {
          swal("Status Changed.");
          setCbox(0);
          setLenCkBox(0);
          unSelect();
          getAllUsersData();
        }

      });

  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;

  }

  const handleStartDateChange = (date) => {
    let datas = formatDate(date)
    setStartDate(date);
    setStartDate1(datas);
  };


  const handleEndDateChange = (date) => {
    let datas = formatDate(date)
    setEndDate(date);
    setEndDate1(datas)
  };

  const getFilterUserData = () => {
    setIsLoading(true);
    getAllUsersData();
  };

  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
          {isLoading ? (<Loader />) : (
            <>
              <div className="content-wrapper">
                <div class="row">
                  {(reactLocalStorage.get('userLoginType') == 'Admin')?<div className="col-md-2">
                    <div className="form-group row">
                      <label className="col-sm-12">Select District </label>
                      <div className="col-sm-12">
                        <select className="form-control" onChange={(e) => { setTyp({ ftype: 'district_id', id: e.target.value }); }} required>
                          <option value='0' >Select</option>
                          {districtData.map(district => (
                            ((reactLocalStorage.get("userLoginType") == "Chc") || (reactLocalStorage.get("userLoginType") == "Block") || (reactLocalStorage.get("userLoginType") == "District")) ? ((reactLocalStorage.get("district") == district.id) ? <option value={district.id}>{district.name} </option> : "") : <option value={district.id}>{district.name} </option>

                          ))}
                        </select>
                      </div>
                    </div>
                  </div>:""}
                  <div className="col-md-2">
                    <div className="form-group row">
                      <label className="col-sm-12">Select Date </label>
                      <div className="col-sm-12">
                        <DatePicker
                          selected={startDate}
                          onChange={handleStartDateChange}
                          name="dob"
                          dateFormat="dd-MM-yyyy"
                          dropdownMode="select"
                          showMonthDropdown
                          showYearDropdown
                          adjustDateOnChange
                          minDate={new Date('01-01-1950')}
                          maxDate={new Date()}
                          placeholderText="From"
                          className="form-control"
                          id="dobpicker"
                          tabIndex="5"
                          onKeyDown={e => e.preventDefault()}
                          autoComplete="off"

                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group row">
                      <label className="col-sm-12">End Date </label>
                      <div className="col-sm-12">
                        <DatePicker
                          selected={endDate}
                          onChange={handleEndDateChange}
                          name="dob"
                          dateFormat="dd-MM-yyyy"
                          dropdownMode="select"
                          showMonthDropdown
                          showYearDropdown
                          adjustDateOnChange
                          minDate={new Date('01-01-1950')}
                          maxDate={new Date()}
                          placeholderText="To"
                          className="form-control"
                          id="dobpicker"
                          tabIndex="5"
                          onKeyDown={e => e.preventDefault()}
                          autoComplete="off"

                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">

                    <div className="form-group row">

                      <div className="col-sm-12 export-fit">
                        <button className="btn btn-primary" onClick={getFilterUserData} >Go</button>

                      </div>
                    </div>

                  </div>
                </div>
                <div className="row">


                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">All User Lists</h4>


                        <>
                          <button className="btn btn-success btn-md" type="button" disabled={lenCkBox > 0 ? false : true} onClick={() => { if (window.confirm('Are you sure to activate selected user?')) { approveSelectedUser() } }} >Activate Selected Users</button>
                          <div className="table-responsive pt-3">
                            {/* <table className="table table-bordered display responsive nowrap" id="example"> */}
                            <table className="table table-bordered display  nowrap" id="example">

                              <thead>
                                <tr>
                                  {/* <th className="nosort"> S.No.</th> */}
                                  <th className="nosort"></th>
                                  <th className="nosort"><input ref={mainCheckBx} className="selBtn" type="checkbox" id="allSelect" name="allSelected" value="all" onClick={allCheckData} /> </th>
                                  <th className="nosort"> Status </th>
                                  <th className="nosort"> Action </th>
                                  <th> Name</th>
                                  <th> Mobile </th>
                                  <th> User Type </th>

                                  {/* <th> DOB </th>
                        <th> Gender </th>
                        <th> State </th> */}
                                  <th> District </th>
                                  <th> Block </th>
                                  <th> CHC/PHC </th>
                                  {/* <th> User Id </th> */}
                                  <th> Sub center </th>
                                  {/* <th> Village </th>
                        <th> School </th> */}
                                  <th> Reg. Date  </th>

                                </tr>
                              </thead>
                              <tbody>

                                {userData.map((udata, index) => (
                                  <tr key={index} >
                                    <td></td>
                                    <td> {udata.status ? "" : <input className="selAll" ref={(element) => { rowCheckBx.current[index] = element }} type="checkbox" id={index} name={"ckb" + index + udata.user_id} value={udata.user_id} onClick={getCkBoxCnt} />} </td>
                                    {/* <td> { index+1 }</td> */}
                                    <td>
                                      <button className={udata.status ? 'btn btn-success btn-sm cust_btn' : 'btn btn-danger btn-sm cust_btn'} onClick={() => { if (window.confirm(udata.status ? 'Are you sure to Inactivate user?' : 'Are you sure to Activate user?')) { updateStatus(udata.user_id, udata.status ? '0' : '1') } }}  >{udata.status ? 'Active' : 'Inactive'}</button>
                                    </td>

                                    <td>
                                      <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Delete User" onClick={() => { if (window.confirm('Delete the item?')) { deleteData(udata.user_id) } }}>
                                        <i className="typcn typcn-trash text-success"></i>
                                      </button>

                                      <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Edit User" onClick={() => { editUser(udata.user_id) }}>
                                        <i className="typcn typcn-edit  text-primary"></i>
                                      </button>
                                      <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Reset password" onClick={() => { setShowLogin(true); setMobid(udata.mobile_no); }}>
                                        <i className="typcn typcn-key-outline  text-warning"></i>
                                      </button>
                                    </td>
                                    <td> {udata.name}</td>
                                    <td> {udata.mobile_no}</td>
                                    <td> {udata.user_type != null ? udata.user_type.toUpperCase() : ""}</td>


                                    {/* <td> {udata.dob } </td>
                           <td> {udata.gender }</td>
                           <td> {udata.state }</td> */}
                                    <td> {udata.district_name} </td>
                                    <td> {udata.block_name}</td>
                                    <td> {udata.chc_name} </td>
                                    {/* <td> {udata.village_name } </td>
                           <td> {udata.school_name } </td> */}
                                    {/* <td> { udata.status?'Active':'Inactive'} </td> */}
                                    {/* <td> {udata.user_id }</td> */}
                                    <td> {udata.sub_center}</td>
                                    <td><Moment format="DD-MM-YYYY">{udata.created_on }</Moment> </td>

                                  </tr>

                                ))}

                              </tbody>
                            </table>
                          </div>
                        </>

                      </div>
                    </div>
                  </div>
                  <ResetModal show={showLogin} data={mobid} close={() => setShowLogin(false)} />

                </div>
              </div>

              <Footer />
            </>
          )}
        </div>

      </div>

    </div>




  );

}

export default AllUser;