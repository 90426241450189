import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules


import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { toExcel } from 'to-excel';

import $, { readyException } from 'jquery';


function AllPatient() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pages, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [totalData, setTotalData] = useState();

  /********************************************** */


  /*********************************************** */
  useEffect(() => {
    document.title = "Beneficiary List";
    getTotalPatientData();

    //getAllPatientData();
  }, [1]
  );



  useEffect(() => {

    getAllPatientData(pages);

  }, [pages]);


  /************Get all patient user********************** */
  let getAllPatientData = async (page) => {
    //setUserData([]); 
    let datas = { page: page, per_page: perPage };
    var url = "";
    if (reactLocalStorage.get('userLoginType') == 'Admin') {
      url = 'admin/allPatientData_server';
    } else {
      url = 'chc/allPatientData_server';
      datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
    }

    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        //  console.log(ress.data);
        setUserData(ress.data.Rdata);
        setIsLoading(false);
      });
    //  console.log(res.data);
  }

  /************Get total user********************** */
  let getTotalPatientData = async () => {


    let datas = {};
    //	console.log(datas);
    var url = "";
    if (reactLocalStorage.get('userLoginType') == 'Admin') {
      url = 'admin/totalPatientData_server';
    } else {
      url = 'chc/totalPatientData_server';
      datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
    }
    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        // console.log(ress.data.Rdata.length);
        setTotalRows(ress.data.Rdata.length);
        setTotalData(ress.data.Rdata);

      });
      

  }





  /***************** on click of mobile no************************************* */

  let getMobileNo = (typ, data) => {
    navigat('/patient_all_member_list/' + typ + '/' + data);
  }

  /********************************************************************* */


  var headers = [
    { label: 'Family Head Name', field: 'guardian_name' },
    { label: 'Mobile No', field: 'mobile_no' },
    { label: 'District', field: 'district_name' },
    { label: 'Block', field: 'block_name' },
    { label: 'CHC/PHC', field: 'chc_name' },
    { label: 'Village', field: 'village_name' },
    { label: 'School', field: 'school_name' },
    { label: 'Added On', field: 'added_on' },

  ]


  const dwn = () => {
    var content = toExcel.exportXLS(headers, totalData, 'AllUserList');

  }


  /*********************************************************** */

  const fetchUsers = async page => {
    setLoading(true);
    getAllPatientData(page);
    setLoading(false);
  };

  const handlePageChange = page => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    getAllPatientData(page)
    setPerPage(newPerPage);
    setLoading(false);
  };



  const columns = [

    {
      name: 'Action',
      selector: row => row.mobile_no,
      sortable: true,
      cell: (row) => <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="View" onClick={() => getMobileNo('vb', row.mobile_no)} >
        <i className="typcn typcn-eye  text-primary"></i>
      </button>
    },
    {
      name: 'Family Head Name',
      selector: row => row.guardian_name,
      sortable: true,
    },
    {
      name: 'Mobile No',
      selector: row => row.mobile_no,
      sortable: true,
      cell: (row) => <Link to={'/patient_all_member_list/vb/' + row.mobile_no}>{row.mobile_no}</Link>
    },

    {
      name: 'District',
      selector: row => row.district_name,
      sortable: true,

    },
    {
      name: 'Block',
      selector: row => row.block_name,
      sortable: true,
    },
    {
      name: ' CHC/PHC',
      selector: row => row.chc_name,
      sortable: true,

    },
    {
      name: "Village",
      selector: row => row.village_name,
      sortable: true,
    },
    {
      name: "School",
      selector: row => row.school_name,
      sortable: true,
    },
    {
      name: "Added On",
      selector: row => row.added_on,
      sortable: true,
      cell: (row) => <Moment format="DD-MM-YYYY" >{row.added_on}</Moment>
    },
  ];

  return (

    <div className="container-scroller">
      <Hor_header />
      <div className="container-fluid page-body-wrapper">

        <Ver_header />
        <div className="main-panel">
          {isLoading ? (<Loader />) : (
            <>
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">All Beneficiary Lists</h4>
                        <div className="col-lg-6 grid-margin">
                          <button className="btn btn-success" onClick={dwn} >Export</button>
                          {/* <input type="text"  name="search" className="" placeholder='Search user by mobile'  /> <button type="bytton" onClick="search by mobile " >Search</button> */}
                        </div>

                        <div className="table-responsive pt-3">

                          <DataTable
                            columns={columns}
                            data={userData}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                          //  expandableRows = {true}
                          //  expandableRowsComponent={ExpandedComponent}
                          />

                        </div>

                      </div>
                    </div>
                  </div>


                </div>
              </div>

              <Footer />
            </>
          )}
        </div>

      </div>

    </div>);
}

export default AllPatient;