import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { param, readyException } from 'jquery';


function RefAnmMedicalUser() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  $('#example').DataTable().destroy();

  $(document).ready(function () {


    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
      responsive: true
    });
    var dt = $('#example').DataTable();
    dt.columns([2]).visible(false);
  });

  useEffect(() => {
    document.title = "Patient List";
    getAnmMedicalUserData();
  }, [1]
  );
  /*********************get parameter from url*********************************** */
  const params = useParams();
  /************Get all patient user********************** */
  let getAnmMedicalUserData = async () => {
    let udata = { "anm_medical_user_id": params.anm_id }
    const res = await axios.post(API_URL + 'admin/getAnmMedicalUser', udata)
      .then(ress => {
        // console.log(ress.data);
        setUserData(ress.data.Rdata);
        setIsLoading(false);
      });
    //  console.log(res.data);
  }

  /***************** on click of back ************************************* */

  let getMobileNo = (data) => {
    navigat('/patient_all_member_list/' + data);
  }
  let backto = () => {
    return navigat('/ref_patient_all_test_list/');
  }
  /****************************************** */



  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">


              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Anm/Medical Team User Details  </h4><Link className="btn btn-outline-primary" to={'/reffered_cases/'}>Back</Link>
                   
                      <div className="table-responsive pt-3">
                        {/* <table className="table table-bordered display responsive nowrap" id="example"> */}
                        <table className="table table-bordered display  nowrap" id="example">
                          <thead>
                            <tr>
                              <th></th>
                              <th> User Type </th>
                              <th> User Id </th>
                              <th> Name</th>
                              <th> Mobile </th>
                              <th> DOB </th>
                              <th> Gender </th>
                              <th> State </th>
                              <th> District </th>
                              <th> Block </th>
                              <th> Chc/Phc </th>
                              <th> Sub Center </th>
                              {/* <th> Village </th>
                                <th> School </th> */}
                              <th> Status </th>
                              <th> Created On </th>
                              {/* <th className="nosort"> Action </th> */}
                            </tr>
                          </thead>
                          <tbody>

                            {userData.map((udata, index) => (
                              <tr>
                                <td></td>
                                <td> {udata.user_type != null ? udata.user_type.toUpperCase() : ""}</td>
                                <td> {udata.user_id}</td>
                                <td> {udata.name}</td>
                                <td> {udata.mobile_no}</td>
                                <td> {udata.dob} </td>
                                <td> {udata.gender != null ? udata.gender.toUpperCase() : ""}</td>
                                <td> {udata.state}</td>
                                <td> {udata.district_name} </td>
                                <td> {udata.block_name}</td>
                                <td> {udata.chc_name} </td>
                                <td> {udata.sub_center} </td>
                                {/* <td> {udata.village_name } </td>
                                    <td> {udata.school_name } </td> */}
                                {/* <td> { udata.status?'Active':'Inactive'} </td> */}
                                <td>
                                  <button className={udata.status ? 'btn btn-success btn-sm cust_btn' : 'btn btn-danger btn-sm cust_btn'}   >{udata.status ? 'Active' : 'Inactive'}</button>
                                </td>
                                <td><Moment format="DD-MM-YYYY">{udata.created_on}</Moment> </td>

                                {/* <td>
                                    <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon"   >
                                        <i className="typcn typcn-gift text-success"></i>                          
                                    </button>
                                    
                                    <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" >
                                        <i className="typcn typcn-edit  text-primary"></i>                          
                                    </button>
                                    
                                        </td> */}
                              </tr>
                            )
                            )}

                          </tbody>
                        </table>
                      </div>
                   
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>

    </div>);
}

export default RefAnmMedicalUser;