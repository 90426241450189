import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddStates() {


    const [stateName, setStateName] = useState("");
    const params = useParams();

    const handleState = (e) => {
        setStateName(e.target.value);
    }

    const navegate = useNavigate();
    let clickCancel = () => {
        return navegate('/');
    }



    useEffect(() => {
        document.title = (params?.id)?'Edit State':'Add State';
        if (params?.id) {
            setStateName(params?.name);
        }
    }, [])


    const handleValidation = () => {
        let formIsValid = true;
    
        if (!stateName) {
            formIsValid = false;
            swal('Please Enter State');
        }
       
        
        return formIsValid;
    }

    const AddStatesData = async () => {

        let formData = {
            'state': stateName
        };
       
        const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const getResps = await fetch(API_URL + 'admin/add-state', regDatas);
        const getDatas = await getResps.json();

        swal(getDatas.msg);

    }


    const UpdateStatesName = async () => {

        let formData = {

            'state': stateName,
            'id': params?.id

        };
       
        const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const getResps = await fetch(API_URL + 'admin/update-state', regDatas);
        const getDatas = await getResps.json();

        swal(getDatas.msg);

    }


    const handleSubmit = (events) => {
        if(handleValidation()){
            events.preventDefault();
            if (params?.id) {
                UpdateStatesName();
            } else {
                AddStatesData();
            }
        }
       

    }




    return (
        <div className="container-scroller">

            <Hor_header />

            <div className="container-fluid page-body-wrapper">


                <Ver_header />


                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">

                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">{(params?.id)?"Update State":"Add State"}</h4>
                                        <form className="form-sample" onSubmit={handleSubmit}>
                                            
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-12">State Name *</label>
                                                        <div className="col-sm-12">
                                                            <input value={stateName} className="form-control" autoComplete="off" required onChange={handleState} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            <button type="submit" className="btn btn-primary mr-2">Submit</button>
                                            <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <Footer />

                </div>

            </div>


        </div>


    );
}

export default AddStates;