import React , {useEffect,useState} from "react";
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import DatePicker from "react-datepicker";
import axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';
import swal from "sweetalert";

function AddInvItems(){
   const [hideDiv, setHideDiv] = useState(false);
   const [itemAV, setItemAV] = useState([]);
   const [myitem, setMyitem] = useState([]);
   
const [startDate, setStartDate] = useState();
   useEffect( () => {
     document.title='Add In Stock';
     getInvItems();
  },[1]);

 
  
  /******************get all inventory items ******************** */
  let getInvItems = async () => {    
   const res = await axios.post(API_URL+'admin/getInvItems')
    .then(ress => {
          setItemAV(ress.data.Rdata);
     });
  }


/*****************submit******************************* */
 const submitUser = async(events)=>{
        events.preventDefault();
        const data = new FormData(events.target);
        const temp = [];
        let user_type_id = '';
        if(reactLocalStorage.get("userLoginType") == "Admin"){
          user_type_id = 'admin';
        }else{
          user_type_id = reactLocalStorage.get("user_id");
        }        
        itemAV.forEach(function(val, key){
          if(data.get(val.id) > 0){
            temp.push([val.id, data.get(val.id)]);
          }          
        });
        let formData = {data: temp,user_id:user_type_id} ;
        const regDatas = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData)
        }
        const getResps = await fetch(API_URL+'admin/save-in-stock',regDatas);
        const getDatas = await getResps.json();
        swal(getDatas.msg);
        if(getDatas.status == 200){
          document.getElementById("in_stock_form").reset();
        }
 }



    /******************************* */
  return (
    <div className="container-scroller">
      <Hor_header/>
      <div className="container-fluid page-body-wrapper">
        <Ver_header/>
        <div className="main-panel">        
          <div className="content-wrapper">
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Add In Stock</h4>
                    <form className="inventory-class" id="in_stock_form" onSubmit={submitUser}>
                    <p><b>Consumable</b></p>
                    {itemAV.map( itm => (
                      (itm.type == "Consumable")?
                      <div className="form-inline">
                        <div className="form-group">
                          <label >{itm.item_name}</label>
                          <input type="number" min="0" className="form-control" name={itm.id} placeholder={itm.item_name} />
                        </div>
                      </div>:""
                      ))}
                    <hr />
                      <p><b>Non Consumable</b></p>
                      {itemAV.map( itm => (
                        (itm.type == "Non consumable")?
                        <div className="form-inline">
                          <div className="form-group">
                            <label >{itm.item_name}</label>
                            <input type="number" min="0" className="form-control" name={itm.id} placeholder={itm.item_name} />
                          </div>
                        </div>:""
                        ))}
                      <br />
                      <button type="submit" className="btn btn-primary mr-2">Add In Stock</button>
                      <button type="reset" className="btn btn-light">Cancel</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default AddInvItems;