import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import $, { readyException } from 'jquery';
import { toExcel } from 'to-excel';
import TestModal from "./test_beneficiary_model";

function AllBeneficiaryList() {

    const navigat = useNavigate();

    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pages, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalData, setTotalData] = useState();
    const [searchText, setSearchText] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState("");
    const [patientId, setPatientId] = useState("");
    const [beneficiaryType, setBeneficiaryType] = useState("");
    const [age, setAge] = useState("");
    const [anmId, setAnmId] = useState("");



    useEffect(() => {
        document.title = "Beneficiary List";
        getTotalPatientData()

        //getExportPatientData();
    }, [1]
    );

    useEffect(() => {

        getAllPatientData(pages);

    }, [pages]);




    /************Get all patient user********************** */
    let getAllPatientData = async (page) => {
        //setUserData([]); 
        let datas = { page: page, per_page: perPage };

        var url = "";

        url = 'admin/allBeneficiary_server';


        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                 console.log("dataaata---->",ress.data);
                setUserData(ress.data.Rdata);
                setIsLoading(false);
            });
        //  console.log(res.data);
    }

    /************Get total user********************** */
    let getTotalPatientData = async () => {


        let datas = {};
        //	console.log(datas);
        var url = "";

        url = 'admin/totalBeneficiaryData_server';

        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                // console.log(ress.data.Rdata.length);
                setTotalRows(ress.data.Rdata.length);
                setTotalData(ress.data.Rdata);

            });


    }


    const fetchUsers = async page => {
        setLoading(true);
        getAllPatientData(page);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        getAllPatientData(page)
        setPerPage(newPerPage);
        setLoading(false);
    };




    var headers = [
        { label: 'Name', field: 'name' },
        { label: 'Beneficiary Type', field: 'beneficiary_type' },
        { label: 'Patient id', field: 'patient_id' },
        { label: 'Family Head Name', field: 'guardian_name' },
        { label: 'Mobile No', field: 'mobile_no' },
        { label: 'Gender', field: 'gender' },
        { label: 'Caste', field: 'caste' },
        { label: 'Age id', field: 'age' },
        { label: 'Pregnancy Duration', field: 'preg_duration' },
        { label: 'Address', field: 'address' },
        { label: 'Staff Type', field: 'staff_type' },
        { label: 'Added By Anm Id', field: 'anm_teacher_id' },
        { label: 'Rch id', field: 'rch_uid' },
        { label: 'District', field: 'district_name' },
        { label: 'Block', field: 'block_name' },
        { label: 'CHC/PHC', field: 'chc_name' },
        { label: 'Village', field: 'vil_name' },
        { label: 'Added On', field: 'added_on' },


    ]


    const dwn = () => {

        var content = toExcel.exportXLS(headers, totalData, 'AllBeneficiaryData');
        // in node you must open or save the content
        //require('fs').writeFileSync('filename.xls', content);


    }


    const ExpandedComponent = ({ data }) => {
        return (

            <>
                <table className='table table-striped  sadi'>
                    <tr><td> <b>Pregnancy Duration - </b>  {data.preg_duration}</td></tr>

                    <tr><td> <b>Address</b>  - {data.address}</td></tr>
                    <tr><td> <b>Adhar No - </b> {data.aadhar_no}</td></tr>
                    <tr><td> <b>Added By Anm Id - </b> {data.anm_teacher_id}</td></tr>
                    <tr><td> <b>Rch id - </b> {data.rch_uid}</td></tr>
                    <tr><td> <b>District - </b> {data.district_name}</td></tr>
                    <tr><td> <b>Block - </b> {data.block_name}</td></tr>
                    <tr><td> <b>CHC/PHC - </b> {data.chc_name}</td></tr>
                    <tr><td> <b>Village - </b> {data.vil_name}</td></tr>



                    <tr><td> <b>Added On - </b> <Moment format="DD-MM-YYYY" >{data.added_on}</Moment></td></tr>
                </table>
            </>

        )


    }

    let deleteData = async (vals) => {
        const dataDel = { "id": vals };
        const res = await axios.post(API_URL + 'admin/delete-beneficiary', dataDel)
            .then(ress => {
                if (ress.status == '200') {
                    swal("Beneficiary deleted successfully.");

                } else {
                    swal("Some error occured, please try after some time. ");
                }
                getAllPatientData(pages);
                getTotalPatientData();

            });

    }

    let editSchool = (data) => {
        return navigat('/edit-beneficiary/' + data);
    }



    const columns = [
        {
            name: 'Action',
            // selector: row => row.mobile_no,
            //sortable: true,
            cell: (row) => <>
                <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Delete Beneficiary" onClick={() => { if (window.confirm('Do you want to delete this item?')) { deleteData(row.id) } }}>
                    <i className="typcn typcn-trash text-success"></i>
                </button>


                <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Edit Beneficiary" onClick={() => { editSchool(row.id) }}>
                    <i className="typcn typcn-edit  text-primary"></i>
                </button>
                <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Add Test" onClick={() => { setShowModal(true); setId(row.id); setPatientId(row.patient_id); setBeneficiaryType(row.beneficiary_type); setAge(row.age); setAnmId({id:row.anm_teacher_id,name:row.treatment_giv_name})}}>
                    <i className=" typcn typcn-plus  text-secondary"></i>
                </button>
            </>

        },

        {
            name: 'Name',
            selector: row => row.name,
            //   cellExport: row => row.name,
            sortable: true,
            // width: '110px',

        },

        {
            name: 'Beneficiary Type',
            selector: row => row.beneficiary_type,
            //   cellExport: row => row.beneficiary_type,
            sortable: true,

        },
        {
            name: 'Patient id',
            selector: row => row.patient_id,
            sortable: true,
            //   cellExport: row => row.patient_id,
            // width: '100px',
        },
        {
            name: 'Family Head Name',
            selector: row => row.guardian_name,
            //  cellExport: row => row.guardian_name,
            sortable: true,

        },

        {
            name: 'Mobile No',
            selector: row => row.mobile_no,
            sortable: true,
            //width: '75px',
            //  cellExport: row =>row.mobile_no,
            //cell: (row) => <Link to={'/patient_all_member_list/rp/' + row.mobile_no}>{row.mobile_no}</Link>
        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true,
            //   cellExport: row =>row.gender,
            cell: (row) => <>{row.gender != null ? row.gender.toUpperCase() : ""}</>,

        },
        {
            name: "Caste",
            selector: row => row.caste,
            sortable: true,
            // cellExport: row =>row.caste,
            cell: (row) => <>{row.caste != null ? row.caste.toUpperCase() : ""}</>,

        },
        {
            name: "Age",
            selector: row => row.age,
            sortable: true,


        },



    ];


    let AllBeneficiarySearchData = async (seeach) => {

        let datas = { page: pages, per_page: perPage, search: seeach };
        var url = "";

        url = 'admin/getSearchedServerSideBeneficiary';

        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                //  console.log(ress.data);

                setUserData(ress.data.Rdata);
                setIsLoading(false);


            });
        //  console.log(res.data);
    }



    let TotalBeneficiaryData = async (serach) => {

        let datas = { search: serach };

        var url = "";

        url = 'admin/getSearchedServerSideBeneficiaryTotal';

        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                // console.log(ress.data.Rdata.length);
                setTotalRows(ress.data.Rdata.length);
                setTotalData(ress.data.Rdata);

            });

    }

    const handleSercahBox = (e) => {
        const inputValue = e.target.value;
        setSearchText(inputValue);
        AllBeneficiarySearchData(inputValue);
        TotalBeneficiaryData(inputValue);

    }







    return (

        <div className="container-scroller">

            <Hor_header />

            <div className="container-fluid page-body-wrapper">

                <Ver_header />

                <div className="main-panel">
                    {isLoading ? (<Loader />) : (
                        <>
                            <div className="content-wrapper">



                                <div className="row">


                                    <div className="col-lg-12 grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="card-title">Beneficiary List</h4>
                                                <div className="row">
                                                    <div className="col-lg-6 grid-margin">
                                                        <button className="btn btn-success" onClick={dwn}  >Export</button>
                                                        {/* <input type="text"  name="search" className="" placeholder='Search user by mobile'  /> <button type="bytton" onClick="search by mobile " >Search</button> */}
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div className='cusSearch-box-item'>
                                                            <label>Search:
                                                                <input type='text' value={searchText} onChange={handleSercahBox} />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="table-responsive">



                                                    <DataTable

                                                        columns={columns}
                                                        data={userData}
                                                        progressPending={loading}
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={totalRows}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}
                                                        expandableRows={true}
                                                        expandableRowsComponent={ExpandedComponent}
                                                    />



                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <TestModal  show={showModal}  id={id} patientId={patientId} close={() => setShowModal(false)} benType={beneficiaryType} age={age} anmId={anmId}/>

                                </div>
                            </div>

                            <Footer />
                        </>
                    )}
                </div>

            </div>

        </div>);
}

export default AllBeneficiaryList;