import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
/************get parameter from url******************* */
import { useParams, useNavigate } from 'react-router-dom';
/*******************Date Picker**************************** */
import DatePicker from "react-datepicker";
import { reactLocalStorage } from 'reactjs-localstorage';
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function EditBlockUser() {

  /********************************************* */
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  // const [chcData,setChcData] =useState([]);


  const [userFormData, setUserFormData] = useState([]);

  /******************pass & Cpass************************** */
  //  const [passData,setPassData] = useState();
  //  const [cpassData,setCpassData] = useState();

  /********************medical team Hide Show************************* */

  const [hideDiv, setHideDiv] = useState(false);
  /*****************Date****************************** */
  const [startDate, setStartDate] = useState(new Date());
  /***********************radio button***************************** */
  const [rad, setRad] = useState(false);
  /********************back***************************************** */
  const navegate = useNavigate();
  let clickCancel = () => {
    return navegate('/allBlockUser');
  }
  /*********************get parameter from url*********************************** */
  const params = useParams();
  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    document.title = 'Edit Block User';

    getDataOfUser();
    getStateData();

  }, [1]
  );

  //console.log(userFormData.gender);

  let getDataOfUser = async () => {
    const data = { 'user_id': params.id };
    var res = await axios.post(API_URL + "chc/getBlockUserDetails", data).then((ress) => {
      // console.warn(ress.data.Rdata[0]);
      setUserFormData(ress.data.Rdata[0]);
      getDistrictList(ress.data.Rdata[0].state_id);
      getBlock(ress.data.Rdata[0].district_id);
      (ress.data.Rdata[0].auto_analyser == 'yes') ? setRad(true) : setRad(false);

    });

  }
  //console.log(userFormData.dob);



  /********State*********** */
  let getStateData = async () => {
    const regDataState = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' }
    }
    const getState = await fetch(API_URL + 'admin/state', regDataState);
    const getdatastate = await getState.json();
    setStateData(getdatastate);
  }

  /********District based on state*********** */
  let getDistrictList = async (e) => {
    setDistrictData([]);
    let state_id = e;
    const regDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    }
    const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
    const getdataDistrict = await getDistrict.json();
    // console.log(getdataDistrict.Rdata);
    if (getdataDistrict.Rdata.length > 0) {
      setDistrictData(getdataDistrict.Rdata);
    }
  }
  /********Block based on district*********** */
  let getBlock = async (e) => {
    setBlockData([]);
    let district_id = e;
    //console.log(district_id);
    const reDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'district_id': district_id })
    }
    const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
    const getBlockData = await getBlock.json();
    //console.log(getBlockData);
    if (getBlockData.Rdata.length > 0) {
      setBlockData(getBlockData.Rdata);
    }
    // else{
    //   const data=[{'id':'','name':''}];
    //   setBlockData(data);
    // }
  }



  /*****************submit******************************* */
  const submitUser = async (events) => {
    events.preventDefault();
    if (validate(events)) {
      const data = new FormData(events.target);

      let formData = {
        'mobile_no': data.get('mobile_no'),
        'state_id': data.get('state'),
        'district_id': data.get('district'),
        'block_id': data.get('block'),
        'auto_analyser': 'no',
        'user_id': params.id
      };
      //   console.log(formData); 

      const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      const getResps = await fetch(API_URL + 'chc/editBlockUserData', regDatas);
      const getDatas = await getResps.json();
      if((reactLocalStorage.get("userLoginType") == "District")){
        swal("Block user updated successfully.");
      }else{
        swal(getDatas.msg);
      }
     


    }
  }


  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    // console.log(data.get);
    let validat = true;

    if (data.get('mobile_no') == '') {
      swal('Please enter mobile');
      return validat = false;
    } else {
      let mobRegExp = "^[0-9]{10}";
      //let testemail= emailRegExp.test(data.get('email'));
      let mobname = data.get('mobile_no').match(mobRegExp);
      if (mobname) {
        validat = true;
      } else {
        swal('Invalid mobile');
        return validat = false;
      }
    }
    if (data.get('state') == 'Select') {
      swal('Please select state');
      return validat = false;
    } else {
      validat = true;
    }

    if (data.get('district') == "Select") {
      swal('Please select district');
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('block') == "Select") {
      swal('Please select block');
      return validat = false;
    } else {
      validat = true;
    }

    return validat;
  }


  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">{((reactLocalStorage.get("userLoginType") == "District")) ? "Update Block User" : "Update CHC User Registration"}</h4>
                    <form className="form-sample" onSubmit={submitUser}>
                      <p className="card-description">

                      </p>
                      {((reactLocalStorage.get("userLoginType") != "District"))?<div className="row">

                        <p> User Type : {userFormData.user_type}</p>

                      </div>:""}

                      <p className="card-description">
                        User Info
                      </p>
                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Mobile Number *</label>
                            <div className="col-sm-12">
                              <input name="mobile_no" type="tel" pattern="[0-9]{10}" className="form-control" value={userFormData.mobile_no} onChange={e => setUserFormData({ mobile_no: e.target.value })} required />
                            </div>
                          </div>
                        </div>
                      </div>


                      <p className="card-description">
                        Geographical Data
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select State *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="state" onChange={(e) => { setUserFormData({ "state": e.target.value }); getDistrictList(e.target.value); }} value={userFormData.state_id} required>
                                <option>Select</option>
                                {stateData.map(states => (
                                  <option value={states.id}>{states.state}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select District *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="district" onChange={(e) => { setUserFormData({ "district": e.target.value }); getBlock(e.target.value); }} value={userFormData.district_id} required>
                                <option>Select</option>
                                {districtData.map(district => (
                                  <option value={district.id}>{district.name}  {district.code} </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select Block *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="block" onChange={(e) => { setUserFormData({ "block": e.target.value }); }} value={userFormData.block_id} required>
                                <option>Select</option>
                                {blockData.map(blocks => (
                                  <option value={blocks.id}>{blocks.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row" >
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Do you have Auto Analyser*</label>
                          <div className="col-sm-12">
                         
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input"  name="auto_analyser" id="membershipRadios5" value="yes" checked={(rad)?true:false} onChange={e=>setRad(true)} />
                                Yes
                              <i className="input-helper"></i></label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="auto_analyser" id="membershipRadios6" value="no" checked={(rad)?false:true} onChange={ e=>setRad(false)}  />
                                No
                              <i className="input-helper"></i></label>
                            </div>
                         
                          </div>
                        </div>
                      </div>
                      
                    </div> */}



                      <button type="submit" className="btn btn-primary mr-2">Submit</button>
                      <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}

export default EditBlockUser;