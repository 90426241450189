import React , {useEffect,useState} from "react";
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import $, { readyException } from 'jquery';
import { v4 as uuid } from 'uuid';
import { toast } from "react-toastify";
function AddInvItemsRequest(){
  const [hideDiv, setHideDiv] = useState(false);
  const [itemAV, setItemAV] = useState([]);
  const [high, setHigh] = useState([]);
  const [reqTo, setReqTo] = useState([]);
  const [len,setLen]=useState(0);

  const [districtData, setDistrictData] =useState([]);

  const navegate = useNavigate();
  let clickCancel =()=>{
    return navegate('/');
  }
  const [startDate, setStartDate] = useState();
  useEffect( () => {
     document.title='Issue Item Without Request For Inventory';
     getInvItems();
     getDistrictList();
     //getListOfHigherLevel();
  },[1]);
  let getInvItems = async () => {
    const res = await axios.post(API_URL+'admin/getInvItems').then(ress => {
      setItemAV(ress.data.Rdata);
    });
  }

  $(document).ready(function () {
     $(".num").val(0);
     $("#btns").attr("disabled", true);
    });

    const disSubmit = ()=>{
      $("#btns").attr("disabled", true);
    }


/********District based on state*********** */
let getDistrictList = async(e) =>{
    setDistrictData([]);
     // let state_id = e.target.value;
       let state_id = 1;
      const regDataDistrict = {
          method: 'Post',
          headers: { 'Content-Type': 'application/json' },
          body : JSON.stringify({'state_id':state_id})
     }
     const getDistrict = await fetch(API_URL+'admin/district-name',regDataDistrict);
     const getdataDistrict = await getDistrict.json();
     // console.log(getdataDistrict.Rdata);
      if(getdataDistrict.Rdata.length > 0){
          setDistrictData(getdataDistrict.Rdata);
      }
  }



  const CheckAvail = async (edata,item_id) =>{
    $("#btns").attr("disabled", true);
   // console.log(edata,req_qty);
   if(edata!=""){
   
    
   var inv_user="";
    if(reactLocalStorage.get("userLoginType")=="Admin"){
      inv_user="admin" ;
    }else{
      inv_user = reactLocalStorage.get("user_id");
    }
  
  
    var data ={"inv_user_id":inv_user,"qty":edata,"item_id":item_id}
    
    const res = await axios.post(API_URL+'admin/check_avail_qty',data).then(ress => {
      if(ress.data.status== 201 ){
       toast.error(ress.data.msg); 
       $("#"+item_id).val(0);
       
     }else{
      
      $("#btns").removeAttr("disabled");
     }
   });

   }
  }


    const submitRequest = async(events)=>{
           events.preventDefault();
    
      const data = new FormData(events.target);
      if(data.get("district")!="Select"){
        const temp = [];
       // let user_type_id = reactLocalStorage.get("user_id");
        let req_to = "";
        let req_type = "NOT INDENT";
        let req_to_name="";
        let req_by="";
        let user_type="";
        let req_by_name="";
        let req_by_data="";

        if( reactLocalStorage.get('userLoginType') == 'Chc' || reactLocalStorage.get("userLoginType") == "Store"){
            req_to = reactLocalStorage.get("district");
            user_type = 'district';
        } 
        if( reactLocalStorage.get('userLoginType') == 'District'  || reactLocalStorage.get("userLoginType") == "DistrictStore" ){
            req_to = 'admin';
            user_type = 'Admin';
        } 
        if( reactLocalStorage.get('userLoginType') == 'Admin'){
            req_to = 'admin';
            req_to_name=reactLocalStorage.get('user_name');
            user_type="Admin";
           // console.log(data.get('district'));
            req_by_data=data.get('district').split("&&");
            req_by= $.trim(req_by_data[0]);
            req_by_name= $.trim(req_by_data[1]);

          }
        itemAV.forEach(function(val, key){
          if(data.get(val.id) > 0){
            temp.push([val.id, data.get(val.id)]);
          }          
        });

      //  console.log("temp",temp.length);
      
       if(temp.length){
        let formData = {
          data: temp,
        
          req_to:req_to,
          req_to_name:req_to_name,
          req_user_typ:user_type,
          req_user_by:req_by,
          req_by_name:req_by_name,
          req_type:req_type,
          unique_id : uuid()
        };
        const reqData = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData)
        }


        const getResps = await fetch(API_URL+'admin/send-request-to-lower',reqData);
     //   const getResps = await fetch(API_URL+'admin/send-request-to-lower',reqData);
        const getDatas = await getResps.json();
        swal(getDatas.msg);
        if(getDatas.status == 200){
          document.getElementById("request_form").reset();
          $(".num").val(0);
        }
      }

      }else{
        swal("Please Select District");
      }
    }
    return (
      <div className="container-scroller">
      <Hor_header/>
      <div className="container-fluid page-body-wrapper">
        <Ver_header/>
        <div className="main-panel">        
          <div className="content-wrapper">
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Issue Inventory</h4>
                    <br/>
                    <form className="inventory-class" id="request_form" onSubmit={submitRequest}>
                        
                       <p><b>Select District</b></p> 

                       <div className="col-md-5">
                        <div className="form-group row">
                        
                            <select className="form-control" name="district"  required>
                            <option>Select</option>
                             {districtData.map(district => (
                                 <option value={district.user_id+" && "+district.name}>{district.name} </option>  
                             ))}
                            </select>
                         
                        </div>
                      </div>

                     <p><b>Consumable</b></p>
                    {itemAV.map( itm => (
                      (itm.type == "Consumable")?
                      <div className="form-inline">
                        <div className="form-group">
                          <label >{itm.item_name}</label>
                          <input type="number" id={itm.id} min="0" className="form-control num" name={itm.id} placeholder={itm.item_name} onChange={disSubmit} onBlur={(e) =>{CheckAvail(e.target.value,itm.id);}} />
                        </div>
                      </div>:""
                      ))}
                    <hr />
                      <p><b>Non Consumable</b></p>
                      {itemAV.map( itm => (
                        (itm.type == "Non consumable")?
                        <div className="form-inline">
                          <div className="form-group">
                            <label >{itm.item_name}</label>
                            <input type="number" id={itm.id} min="0" className="form-control num" name={itm.id} placeholder={itm.item_name} onChange={disSubmit} onBlur={(e) =>{CheckAvail(e.target.value,itm.id);}} />
                          </div>
                        </div>:""
                        ))}
                      <br />
                      <button type="submit" id="btns" className="btn btn-primary mr-2">Submit</button>
                      <button type="reset" className="btn btn-light">Cancel</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </div>
    );
}

export default AddInvItemsRequest;