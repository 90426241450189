import React, { useEffect, useState } from "react";
import axios from "axios";
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { reactLocalStorage } from 'reactjs-localstorage';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import TestingModel from "./test_beneficiary_model";
import { format } from 'date-fns';

function AddBeneficiary() {

    const navegate = useNavigate();
    let clickCancel = () => {
        return navegate('/');
    }
    const [stateData, setStateData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const [chcData, setChcData] = useState([]);
    const [anmData, setAnmData] = useState([]);
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [block, setBlock] = useState("");
    const [chc, setChc] = useState("");
    const [anmId, setAnmId] = useState({ id: '', name: '' });
    const [beneficiaryType, setBeneficiaryType] = useState("");
    const [benProps, setBenProps] = useState("");
    const [makePrimary, setMakePrimary] = useState(false);
    const [primaryName, setPrimaryName] = useState("");
    const [mobile, setMobile] = useState("");
    const [beneficiaryName, setBeneficiaryName] = useState("");
    const [gender, setGender] = useState("");
    const [caste, setCaste] = useState("");
    const [addharNumber, setAddharNumber] = useState("");
    const [villageData, setVillageData] = useState([]);
    const [village, setVillage] = useState("");
    const [address, setAddress] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [benDate, setBenDate] = useState(null);
    const [hideTrimeseter, setHideTrimeseter] = useState(false);
    const [trimeseter, setTrimeseter] = useState("");
    const [rchId, setRchId] = useState("");
    const [showRch, setShowRch] = useState(false);
    const [disable, setDisable] = useState(false);
    const [age, setAge] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState("");
    const [patientId, setPatientId] = useState("");
    const [showSchool, setShowSchool] = useState(false);
    const [schoolData, setSchoolData] = useState([]);
    const [school, setSchool] = useState("");

    const params = useParams();


    useEffect(() => {
        getStateData();
    }, [1]);

    useEffect(() => {
        document.title = (params?.id) ? "Edit Beneficiary" : "Add Beneficiary";
        if (params?.id != undefined) {
            getDataOfBeneficiary(params?.id)
        } else {
            setBeneficiaryType("");
            setTrimeseter("");
            setHideTrimeseter(false);
            setRchId("");
            setShowRch(false);
            setState("");
            setStateData([]);
            setDistrict("");
            setDistrictData([]);
            setBlock("");
            setBlockData([]);
            setChc("");
            setChcData([]);
            setAnmId("");
            setAnmData([]);
            setVillage("");
            setVillageData([]);
            setPrimaryName("");
            setMobile("");
            setBeneficiaryName("");
            setGender("");
            setAddharNumber("");
            setAddress("");
            setAge("");
            setBenDate("");
            setCaste("");
            setSchool("");
        }

    }, [params?.id]);

    let getDataOfBeneficiary = async (id) => {
        const data = { 'id': id };
        var res = await axios.post(API_URL + "admin/getBeneficiaryData", data).then(async (ress) => {
            let data = ress.data.Rdata[0]
           
            setBeneficiaryType(data?.beneficiary_type);
            console.log("beneficiary_type", data?.beneficiary_type);
            if (data?.beneficiary_type === 'PREGNANT WOMEN') {
                setHideTrimeseter(true);
                setTrimeseter(data?.preg_duration);
                setRchId(data?.rch_uid);
                setShowRch(true)
                setDisable(false);
            }
            if (data?.beneficiary_type === 'LACTATING WOMEN') {
                setShowRch(true)
                setRchId(data?.rch_uid);
            }
            if (data?.beneficiary_type === 'ADOLESCENT 10-19 YEARS') {
              
                setShowSchool(true);
                setSchool(data?.school_id);
            }
            setState(data?.state_id);
            setDistrict(data?.district_id);
            setBlock(data?.block_id);
            setVillage(data?.village_id)
            setChc(data?.chc_id);
            setAnmId({id:data?.anm_teacher_id});
            if (data?.state_id) {
                let state_id = data?.state_id;
                const regDataDistrict = {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'state_id': state_id })
                }
                const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
                const getdataDistrict = await getDistrict.json();
                // console.log(getdataDistrict.Rdata);
                if (getdataDistrict.Rdata.length > 0) {
                    setDistrictData(getdataDistrict.Rdata);
                }
            }
            if (data?.district_id) {
                let district_id = data?.district_id;
                //console.log(district_id);
                const reDataDistrict = {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'district_id': district_id })
                }
                const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
                const getBlockData = await getBlock.json();
                //console.log(getBlockData);
                if (getBlockData.Rdata.length > 0) {
                    setBlockData(getBlockData.Rdata);
                }
            }
            if (data?.block_id) {
                let block_id = data?.block_id;

                const reDataBlock = {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'block_id': block_id })
                }

                const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
                const getChcData = await getChc.json();
                const getVillage = await fetch(API_URL + "admin/getVillage", reDataBlock);
                const getVillageData = await getVillage.json();
                const getSchool = await fetch(API_URL + "admin/getSchool", reDataBlock);
                const getSchoolData = await getSchool.json();

                if (getChcData.Rdata.length > 0) {
                    setChcData(getChcData.Rdata);
                }
                if (getVillageData.Rdata.length > 0) {
                    setVillageData(getVillageData.Rdata);
                }
                if (getSchoolData.Rdata.length > 0) {
                    setSchoolData(getSchoolData.Rdata);
                }
            }

            if (data?.chc_id) {
                let chc_id = data?.chc_id;

                const reDataBlock = {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'chc_id': chc_id })
                }

                const getChc = await fetch(API_URL + "admin/get-anm-list", reDataBlock);
                const getChcData = await getChc.json();

                if (getChcData.Rdata.length > 0) {
                    setAnmData(getChcData.Rdata);
                }
            }
            setAddharNumber(data?.aadhar_no);
            setAge(data?.age);
            setAddress(data?.address);
            setCaste(data?.caste);
            setGender(data?.gender);
            setBeneficiaryName(data?.name);
            setMobile(data?.mobile_no);
            setPrimaryName(data?.guardian_name);
            setMakePrimary(data?.primary_contact);
            //setBenDate(data?.added_on ? format(data?.added_on, 'dd-MM-yyyy h:m:s') : null)
           // console.log("Akshay--------->",format(data?.added_on, 'dd-MM-yyyy'))
            //setBenDate(data?.added_on);
            
        });



    }
   

    useEffect(() => {
        let newGender = "";
        if (beneficiaryType === 'PREGNANT WOMEN' ||
            beneficiaryType === 'LACTATING WOMEN' ||
            beneficiaryType === 'WOMEN OF REPRODUCTIVEAGE') {
            newGender = "female";
            setDisable(true);
        } else {
            setDisable(false);
        }

        setGender(newGender);

    }, [beneficiaryType]);

    let getStateData = async () => {
        const regDataState = {
            method: 'Get',
            headers: { 'Content-Type': 'application/json' }
        }
        const getState = await fetch(API_URL + 'admin/state', regDataState);
        const getdatastate = await getState.json();
        setStateData(getdatastate);
    }

    const handleState = async (e) => {
        setState(e.target.value);
        if (e.target.value) {
            let state_id = e.target.value;
            const regDataDistrict = {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'state_id': state_id })
            }
            const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
            const getdataDistrict = await getDistrict.json();
            // console.log(getdataDistrict.Rdata);
            if (getdataDistrict.Rdata.length > 0) {
                setDistrictData(getdataDistrict.Rdata);
            }
        }
    }

    const handleDistrict = async (e) => {
        setDistrict(e.target.value);
        if (e.target.value) {
            let district_id = e.target.value;
            //console.log(district_id);
            const reDataDistrict = {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'district_id': district_id })
            }
            const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
            const getBlockData = await getBlock.json();
            //console.log(getBlockData);
            if (getBlockData.Rdata.length > 0) {
                setBlockData(getBlockData.Rdata);
            }
        }
    }


    const handleBlock = async (e) => {
        setBlock(e.target.value);
        if (e.target.value) {
            let block_id = e.target.value;

            const reDataBlock = {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'block_id': block_id })
            }

            const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
            const getChcData = await getChc.json();
            const getVillage = await fetch(API_URL + "admin/getVillage", reDataBlock);
            const getVillageData = await getVillage.json();
            const getSchool = await fetch(API_URL + "admin/getSchool", reDataBlock);
            const getSchoolData = await getSchool.json();

            if (getChcData.Rdata.length > 0) {
                setChcData(getChcData.Rdata);
            }
            if (getVillageData.Rdata.length > 0) {
                setVillageData(getVillageData.Rdata);
            }
            if (getSchoolData.Rdata.length > 0) {
                setSchoolData(getSchoolData.Rdata);
            }
        }
    }

    const handleCHCs = async (e) => {
        setChc(e.target.value);
        if (e.target.value) {
            let chc_id = e.target.value;

            const reDataBlock = {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'chc_id': chc_id })
            }

            const getChc = await fetch(API_URL + "admin/get-anm-list", reDataBlock);
            const getChcData = await getChc.json();

            if (getChcData.Rdata.length > 0) {
                setAnmData(getChcData.Rdata);
            }
        }
    }

    const handleBeneficiary = (e) => {
        console.log("asdhgasd", e.target.value);
        setBeneficiaryType(e.target.value);
        setBenProps(e.target.value)
        if (e.target.value === 'PREGNANT WOMEN') {
            setHideTrimeseter(true);
        } else {
            setHideTrimeseter(false);
        }

        if (e.target.value === 'LACTATING WOMEN') {
            setShowRch(true);
        } else {
            setShowRch(false)
        }
        if (e.target.value === 'ADOLESCENT 10-19 YEARS') {
            setShowSchool(true);
        } else {
            setShowSchool(false);
        }

    }


    const handleAnmName = (e) => {
        console.log("sadhasd", e.target.value);
        const selectedId = e.target.value;
        const selectedName = anmData.find(anm => anm.user_id === selectedId)?.name || '';
        console.log("sadhasd", selectedName);

        setAnmId({ id: selectedId, name: selectedName });
        console.log("dddd", anmId)
    }

    const handleMakePrimary = (e) => {
        setMakePrimary(!makePrimary);
    };

    const handlePrimaryName = (e) => {
        setPrimaryName(e.target.value);
    }

    const handleMobile = (e) => {
        const input = e.target.value;
        const numericInput = input.replace(/\D/g, '');
        const limitedInput = numericInput.slice(0, 10);
        setMobile(limitedInput);
    }

    const handleBeneficiaryName = (e) => {
        setBeneficiaryName(e.target.value);
    }

    const handleGender = (e) => {
        setGender(e.target.value);
    }

    const handleCaste = (e) => {
        setCaste(e.target.value);
    }

    const handleAddharNumber = (e) => {
        const input = e.target.value;
        const numericInput = input.replace(/\D/g, '');
        const limitedInput = numericInput.slice(0, 12);
        setAddharNumber(limitedInput);

    }

    const handleVillage = (e) => {
        setVillage(e.target.value);
    }

    const handleSchool = (e) => {
        setSchool(e.target.value);
    }

    const handleAddress = (e) => {
        setAddress(e.target.value);
    }

    const handleTrimester = (e) => {
        setTrimeseter(e.target.value);
        if (e.target.value != "") {
            setShowRch(true);
        } else {
            setShowRch(false);
        }
    }

    const handleRCHId = (e) => {
        const input = e.target.value;
        const numericInput = input.replace(/\D/g, '');
        const limitedInput = numericInput.slice(0, 12);
        setRchId(limitedInput);
    }






    const handleDateChange = (date) => {
        const today = new Date();
        const diff = today - date;

        // Convert milliseconds to months
        const months = diff / (1000 * 60 * 60 * 24 * 30);

        if (beneficiaryType === 'CHILDREN 6-59 MONTHS') {
            if (months < 6 || months > 59) {
                swal("Age must be between 6 months and 59 months.");
                return;
            }
        } else if (beneficiaryType === 'CHILDREN 5-9 YEARS') {
            if (months < 60 || months > 119) {
                swal("Age must be between 5 years to 9 years 11 months");
                return;
            }
        } else if (beneficiaryType === 'ADOLESCENT 10-19 YEARS') {
            if (months < 120 || months > 228) {
                swal("Age must be between 10 years and 19 years.");
                return;
            }
        }

        setStartDate(date);
        getAge(date);
    }

    const handleBenDateChange = (date) => {
        setBenDate(date);
    }

    const getAge = (dateStrings) => {
        // var dateString = dateStrings.toLocaleDateString("en-US")
        //console.log("dateString",dateStrings.toLocaleDateString("en-US"));


        let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dateStrings);
        let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(dateStrings);
        let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(dateStrings);
        //console.log(`${day}-${month}-${year}`);
        var dateString = `${month}/${day}/${year}`;

        var now = new Date();
        var today = new Date(now.getYear(), now.getMonth(), now.getDate());

        var yearNow = now.getYear();
        var monthNow = now.getMonth();
        var dateNow = now.getDate();

        var dob = new Date(dateString.substring(6, 10),
            dateString.substring(0, 2) - 1,
            dateString.substring(3, 5)
        );
        // console.log("dateString",dateString.substring(6,10),dateString.substring(0,2)-1,dateString.substring(3,5));
        var yearDob = dob.getYear();
        var monthDob = dob.getMonth();
        var dateDob = dob.getDate();
        var age = {};
        var ageString = "";
        var yearString = "";
        var monthString = "";
        var dayString = "";


        var yearAge = yearNow - yearDob;

        if (monthNow >= monthDob)
            var monthAge = monthNow - monthDob;
        else {
            yearAge--;
            var monthAge = 12 + monthNow - monthDob;
        }

        if (dateNow >= dateDob)
            var dateAge = dateNow - dateDob;
        else {
            monthAge--;
            var dateAge = 31 + dateNow - dateDob;

            if (monthAge < 0) {
                monthAge = 11;
                yearAge--;
            }
        }

        age = {
            years: yearAge,
            months: monthAge,
            days: dateAge
        };

        if (age.years > 1) yearString = " years ";
        else yearString = " year ";
        if (age.months > 1) monthString = " months ";
        else monthString = " month ";
        if (age.days > 1) dayString = " days ";
        else dayString = " day ";


        if ((age.years > 0) && (age.months > 0) && (age.days > 0))
            ageString = age.years + yearString + age.months + monthString + age.days + dayString;
        else if ((age.years == 0) && (age.months == 0) && (age.days > 0))
            ageString = age.days + dayString;
        else if ((age.years > 0) && (age.months == 0) && (age.days == 0))
            ageString = age.years + yearString;
        else if ((age.years > 0) && (age.months > 0) && (age.days == 0))
            ageString = age.years + yearString + age.months + monthString;
        else if ((age.years == 0) && (age.months > 0) && (age.days > 0))
            ageString = age.months + monthString + age.days + dayString;
        else if ((age.years > 0) && (age.months == 0) && (age.days > 0))
            ageString = age.years + yearString + age.days + dayString;
        else if ((age.years == 0) && (age.months > 0) && (age.days == 0))
            ageString = age.months + monthString;
        else ageString = "Oops! Could not calculate age!";
        //console.log("ageString",ageString);
        // return ageString;
        setAge(ageString)
    }

    const handleAge = (e) => {
        setAge(e.target.value);
    }



    const calculateAgeText = (dob) => {
        const today = new Date();
        const diff = today - dob;
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));

        const months = Math.floor(days / 30);
        const remainingDays = days % 30;

        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;

        return `${years} Years ${remainingMonths} Months ${remainingDays} Days`;
    }

    const handleValidation = () => {
        let formIsValid = true;

        if (!state && state === "") {
            formIsValid = false;
            swal('Please Select State');
        }
        if (!district && district === "") {
            formIsValid = false;
            swal('Please Select District');
        }
        if (!block && block === "") {
            formIsValid = false;
            swal('Please Select Block')
        }
        if (!chc && chc === "") {
            formIsValid = false;
            swal('Please Select CHC/PHC')
        }
        if (!anmId && anmId === "") {
            formIsValid = false;
            swal('Please Select ANM');
        }
        if (!beneficiaryType && beneficiaryType === "") {
            formIsValid = false;
            swal("Please Select Beneficiary Type");
        }
        if (!primaryName) {
            formIsValid = false;
            swal("Please Enter Primary Name");
        }
        if (!mobile) {
            formIsValid = false;
            swal("Please Enter Primary Mobile Number");
        }
        if (!beneficiaryName) {
            formIsValid = false;
            swal("Please Enter Beneficiary Name");
        }
        if (!gender && gender === "") {
            formIsValid = false;
            swal("Please Select Gender");
        }
        if (!caste && caste === "") {
            formIsValid = false;
            swal("Please Select Primary Caste");
        }
       
        if (!village && village === "") {
            formIsValid = false;
            swal("Please Select Village");
        }
        if (!address) {
            formIsValid = false;
            swal("Please Enter Address");
        }
        if (!params?.id) {
            if (!startDate) {
                formIsValid = false;
                swal("Please Select DOB");
            }
            if(!params?.id){
                if (!benDate) {
                    formIsValid = false;
                    swal("Please Select Beneficiary Added Date")
                }
            }
            
        }
        if(beneficiaryType === 'ADOLESCENT 10-19 YEARS'){
            if(!school && school === ""){
                formIsValid = false;
                swal('Please Select School');
            }
        }

        // if (beneficiaryType === 'Pregnant Women') {
        //     if(!trimeseter){
        //         formIsValid = false;
        //         swal("Please Select Trimeseter")
        //     }
        // }
        // if(!trimeseter || ! beneficiaryType === 'Lactating Women'){
        //     if(!rchId){
        //         formIsValid = false;
        //         swal("Please Enter RCH ID")
        //     }
        // }
        return formIsValid;
    }

    const addBeneficiary = async (events) => {


        let formData = {
            "beneficiary_type": beneficiaryType,
            "name": beneficiaryName,
            "mobile_no": mobile,
            "gender": gender,
            "age": age,
            "guardian_name": primaryName,
            "aadhar_no": addharNumber,
            "address": address,
            "anm_teacher_id": anmId?.id,
            "rch_uid": (trimeseter || !beneficiaryType === 'LACTATING WOMEN') ? rchId : "",
            "patient_id": "",
            "primary_contact": (makePrimary === 1) ? 1 : 0,
            "village_id": village,
            "school_id": school,
            "caste": caste,
            "preg_duration": (beneficiaryType === 'PREGNANT WOMEN') ? trimeseter : "",
            "ab_lat": "0",
            "ab_log": "0",
            "added_on": benDate

        };
      

        const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const getResps = await fetch(API_URL + 'admin/addBeneficiary', regDatas);
        const getDatas = await getResps.json();
        setId(getDatas?.Rdata[0]?.id)
        setPatientId(getDatas?.Rdata[0]?.patient_id);

        if ((reactLocalStorage.get("userLoginType") == "Admin")) {
            swal("Beneficiary Added Successfully.")
            setShowModal(true);
        } else {
            swal(getDatas?.msg);
        }
        setBeneficiaryType("");
        setTrimeseter("");
        setHideTrimeseter(false);
        setRchId("");
        setShowRch(false);
        setState("");
        setStateData([]);
        setDistrict("");
        setDistrictData([]);
        setBlock("");
        setBlockData([]);
        setChc("");
        setChcData([]);
        //setAnmId("");
        setAnmData([]);
        setVillage("");
        setVillageData([]);
        setPrimaryName("");
        setMobile("");
        setBeneficiaryName("");
        setGender("");
        setAddharNumber("");
        setAddress("");
        setAge("");
        setStartDate("");
        setBenDate("");
        setCaste("");
        setSchool("");


    }
    const updateBeficiary = async (events) => {

        let formData = {
            "id": params?.id,
            "beneficiary_type": beneficiaryType,
            "gender": gender,
            "caste": caste,
            "age": age,
            "guardian_name": primaryName,
            "aadhar_no": addharNumber,
            "primary_contact": (makePrimary === 1) ? 1 : 0,
            "address": address,
            "anm_teacher_id": anmId?.id,
            "rch_uid": (trimeseter || !beneficiaryType === 'LACTATING WOMEN') ? rchId : "",
            "village_id": village,
            "school_id": school,
            "preg_duration": trimeseter,
            "name": beneficiaryName,
            "added_on": benDate


        }
       

        const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const getResps = await fetch(API_URL + 'admin/update-beneficiary', regDatas);
        const getDatas = await getResps.json();
        swal(getDatas?.msg);


    }

    const handleSubmit = (events) => {

        if (handleValidation()) {
            events.preventDefault();
            if (params?.id) {
                updateBeficiary();
            } else {
                addBeneficiary();
            }
        }


    }




    return (
        <div className="container-scroller">

            <Hor_header />

            <div className="container-fluid page-body-wrapper">


                <Ver_header />


                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">

                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">{(params?.id) ? "Update Beneficiary" : "Add Beneficiary"}</h4>
                                        {/* <form className="form-sample" > */}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12"> Beneficiary Type *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={beneficiaryType} onChange={handleBeneficiary} required>
                                                            <option value="">Select Beneficiary Type</option>
                                                            <option value="CHILDREN 6-59 MONTHS">CHILDREN 6-59 MONTHS</option>
                                                            <option value="CHILDREN 5-9 YEARS">CHILDREN 5-10 YEARS</option>
                                                            <option value="ADOLESCENT 10-19 YEARS">ADOLESCENT 10-19 YEARS</option>
                                                            <option value="PREGNANT WOMEN">PREGNANT WOMEN</option>
                                                            <option value="LACTATING WOMEN">LACTATING WOMEN</option>
                                                            <option value="WOMEN OF REPRODUCTIVEAGE">WOMEN OF REPRODUCTIVEAGE</option>
                                                            <option value="MEDICAL TEAM">MEDICAL TEAM</option>
                                                            <option value="OTHERS">OTHERS</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6" style={{ "display": (hideTrimeseter) ? "" : "none" }}>
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select Trimeseter  *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={trimeseter} onChange={handleTrimester} required>
                                                            <option value="">Select Trimeseter</option>
                                                            <option value="1st trimester(1-3 months)">1st trimester(1-3 months)</option>
                                                            <option value="2nd trimester(4-6 months)">2nd trimester(4-6 months)</option>
                                                            <option value="3rd trimester(6-9 months)">3rd trimester(6-9 months)</option>


                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select State *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={state} onChange={handleState} required>
                                                            <option value="">Select State</option>
                                                            {stateData.map(states => (
                                                                <option value={states.id}>{states.state}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select District *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={district} onChange={handleDistrict} required>
                                                            <option value="">Select District</option>
                                                            {districtData.map(district => (
                                                                <option value={district.id}>{district.name} </option>

                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select Block *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={block} onChange={handleBlock} required>
                                                            <option value="">Select Block</option>
                                                            {blockData.map(blocks => (
                                                                <option value={blocks.id}>{blocks.name}</option>

                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select CHC/PHC *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={chc} onChange={handleCHCs} required>
                                                            <option value="">Select CHC/PHC</option>
                                                            {chcData.map(chc => (
                                                                <option value={chc.id}>{chc.name}</option>

                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select ANM *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={anmId?.id} onChange={handleAnmName} required>
                                                            <option value="">Select ANM</option>
                                                            {anmData.map(anm => (
                                                                <option value={anm.user_id}>{anm.name}</option>

                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {(!params?.id)?<div className="col-md-6">
                                                <div className="form-group row dob-labels">
                                                    <label className="col-sm-12">Beneficiary Added Date *</label>
                                                    <div className="col-sm-12">
                                                        {/* <input name="dob" type="date"className="form-control" placeholder="dd/mm/yyyy" required/> */}
                                                        <DatePicker
                                                            selected={benDate}
                                                            onChange={handleBenDateChange}
                                                            name="dob"
                                                            dateFormat="dd-MM-yyyy"
                                                            dropdownMode="select"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            adjustDateOnChange
                                                            minDate={new Date('01-01-1950')}
                                                            maxDate={new Date()}
                                                            placeholderText="Beneficiary Added Date"
                                                            className="form-control"
                                                            id="dobpicker"
                                                            tabIndex="5"
                                                            onKeyDown={e => e.preventDefault()}
                                                            autoComplete="off"

                                                        />
                                                    </div>
                                                    {/* <span className="doblabel-Txt"> {startDate ? calculateAgeText(startDate) : ''}</span> */}
                                                </div>
                                            </div>:""}
                                        </div>


                                        <p className="card-description">
                                            Personal
                                        </p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row primary-chetr">
                                                    <span className="primary-check"><input type="checkbox" value={makePrimary ? 0 : 1} onChange={() => setMakePrimary(makePrimary ? 0 : 1)} checked={makePrimary} /> <label className="">Make Primary</label></span>
                                                    <label className="col-sm-12">Head of Family *</label>
                                                    <div className="col-sm-12">
                                                        <input type="text" className="form-control" value={primaryName} onChange={handlePrimaryName} autoComplete="off" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Primary Mobile Number *</label>
                                                    <div className="col-sm-12">
                                                        <input type="number" className="form-control" value={mobile} onChange={handleMobile} autoComplete="off" pattern="[6789][0-9]{9}" maxLength={10} required readOnly={(params?.id) ? true : false} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Beneficiary Name *</label>
                                                    <div className="col-sm-12">
                                                        <input name='name' type="text" className="form-control" value={beneficiaryName} onChange={handleBeneficiaryName} autoComplete="off" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select Gender *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={gender} onChange={handleGender} required disabled={disable}>
                                                            <option value="">Select Gender</option>
                                                            <option value="female">Female</option>
                                                            <option value="male">Male</option>
                                                            <option value="other">Other</option>


                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select Primary Caste *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={caste} onChange={handleCaste} required>
                                                            <option value="">Select Caste</option>
                                                            <option value="general">General</option>
                                                            <option value="sc">Scheduled Caste (SC)</option>
                                                            <option value="st">Scheduled Tribe (ST)</option>
                                                            <option value="other">Other</option>


                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Aadhar Number</label>
                                                    <div className="col-sm-12">
                                                        <input type="number" className="form-control" autoComplete="off" pattern="[6789][0-9]{9}" value={addharNumber} onChange={handleAddharNumber} maxLength={12} required readOnly={(params?.id) ? true : false} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select Village *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={village} onChange={handleVillage}>
                                                            <option value="">Select Village</option>
                                                            {villageData.map(vi => (
                                                                <option value={vi.id}>{vi.name}</option>
                                                            ))}


                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {(showSchool) ? <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select School *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={school} onChange={handleSchool}>
                                                            <option value="">Select School</option>
                                                            {schoolData.map(vi => (
                                                                <option value={vi.id}>{vi.name}</option>
                                                            ))}


                                                        </select>
                                                    </div>
                                                </div>
                                            </div> : ""}
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Address *</label>
                                                    <div className="col-sm-12">
                                                        <input type="text" className="form-control" autoComplete="off" value={address} onChange={handleAddress} required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {(showRch) ? <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">RCH Id *</label>
                                                    <div className="col-sm-12">
                                                        <input type="number" className="form-control" autoComplete="off" pattern="[6789][0-9]{9}" value={rchId} onChange={handleRCHId} maxLength={12} required />
                                                    </div>
                                                </div>
                                            </div> : ""}
                                            <div className="col-md-6">
                                                <div className="form-group row dob-labels">
                                                    <label className="col-sm-12">Date of Birth *</label>
                                                    <div className="col-sm-12">
                                                        {/* <input name="dob" type="date"className="form-control" placeholder="dd/mm/yyyy" required/> */}
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={handleDateChange}
                                                            name="dob"
                                                            dateFormat="dd-MM-yyyy"
                                                            dropdownMode="select"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            adjustDateOnChange
                                                            minDate={new Date('01-01-1950')}
                                                            maxDate={new Date()}
                                                            placeholderText="Date of Birth"
                                                            className="form-control"
                                                            id="dobpicker"
                                                            tabIndex="5"
                                                            onKeyDown={e => e.preventDefault()}
                                                            autoComplete="off"

                                                        />
                                                    </div>
                                                    {/* <span className="doblabel-Txt"> {startDate ? calculateAgeText(startDate) : ''}</span> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Age </label>
                                                    <div className="col-sm-12">
                                                        <input name="age" type="text" className="form-control" value={age} onChange={handleAge} readOnly />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <button type="submit" className="btn btn-primary mr-2" onClick={handleSubmit}>Submit</button>
                                        <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                                        {/* </form> */}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <Footer />

                </div>

            </div>

            <TestingModel show={showModal} close={() => { setShowModal(false); }} id={id} benType={benProps} patientId={patientId} age={age} anmId={anmId} />
        </div>


    );
}

export default AddBeneficiary;