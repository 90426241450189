import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from 'reactjs-localstorage';

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { param, readyException } from 'jquery';


function InvItemStateHis() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  $('#example').DataTable().destroy();

  $(document).ready(function () {

    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print']
      //  responsive: true
    });
  });
  /*********************get parameter from url*********************************** */
  const params = useParams();

  /******************************************************** */

  useEffect(() => {
    document.title = "Inventory Item list";
    getItemData();
  }, [1]
  );
  /************Get all state data********************** */
  let getItemData = async (type = "") => {

    let user_type_id = '';
    if (reactLocalStorage.get("userLoginType") == "Admin") {
      user_type_id = 'admin';
    }
    // if(reactLocalStorage.get("userLoginType")=="Chc"){
    //     user_type_id=reactLocalStorage.get("user_id");
    // }


    let formData = {
      'user_id': user_type_id,
      'type': type
    };

    const res = await axios.post(API_URL + 'admin/view_inv_item_history', formData)
      .then(ress => {
        //      console.log(ress.data);
        setUserData(ress.data.Rdata);
        setIsLoading(false);
      });
    //  console.log(res.data);
  }

  const handleFilter = (event) => {
    getItemData(event.target.value);
  }


  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
          {isLoading ? (<Loader />) : (
            <>
              <div className="content-wrapper">
                <div className="row">

                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Inventory Item History </h4>


                        <div className="table-responsive pt-3">
                          {/* <table className="table table-bordered display responsive nowrap" id="example"> */}
                          <select onChange={handleFilter} className="search-option">
                            <option value="">All</option>
                            <option value="Consumable">Consumable</option>
                            <option value="Non consumable">Non Consumable</option>
                          </select>
                          <table className="table table-bordered display  nowrap" id="example">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Item</th>
                                <th>Added Quantity</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>

                              {userData.map((udata, index) => (
                                <tr key={index}>
                                  <td> {udata.type}</td>
                                  <td> {udata.item_name}</td>
                                  <td> {udata.qty}</td>
                                  <td> {((udata.added_on == null) ? "" : <Moment format="DD-MM-YYYY">{udata.added_on}</Moment>)}</td>


                                </tr>
                              )
                              )}

                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>


                </div>
              </div>

              <Footer />
            </>
          )}
        </div>

      </div>

    </div>);
}

export default InvItemStateHis;