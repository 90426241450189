import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddDistrict() {

    /********************************************* */
    const [stateData, setStateData] = useState([]);
    const [stateName, setStateName] = useState("");
    const [districtName, setDistrictName] = useState("");

    const params = useParams();

    const handleState = (e) => {
        setStateName(e.target.value);
    }

    const handleDistrictName = (e) => {
        setDistrictName(e.target.value);
    }


    const navegate = useNavigate();
    let clickCancel = () => {
        return navegate('/');
    }



    useEffect(() => {
        getStateData();
    }, [1]);

    useEffect(() => {
        document.title =(params?.id)?'Edit District Name' :'Add District Name';
        if (params?.id) {
            setDistrictName(params?.name);
            setStateName(params?.stateId);
        }

    }, []);


    /********State*********** */
    let getStateData = async () => {
        const regDataState = {
            method: 'Get',
            headers: { 'Content-Type': 'application/json' }
        }
        const getState = await fetch(API_URL + 'admin/state', regDataState);
        const getdatastate = await getState.json();
        setStateData(getdatastate);
    }

    /***********************Validation******************** */

    const handleValidation = () => {
        let formIsValid = true;
    
        if (!stateName && stateName === "") {
            formIsValid = false;
            swal('Please Select State');
        }
        if (!districtName) {
            formIsValid = false;
            swal('Please Enter District');
        }
       
        
        return formIsValid;
    }


    /************************Add District Name************************ */

    const AddDistrictData = async () => {

        let formData = {
            'name': districtName,
            'state_id': stateName
        };
      
        const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const getResps = await fetch(API_URL + 'admin/add-district', regDatas);
        const getDatas = await getResps.json();
        swal(getDatas.msg);

    }

    /*******************************Update District Name**************************** */
    const UpdateDistrictName = async () => {

        let formData = {

            'name': districtName,
            'id': params?.id

        };
       
        const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const getResps = await fetch(API_URL + 'admin/update-district', regDatas);
        const getDatas = await getResps.json();

        swal(getDatas.msg);

    }

    const handleSubmit = (events) => {
        if(handleValidation()){
            events.preventDefault();
        if (params?.id) {
            UpdateDistrictName();
        } else {
            AddDistrictData();
        }

        }
        
    }



    return (
        <div className="container-scroller">

            <Hor_header />

            <div className="container-fluid page-body-wrapper">


                <Ver_header />


                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">

                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">{(params?.id)?"Update District":"Add District"}</h4>
                                        {/* <form className="form-sample"  onSubmit={AddDistrictData}> */}
                                       

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select State *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" onChange={handleState} value={stateName} required disabled={(params?.id)?true:false}>
                                                            <option value="">Select State</option>
                                                            {stateData.map(states => (
                                                                <option value={states.id}>{states.state}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">District Name *</label>
                                                    <div className="col-sm-12">
                                                        <input className="form-control" autoComplete="off"  value={districtName} onChange={handleDistrictName} required/>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                        <button type="submit" className="btn btn-primary mr-2" onClick={handleSubmit}>Submit</button>
                                        <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                                        {/* </form> */}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <Footer />

                </div>

            </div>


        </div>


    );
}

export default AddDistrict;