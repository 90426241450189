import { schemeOranges } from "d3";
import React, { memo,useState } from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";

import {location} from "./geoData.js"

const geoUrl = location ;
  //"https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
// const geoUrl ="https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const plotData = (Dist_Name,dataMap) => {
  let ln=dataMap.length;
     let data="";
  for(let i=0;i<ln;i++){// console.log("m"+dataMap[i].dist_name); console.log(Dist_Name);
     if(Dist_Name == dataMap[i].dbc_name){
      // console.log(dataMap[i].dist_name+dataMap[i].severe);
       data= "Severe: "+dataMap[i].severe+" - "+ "Moderate: " + dataMap[i].moderate+ " - "+"Mild: " + dataMap[i].mild+" - " + "Non-anemic: " + dataMap[i].nonanemic;
     }
      
     }
     return data;
  }
 
  const getColor =(dist_name,actual_dis_name,dataMap) =>{
    if(dist_name===actual_dis_name){
      return '#f0af19';
    }else{
     // return "#408c58";
       //addded
       let ln=dataMap?.length;
       let data="";
        for(let i=0;i<ln;i++){
          if(dist_name == dataMap[i].dbc_name){
           // data= dataMap[i].severe*100;
            data= dataMap[i].severe;
          }    
       }
    if(data==0){ 
       return '#FFF';//'#FDE7E5';
       }
        if(data>0 && data<=300){ 
          return '#f3c2be';
      }
        else if(data>300 && data<=700){ 
          return '#f1a9a3';
      }
        else if(data>700 && data<=1500){ 
           return '#ef958d';
        }
        else if(data>1500 && data<=3000){ 
          return '#ef837a';
       }
       else if(data>3000 && data<=4500){ 
        return '#e96c61';
          }
          else if(data>4500 && data<=6000){ 
            return '#e96c61';
        }else if(data>6000 && data<=7500){ 
          return '#e9493a';
      }else if(data>7500 && data<=9000){ 
          return '#eb3625';
      }
       else if(data>9000 && data<=11500){ 
          return '#f52a17';
      }
      else if(data>11500 && data<=13000){ 
        return '#f51904';
    }
     else if(data>13000 && data<=14500){ 
        return '#cf1604';
    }
       else if(data>14500   ){ 
          return '#ab1203';
       }
       else{
          return '#FFF';//'#408c58';
       }
      
    }
  }
   


const MapCharts = (props) => {
	//disName
  const { setTooltipContent , dataMap , disName} =props;
	 const [position, setPosition] = useState({ coordinates: [86.5, 23.5], zoom: 8 });
 // console.log("bbbb"+disName);
	
  return (
    <>
      <ComposableMap data-tip="" projectionConfig={{ scale: 900 }}   style={{width: '400', height: '300'}} >
		 <ZoomableGroup  zoom={position.zoom} center={position.coordinates} > 
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    const { State_Name, Dist_Name } = geo.properties;
                    setTooltipContent(`${Dist_Name} - ${plotData(Dist_Name,dataMap)}`);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                  style={{
                    default: {
                      fill:getColor(geo.properties.Dist_Name,disName,dataMap), //"#bf00ff",
                      stroke: "#607D8B",
                      strokeWidth: 0.08,
                      outline: "none"
                    },
                    hover: {
                      fill: "#F53",
                      stroke: "#607D8B",
                      strokeWidth: 0.08,
                      outline: "none"
                    },
                    pressed: {
                      fill: "#E42",
                      stroke: "#607D8B",
                      strokeWidth: 0.08,
                      outline: "none"
                    },
                    height:"100px",
                    width:"100px"
                  }}
                />
              ))
            }
          </Geographies>
         </ZoomableGroup>
      </ComposableMap>
    </>
  );
};

export default memo(MapCharts);
