import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Button, Modal } from "react-bootstrap";
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "../front/loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { param, readyException } from 'jquery';


function DetailOfIssuedItem() {
  const navigat = useNavigate();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [listData, setListData] = useState([]);

  const [userItem, setUserItem] = useState([]);

  const params = useParams();
  const inputTypeRef = React.useRef();
  const inputTypeRefType = React.useRef();


  $('#example1').DataTable().destroy();
  $(document).ready(function () {

    $('#example1').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort'],
        "order": [[2, "desc"]]
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
      responsive: true
    });
    var dt1 = $('#example1').DataTable();
    dt1.columns([0, 1, 2]).visible(false);

  });
  useEffect(() => {
    document.title = "Detail of issued item";
    getItemData();
    getItemList();
  }, [1]
  );



  let getItemData = async (type = "", item = "") => {
    let user_type_id = '';
    if (reactLocalStorage.get("userLoginType") == "Admin") {
      user_type_id = 'admin';
    }
    if (reactLocalStorage.get("userLoginType") == "Chc" || reactLocalStorage.get("userLoginType") == "Store") {
      user_type_id = reactLocalStorage.get("user_id");
    }
    if (reactLocalStorage.get("userLoginType") == "District"  || reactLocalStorage.get("userLoginType") == "DistrictStore" ) {
      user_type_id = reactLocalStorage.get("district");
    }
    let formData = {
      'req_to': user_type_id,
      'req_by': params.id,
      'type': type,
      'item': item
    };
    const res = await axios.post(API_URL + 'admin/get-all-issued-item-details', formData).then(ress => {
      setUserData(ress.data.Rdata);
      setIsLoading(false);
    });
  }

  /****************** item****************************** */
  const handleFilter = (event, item = '') => {
    getItemData(event.target.value, item);
  }

  const handleFilterItem = (type = '', event) => {
    getItemData(type, event.target.value);
  }
  /*************reseting value**************************** */
  const settingPre = () => {
    inputTypeRefType.current.value = ""
  }
  const settingPre1 = () => {
    inputTypeRef.current.value = ""
  }


  /************Get all item data********************** */
  let getItemList = async (type = "") => {
    let data = { 'type': type };
    const res = await axios.post(API_URL + 'admin/item_list', data).then(ress => {
      // console.log(ress.data);
      setUserItem(ress.data.Rdata);
      setIsLoading(false);
    });
  }



  /******************Redirecting to edit page***************************** */
  let editUser = (id) => {
    //console.warn("id="+data);
    return navigat('/edit_inv_item/' + id);
  }
  const viewList = async (trans_id) => {
    const data = { 'trans_id': trans_id }
    const res = await axios.post(API_URL + 'admin/get-requested-list', data).then(ress => {
      if (ress.status == '200') {
        setModal(true);
        setListData(ress.data.Rdata)
        // console.log(ress.data.Rdata);
      }
    });
  }
  const close = () => {
    setModal(false)
  }

  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">

              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Detail of all issued item </h4>

                   
                      <div className="table-responsive pt-3">

                        <select onChange={(e) => { handleFilterItem('', e); settingPre1(); }} ref={inputTypeRefType} className="search-option-s">
                          <option value="">Item List </option>
                          {userItem.map(item => (
                            <option value={item.id}>{item.item_name}</option>
                          ))}
                        </select>

                        <select onChange={(e) => { handleFilter(e, ''); settingPre(); }} ref={inputTypeRef} className="search-option">
                          <option value="">Item Type</option>
                          <option value="Consumable">Consumable</option>
                          <option value="Non consumable">Non Consumable</option>
                        </select>

                        <table className="table table-bordered display   nowrap" id="example1">
                          <thead>
                            <tr>
                              <th>Requested To</th>
                              <th>Item Type</th>
                              <th>Requested By-Id</th>
                              <th>Requested By</th>
                              <th>Item Name</th>
                              <th>Request Type</th>
                              <th>Requested Qty</th>
                              <th>Issued Qty</th>
                              <th>Request Date</th>
                              <th>Issued Date</th>

                            </tr>
                          </thead>
                          <tbody>

                            {userData.map((udata, index) => (
                              <tr key={index} >
                                <td> {reactLocalStorage.get('user_name')}</td>
                                <td> {udata.type}</td>
                                <td> {udata.req_by}</td>
                                <td> {udata.req_by_name}</td>
                                <td> {udata.item_name}</td>
                                <td> {udata.req_type}</td>
                                <td> {udata.req_qty}</td>
                                <td> {udata.giv_qty}</td>
                                <td><Moment format="DD-MM-YYYY" >{udata.date_of_req}</Moment></td>
                                <td><Moment format="DD-MM-YYYY" >{udata.issued_date}</Moment></td>

                              </tr>
                            )
                            )}

                          </tbody>
                        </table>
                      </div>
                  
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>

    </div>);
}

export default DetailOfIssuedItem;