import React from 'react';

function Loading(){
  return(
      <>
      <div className='loaderblock' id='loading'>
        <img id="" src={process.env.PUBLIC_URL+"/images/preloader.gif"} />
      </div>
      </>
  );
}
export default Loading;