import React,{useState,useEffect} from 'react';
import { Link,NavLink } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

import $, { readyException } from 'jquery';

function Ver_header(){
  
  const [hide,setHide] = useState(false);

 useEffect(()=>{
  if(reactLocalStorage.get('userLoginType')=="Admin"){
      setHide(true);
   }
 },[1]);

     
 $(document).ready(function () {
  $('.sub-menu li').click(function(e){
    e.stopPropagation();
});
  //  $('button.navbar-toggler.navbar-toggler.align-self-center.d-none.d-lg-flex').trigger('click');
  //  $("button.navbar-toggler.navbar-toggler.align-self-center.d-none.d-lg-flex").ready(function() {
  //  $(this).click();
  //  }); 
});

  
//console.log(hide);

    return(
<nav className="sidebar sidebar-offcanvas" id="sidebar">
<ul className="nav">
  <li className="nav-item">
    <NavLink className="nav-link" to="/dashboard">
      <i className="typcn typcn-device-desktop menu-icon"></i>
      <span className="menu-title">Dashboard </span>
    </NavLink>
  </li> 
  <li className="nav-item">
    <a className="nav-link" data-toggle="collapse" href="#anm" aria-expanded="false" aria-controls="anm">
      <i className="typcn typcn-compass menu-icon"></i>
      <span className="menu-title">User Management </span>
      <i className="menu-arrow"></i>
    </a>
    <div className="collapse" id="anm">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/add_user">Add ANM/RBSK</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/all_users">View ANM/RBSK</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/all_hb_meter_req">HB Meter Requests</NavLink></li>
      </ul>
    </div>
  </li>
  <li className="nav-item" style={{"display":(hide)?"":"none"}}>
    <a className="nav-link" data-toggle="collapse" href="#chc" aria-expanded="false" aria-controls="chc">
      <i className=" typcn typcn-weather-snow menu-icon"></i>
      <span className="menu-title">CHC Management </span>
      <i className="menu-arrow"></i>
    </a>
     <div className="collapse" id="chc">
     <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/addChc">Add CHC</NavLink></li>
     
        <li className="nav-item"> <NavLink className="nav-link" to="/allChc">View CHC</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/hbmeter_chc_req">CHC Item Requests</NavLink></li>
      </ul>
    </div>

  </li>
  <li className="nav-item" style={{"display":(hide)?"":"none"}}>
    <a className="nav-link" data-toggle="collapse" href="#block" aria-expanded="false" aria-controls="block">
      <i className=" typcn typcn-world menu-icon"></i>
      <span className="menu-title">Block Management </span>
      <i className="menu-arrow"></i>
    </a>
    <div className="collapse" id="block">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/addBlock">Add Block</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/allBlockUser">View Block</NavLink></li>
      </ul>
    </div>
  </li>
  <li className="nav-item" style={{"display":(hide)?"":"none"}}>
    <a className="nav-link" data-toggle="collapse" href="#District" aria-expanded="false" aria-controls="District">
      <i className=" typcn typcn-social-dribbble menu-icon"></i>
      <span className="menu-title">District Management </span>
      <i className="menu-arrow"></i>
    </a>
    <div className="collapse" id="District">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/addDistrict">Add District</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/allDistrictUser">View District</NavLink></li>
      </ul>
    </div>
  </li>

  <li className="nav-item" style={{"display":(hide)?"":"none"}}>
    <a className="nav-link" data-toggle="collapse" href="#trans" aria-expanded="false" aria-controls="trans">
      <i className=" typcn typcn-export menu-icon"></i>
      <span className="menu-title">Transfer Management </span>
      <i className="menu-arrow"></i>
    </a>
    <div className="collapse" id="trans">
      <ul className="nav flex-column sub-menu">
      <li className="nav-item"> <NavLink className="nav-link" to="/tranfer_beneficiary">Transfer Beneficiary</NavLink></li>
    
      <li className="nav-item"> <NavLink className="nav-link" to="/view_ben_archive">Transfer Beneficiary Archive</NavLink></li>
     
   
      <li className="nav-item"> <NavLink className="nav-link" to="/transfer_anm_medical">Transfer Anm/Medical Team </NavLink></li>
     
      <li className="nav-item"> <NavLink className="nav-link" to="/view_anm_archive">Transfer Anm/Medical Archive</NavLink></li>
      </ul>
    </div>
  </li>


  <li className="nav-item">
    <a className="nav-link" data-toggle="collapse" href="#patient" aria-expanded="false" aria-controls="patient">
      <i className="typcn typcn-radar menu-icon"></i>
      <span className="menu-title">Beneficiary Management</span>
      <i className="menu-arrow"></i>
    </a>
    <div className="collapse" id="patient">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/all_patient">View Users</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/allPatient_csv">Reports </NavLink></li>
     
        <li className="nav-item"> <NavLink className="nav-link" to="/reffered_cases"> Referred Cases </NavLink></li>
      </ul>
    </div>
  </li>
  
  <li className="nav-item" style={{"display":(hide)?"":"none"}} >
    <a className="nav-link" data-toggle="collapse" href="#inv" aria-expanded="false" aria-controls="inv">
      <i className="typcn typcn-social-instagram-circular menu-icon"></i>
      <span className="menu-title">Inventory Item</span>
      <i className="menu-arrow"></i>
    </a>
    <div className="collapse" id="inv" >
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/add_item">Add Item</NavLink></li>
     
        <li className="nav-item"> <NavLink className="nav-link" to="/view_item_list">View Item List</NavLink></li>
      </ul>
    </div>
    
  </li>

  <li className="nav-item" >
    <a className="nav-link" data-toggle="collapse" href="#mnginv" aria-expanded="false" aria-controls="mnginv">
      <i className="typcn typcn-eject menu-icon"></i>
      <span className="menu-title">Manage Inventory </span>
      <i className="menu-arrow"></i>
    </a>
    <div className="collapse" id="mnginv" >
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/add_inv_item">Add Item</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/view_inventory">View Inventory item</NavLink></li>
    
    {(reactLocalStorage.get("userLoginType")=="Chc")?
    
        <li className="nav-item"> <NavLink className="nav-link" to="/add_req_inventory">Item Request</NavLink></li>:""}
    {(reactLocalStorage.get("userLoginType")=="Chc")?
     
        <li className="nav-item"> <NavLink className="nav-link" to="/my_inventory_req">My Items Request</NavLink></li>:""}
   
        <li className="nav-item"> <NavLink className="nav-link" to="/inventory_req">Inventory Requests</NavLink></li>
      </ul>
    </div>
    
  </li>

  <li className="nav-item" >
    <a className="nav-link" data-toggle="collapse" href="#notif" aria-expanded="false" aria-controls="notif">
      <i className=" typcn typcn-world menu-icon"></i>
      <span className="menu-title">Notification Management </span>
      <i className="menu-arrow"></i>
    </a>
    <div className="collapse" id="notif">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/notification">Send Notification</NavLink></li>
      </ul>
    </div>
   
  </li>


  <li className="nav-item" style={{"display":(hide)?"":"none"}}>
    <a className="nav-link" data-toggle="collapse" href="#master" aria-expanded="false" aria-controls="master">
      <i className="typcn typcn-briefcase menu-icon"></i>
      <span className="menu-title">Master Data </span>
      <i className="menu-arrow"></i>
    </a>
    <div className="collapse" id="master">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/view_states">States</NavLink></li>
     
        <li className="nav-item"> <NavLink className="nav-link" to="/view_districts">Districts</NavLink></li>
   
        <li className="nav-item"> <NavLink className="nav-link" to="/view_blocks">Blocks</NavLink></li>
   
        <li className="nav-item"> <NavLink className="nav-link" to="/view_chc">CHCs</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/view_sub_center">Sub Center</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/view_school">Schools</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/view_village">Villages</NavLink></li>
    
        <li className="nav-item"> <NavLink className="nav-link" to="/setting">Settings</NavLink></li>
      </ul>
    </div>
  </li>

</ul>

</nav>
);
}
export default Ver_header;   