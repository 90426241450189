import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import $, { readyException } from 'jquery';
import { toExcel } from 'to-excel';

function AllRecoveredList() {

    const navigat = useNavigate();

    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pages, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalData, setTotalData] = useState();
    const [searchText, setSearchText] = useState("");




    useEffect(() => {
        document.title = "Recovered List";
        getTotalPatientData()

        //getExportPatientData();
    }, [1]
    );

    useEffect(() => {

        getAllPatientData(pages);

    }, [pages]);




    /************Get all patient user********************** */
    let getAllPatientData = async (page) => {
        //setUserData([]); 
        let datas = { page: page, per_page: perPage };
        var url = "";

        url = 'admin/getServerSideRecoveredBeneficiaries';


        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                //  console.log(ress.data);
                setUserData(ress.data.Rdata);
                setIsLoading(false);
            });
        //  console.log(res.data);
    }

    /************Get total user********************** */
    let getTotalPatientData = async () => {


        let datas = {};
        //	console.log(datas);
        var url = "";

        url = 'admin/getServerSideRecoveredBeneficiariesTotal';

        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                // console.log(ress.data.Rdata.length);
                setTotalRows(ress.data.Rdata.length);
                setTotalData(ress.data.Rdata);

            });


    }


    const fetchUsers = async page => {
        setLoading(true);
        getAllPatientData(page);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        getAllPatientData(page)
        setPerPage(newPerPage);
        setLoading(false);
    };




    var headers = [
        { label: 'Name', field: 'name' },
        { label: 'Beneficiary Type', field: 'beneficiary_type' },
        { label: 'Patient id', field: 'patient_id' },
        { label: 'Family Head Name', field: 'guardian_name' },
        { label: 'Mobile No', field: 'mobile_no' },
        { label: 'Gender', field: 'gender' },
        { label: 'Caste', field: 'caste' },
        { label: 'Age id', field: 'age' },
        { label: 'Pregnancy Duration', field: 'preg_duration' },
        { label: 'Address', field: 'address' },
        { label: 'Staff Type', field: 'staff_type' },
        { label: 'Added By Anm/Medical Team Id', field: 'anm_teacher_id' },
        { label: 'Rch id', field: 'rch_uid' },
        { label: 'District', field: 'district_name' },
        { label: 'Block', field: 'block_name' },
        { label: 'CHC/PHC', field: 'chc_name' },
        { label: 'Village', field: 'village_name' },
        { label: 'School', field: 'school_name' },
        { label: 'Haemoglobin value', field: 'haemoglobin_value' },
        { label: 'Haemoglobin based status', field: 'haemoglobin_based_status' },
        { label: 'Height', field: 'height' },
        { label: 'Weight', field: 'weight' },
        { label: 'BMI value', field: 'bmi_value' },
        { label: 'BMI Status', field: 'bmi_category' },
        { label: 'Pregnancy Duration(At time of test)', field: 'pregnancy_duration' },
        { label: 'Latitude', field: 'ts_lat' },
        { label: 'Longitude', field: 'ts_log' },
        { label: 'Venue', field: 'venue' },
        { label: 'Current Address', field: 'curr_address' },
        { label: 'Referral status', field: 'referral_status' },
        { label: 'Referred At', field: 'referred_center_name' },
        { label: 'Test done on', field: 'investigated_on' },
        { label: 'Test Done By (Not ref.)', field: 'anm_teacher_id' },
        { label: 'Test Done By (ref.)', field: 'test_hos_name' },
        { label: 'Referred By Hospital', field: 'referred_by_hos_name' },
        { label: 'Medician', field: 'medician' },
        { label: 'Dose', field: 'dose' },
        { label: 'Treatment given by', field: 'treat_giv_by_name' },
        { label: 'Follow up date', field: 'follow_up_date' },
        { label: 'Treatment Given on', field: 'treatment_on' },
        { label: 'Added On', field: 'added_on' },


    ]


    const dwn = () => {

        var content = toExcel.exportXLS(headers, totalData, 'AllBeneficiaryData');
        // in node you must open or save the content
        //require('fs').writeFileSync('filename.xls', content);


    }


    const ExpandedComponent = ({ data }) => {
        return (

            <>
                <table className='table table-striped  sadi'>
                    <tr><td> <b>Pregnancy Duration - </b>  {data.preg_duration}</td></tr>

                    <tr><td> <b>Address</b>  - {data.address}</td></tr>
                    <tr><td> <b>Staff Type - </b> {data.staff_type}</td></tr>
                    <tr><td> <b>Added By Anm/Medical Team Id - </b> {data.anm_teacher_id}</td></tr>
                    <tr><td> <b>Rch id - </b> {data.rch_uid}</td></tr>
                    <tr><td> <b>District - </b> {data.district_name}</td></tr>
                    <tr><td> <b>Block - </b> {data.block_name}</td></tr>
                    <tr><td> <b>CHC/PHC - </b> {data.chc_name}</td></tr>
                    <tr><td> <b>Village - </b> {data.village_name}</td></tr>
                    <tr><td> <b>School - </b> {data.school_name}</td></tr>
                    <tr><td> <b>Haemoglobin value - </b> {data.haemoglobin_value}</td></tr>
                    <tr><td> <b>Haemoglobin based status - </b> {data.haemoglobin_based_status != null ? data.haemoglobin_based_status.toUpperCase() : ""}</td></tr>

                    <tr><td> <b>Height - </b> {data.height}</td></tr>
                    <tr><td> <b>Weight - </b> {data.weight}</td></tr>
                    <tr><td> <b>BMI value - </b> {data.bmi_value}</td></tr>
                    <tr><td> <b>BMI Status - </b>{data.bmi_category}</td></tr>
                    <tr><td> <b>Pregnancy Duration(At time of test) - </b> {data.pregnancy_duration}</td></tr>
                    <tr><td> <b>Geo Location - Test - </b>  Latitude - {data.ts_lat} , Longitude - {data.ts_log}</td></tr>
                    <tr><td> <b>Venue - </b> {data.venue}</td></tr>
                    <tr><td> <b>Current Address - </b> {data.curr_address}</td></tr>
                    <tr><td> <b>Referral status - </b> {data.referral_status == 'reffered' ? "Referred" : "Not Referred"}</td></tr>
                    <tr><td> <b>Referred At - </b> {data.referred_center_name}</td></tr>
                    <tr><td> <b>Test done on - </b> <Moment format="DD-MM-YYYY" >{data.investigated_on}</Moment></td></tr>
                    <tr><td> <b>Test Done By - </b> {data.referral_status == 'reffered' ? data.test_hos_name : data.anm_med_nur_name}</td></tr>
                    <tr><td> <b>Referred By Hospital - </b> {data.referred_by_hos_name}</td></tr>
                    <tr><td> <b>Medician - </b> {data.medician}</td></tr>
                    <tr><td> <b>Dose - </b> {data.dose}</td></tr>
                    <tr><td> <b>Treatment given by - </b>{data.treat_giv_by_name}</td></tr>
                    <tr><td> <b>Follow up date - </b> {data.follow_up_date}</td></tr>
                    <tr><td> <b>Treatment Given on - </b> <Moment format="DD-MM-YYYY" >{data.treatment_on}</Moment></td></tr>
                    <tr><td> <b>Added On - </b> <Moment format="DD-MM-YYYY" >{data.added_on}</Moment></td></tr>
                </table>
            </>

        )


    }







    const columns = [

        {
            name: 'Name',
            selector: row => row.name,
            //   cellExport: row => row.name,
            sortable: true,
            // width: '110px',

        },

        {
            name: 'Beneficiary Type',
            selector: row => row.beneficiary_type,
            //   cellExport: row => row.beneficiary_type,
            sortable: true,

        },
        {
            name: 'Patient id',
            selector: row => row.patient_id,
            sortable: true,
            //   cellExport: row => row.patient_id,
            // width: '100px',
        },
        {
            name: 'Family Head Name',
            selector: row => row.guardian_name,
            //  cellExport: row => row.guardian_name,
            sortable: true,

        },

        {
            name: 'Mobile No',
            selector: row => row.mobile_no,
            sortable: true,
            //width: '75px',
            //  cellExport: row =>row.mobile_no,
            //cell: (row) => <Link to={'/patient_all_member_list/rp/' + row.mobile_no}>{row.mobile_no}</Link>
        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true,
            //   cellExport: row =>row.gender,
            cell: (row) => <>{row.gender != null ? row.gender.toUpperCase() : ""}</>,

        },
        {
            name: "Caste",
            selector: row => row.caste,
            sortable: true,
            // cellExport: row =>row.caste,
            cell: (row) => <>{row.caste != null ? row.caste.toUpperCase() : ""}</>,

        },
        {
            name: "Age",
            selector: row => row.age,
            sortable: true,


        },

    ];


    let AllRecoveredSearchData = async (seeach) => {

        let datas = { page: pages, per_page: perPage, search: seeach };
        var url = "";

        url = 'admin/getSearchedServerSideRecoveredBeneficiary';

        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                //  console.log(ress.data);

                setUserData(ress.data.Rdata);
                setIsLoading(false);


            });
        //  console.log(res.data);
    }

    let TotalRecoveredData = async (serach) => {

        let datas = { search: serach };
        

        var url = "";

        url = 'admin/getSearchedServerSideRecoveredBeneficiaryTotal';

        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                // console.log(ress.data.Rdata.length);
                setTotalRows(ress.data.Rdata.length);
                setTotalData(ress.data.Rdata);

            });

    }

    const handleSercahBox = (e) => {
        const inputValue = e.target.value;
        setSearchText(inputValue);
        AllRecoveredSearchData(inputValue);
        TotalRecoveredData(inputValue);

    }



    return (

        <div className="container-scroller">

            <Hor_header />

            <div className="container-fluid page-body-wrapper">

                <Ver_header />

                <div className="main-panel">
                    {isLoading ? (<Loader />) : (
                        <>
                            <div className="content-wrapper">



                                <div className="row">


                                    <div className="col-lg-12 grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="card-title">Recovered List</h4>
                                                <div className="row">
                                                    <div className="col-lg-6 grid-margin">
                                                        <button className="btn btn-success" onClick={dwn}  >Export</button>
                                                        {/* <input type="text"  name="search" className="" placeholder='Search user by mobile'  /> <button type="bytton" onClick="search by mobile " >Search</button> */}
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div className='cusSearch-box-item'>
                                                            <label>Search:
                                                                <input type='text' value={searchText} onChange={handleSercahBox} />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="table-responsive">



                                                    <DataTable

                                                        columns={columns}
                                                        data={userData}
                                                        progressPending={loading}
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={totalRows}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}
                                                        expandableRows={true}
                                                        expandableRowsComponent={ExpandedComponent}
                                                    />



                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <Footer />
                        </>
                    )}
                </div>

            </div>

        </div>);
}

export default AllRecoveredList;