import React ,{useEffect,useState} from 'react';
import {useParams,useNavigate,Link} from "react-router-dom";





function Privacy(){

   

    return(
     
        <div className="container-scroller">
         
      
       
        <div className="container page-body-wrapper">
        
      
         
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                
                
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Privacy Policy </h4>
                     
                        <p>* Institute of Economic Growth, Delhi , India has built the T3 AMB app. This enterprise app is provided by Institute of Economic Growth. at no cost to its users and is intended for use as it is.</p>
                        <p>*App usage is restricted to the enterprise user.</p>
                        <p>*This page is used to inform visitors regarding our policies for using our App / Services.</p>
                        <p>*We are not taking your personal information in any aspect.</p>
                        <p>*In lead generation pages user can use camera. For this purpose, we are using camera permission in app.Link to privacy policy of third party service providers used by the app</p>
                        <p>a) Google Play Services</p>
                        <p>Changes to This Privacy Policy</p>
                        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. we will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
                        <p><b>Contact Us</b></p>
                        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us. Please mail in your such queries to testtreattalkt3@gmail.com</p>
                        <p>Institute of Economic Growth<br/>
                           AMB Unit, University of Delhi Enclave<br/>
                           Delhi University North Campus, Delhi - 110007<br/> 
                           +919868869720<br/> 
                           testtreattalkt3@gmail.com<br/> </p>
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
           
              
           
          </div>
       
        </div>
      
      </div>);
}

export default Privacy;