import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddStore() {

    /********************************************* */
    const [stateData, setStateData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const [chcData, setChcData] = useState([]);
    const [mobile, setMobile] = useState("");
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [block, setBlock] = useState("");
    const [chc, setChc] = useState("");

    /******************pass & Cpass************************** */
    const [passData, setPassData] = useState();
    const [cpassData, setCpassData] = useState();
    const params = useParams();

    /********************medicalteam Hide Show************************* */


    const navegate = useNavigate();
    let clickCancel = () => {
        return navegate('/');
    }



    /*************userd as document.ready -  useEffect****************** */
    useEffect(() => {
        getStateData();
    }, [1]);

    useEffect(async () => {
        document.title = (params?.id) ? 'Edit Storekeeper' : 'Add Storekeeper';
        if (params?.id) {
            setMobile(params?.mob);
            setState(params?.stateId);
            setDistrict(params?.districtId);
            setBlock(params?.blockId);
            setChc(params?.chc);
            if (params?.stateId) {
                let state_id = params?.stateId;
                const regDataDistrict = {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'state_id': state_id })
                }
                const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
                const getdataDistrict = await getDistrict.json();
                // console.log(getdataDistrict.Rdata);
                if (getdataDistrict.Rdata.length > 0) {
                    setDistrictData(getdataDistrict.Rdata);
                }
            }
            if (params?.districtId) {
                let district_id = params?.districtId;
                const reDataDistrict = {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'district_id': district_id })
                }
                const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
                const getBlockData = await getBlock.json();
                //console.log(getBlockData);
                if (getBlockData.Rdata.length > 0) {
                    setBlockData(getBlockData.Rdata);
                }
            }
            if (params?.blockId) {
                let block_id = params?.blockId;

                const reDataBlock = {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'block_id': block_id })
                }
                /************chc************** */
                const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
                const getChcData = await getChc.json();
                //console.log(getBlockData);
                if (getChcData.Rdata.length > 0) {
                    setChcData(getChcData.Rdata);
                }
            }
        }
    }, []);

    /********State*********** */
    let getStateData = async () => {
        const regDataState = {
            method: 'Get',
            headers: { 'Content-Type': 'application/json' }
        }
        const getState = await fetch(API_URL + 'admin/state', regDataState);
        const getdatastate = await getState.json();
        setStateData(getdatastate);
    }

    const handleMobileNumber = (e) => {
        setMobile(e.target.value);

    }

    const handleStates = async (e) => {
        setState(e.target.value);
        if (e.target.value) {
            let state_id = e.target.value;
            const regDataDistrict = {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'state_id': state_id })
            }
            const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
            const getdataDistrict = await getDistrict.json();
            // console.log(getdataDistrict.Rdata);
            if (getdataDistrict.Rdata.length > 0) {
                setDistrictData(getdataDistrict.Rdata);
            }
        }
    }

    const handleDistrict = async (e) => {
        setDistrict(e.target.value);
        if (e.target.value) {
            let district_id = e.target.value;
            const reDataDistrict = {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'district_id': district_id })
            }
            const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
            const getBlockData = await getBlock.json();
            //console.log(getBlockData);
            if (getBlockData.Rdata.length > 0) {
                setBlockData(getBlockData.Rdata);
            }
        }
    }

    const handleBlocks = async (e) => {
        setBlock(e.target.value)
        if (e.target.value) {
            let block_id = e.target.value;

            const reDataBlock = {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'block_id': block_id })
            }
            /************chc************** */
            const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
            const getChcData = await getChc.json();
            //console.log(getBlockData);
            if (getChcData.Rdata.length > 0) {
                setChcData(getChcData.Rdata);
            }
        }
    }


    const handleChc = (e) => {
        setChc(e.target.value);
    }



    const getPassword = (e) => {
        setPassData(e.target.value);

    }
    const getConfirm = (e) => {
        setCpassData(e.target.value);


    }

    /***************validation**************************** */


    const handleValidation = () => {
        let formIsValid = true;

        if (!mobile) {
            swal('Please enter mobile');
            return formIsValid = false;
        } else {
            let mobRegExp = "^[0-9]{10}";
            //let testemail= emailRegExp.test(data.get('email'));
            let mobname = mobile.match(mobRegExp);
            if (mobname) {
                formIsValid = true;
            } else {
                swal('Invalid mobile');
                return formIsValid = false;
            }
        }
        if (!params?.id) {
            if (!passData) {
                swal('Please enter password');
                return formIsValid = false;
            } else {
                formIsValid = true;
            }
            if (!cpassData) {
                swal('Please enter confirm password');
                return formIsValid = false;
            } else {
                formIsValid = true;
            }

            if (passData != cpassData) {
                swal("Password  and confirm pasword not matched");
                return formIsValid = false;
            } else {
                formIsValid = true;
            }
        }
        if (!state && state === "") {
            formIsValid = false;
            swal("Please Select State");
        }

        if (!district && district === "") {
            formIsValid = false;
            swal("Please Select District");
        }
        if (!block && block === "") {
            formIsValid = false;
            swal("Please Select Block");
        }
        if (!chc && chc === "") {
            formIsValid = false;
            swal("Please Select CHc");
        }

        return formIsValid;
    }


    /*****************submit******************************* */
    const addStoreKeeper = async () => {
        let formData = {

            'mobile_no': mobile,
            'state_id': state,
            'district_id': district,
            'block_id': block,
            'chc_phc_id': chc,
            'password': passData,
            'req_user_type': "admin"

        };


        const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const getResps = await fetch(API_URL + 'chc/registerStoreManager', regDatas);
        const getDatas = await getResps.json();

        swal(getDatas.msg);


    }

    const updateStoreKeeper = async () => {
        let formData = {

            'mobile_no': mobile,
            'state_id': state,
            'district_id': district,
            'block_id': block,
            'chc_phc_id': chc,
            'user_id': params?.id

        };


        const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const getResps = await fetch(API_URL + 'chc/editStoreUserData', regDatas);
        const getDatas = await getResps.json();

        swal(getDatas.msg);
    }

    const handleSubmit = (events) => {
        if (handleValidation()) {
            events.preventDefault();
            if (params?.id) {
                updateStoreKeeper();
            } else {
                addStoreKeeper();
            }
        }


    }



    /******************************* */
    return (
        <div className="container-scroller">

            <Hor_header />

            <div className="container-fluid page-body-wrapper">


                <Ver_header />


                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">

                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">{ params?.id ? "Update Storekeepers":"Add Storekeepers"}</h4>
                                        {/* <form className="form-sample" > */}
                                        <p className="card-description">
                                            User Info
                                        </p>
                                        <div className="row">


                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Mobile Number *</label>
                                                    <div className="col-sm-12">
                                                        <input value={mobile} onChange={handleMobileNumber} type="tel" pattern="[6789][0-9]{9}" maxLength={10} className="form-control" autoComplete="off" required />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <p className="card-description">
                                            Geographical Data
                                        </p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select State *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={state} onChange={handleStates} required disabled={params?.id ? true : false}>
                                                            <option value="">Select State</option>
                                                            {stateData.map(states => (
                                                                <option value={states.id}>{states.state}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select District *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={district} onChange={handleDistrict} required disabled={params?.id ? true : false}>
                                                            <option value="">Select</option>
                                                            {districtData.map(district => (
                                                                <option value={district.id}>{district.name}  {district.code} </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select Block *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={block} onChange={handleBlocks} required disabled={params?.id ? true : false}>
                                                            <option value="">Select</option>
                                                            {blockData.map(blocks => (
                                                                <option value={blocks.id}>{blocks.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select CHC/PHC *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={chc} onChange={handleChc} required disabled={params?.id ? true : false}>
                                                            <option value="">Select</option>
                                                            {chcData.map(chc => (
                                                                <option value={chc.id}>{chc.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>




                                        <p className="card-description">

                                        </p>
                                        {!params?.id ? <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Password *</label>
                                                    <div className="col-sm-12">
                                                        <input type="password" className="form-control" id="exampleInputPassword1" onChange={getPassword} minlength="4" maxlength="15" name="password" placeholder="Password" autoComplete="off" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Re-enter Password *</label>
                                                    <div className="col-sm-12">
                                                        <input type="password" className="form-control" id="exampleInputConfirmPassword1" onChange={getConfirm} minlength="4" maxlength="15" name="cpassword" placeholder="Re-enter Password" autoComplete="off" required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ""}



                                        <button type="submit" className="btn btn-primary mr-2" onClick={handleSubmit}>Submit</button>
                                        <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                                        {/* </form> */}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <Footer />

                </div>

            </div>


        </div>


    );
}

export default AddStore;