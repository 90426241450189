import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { reactLocalStorage } from 'reactjs-localstorage';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddSubCenter() {

    /********************************************* */
    const [stateData, setStateData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const [chcData, setChcData] = useState([]);
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [block, setBlock] = useState("");
    const [chc, setChc] = useState("");
    const [subCenter, setSubCenter] = useState("");
    const params = useParams();


    const navegate = useNavigate();
    let clickCancel = () => {
        return navegate('/');
    }


    useEffect(() => {
     getStateData();
    }, [1]);

    useEffect(async () => {
        console.log("pra", params);
        document.title = (params?.id) ? 'Edit Sub Center' : 'Add Sub Center';
        if (params?.id) {
            setState(params?.storeId);
            setDistrict(params?.districtId);
            setBlock(params?.blockId);
            setChc(params?.chc);
            setSubCenter(params?.name)
            if (params?.storeId) {
                let state_id = params?.storeId;
                const regDataDistrict = {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'state_id': state_id })
                }
                const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
                const getdataDistrict = await getDistrict.json();
                // console.log(getdataDistrict.Rdata);
                if (getdataDistrict.Rdata.length > 0) {
                    setDistrictData(getdataDistrict.Rdata);
                }
            }

            if (params?.districtId) {
                let district_id = params?.districtId;
                //console.log(district_id);
                const reDataDistrict = {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'district_id': district_id })
                }
                const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
                const getBlockData = await getBlock.json();
                //console.log(getBlockData);
                if (getBlockData.Rdata.length > 0) {
                    setBlockData(getBlockData.Rdata);
                }
            }
            if (params?.blockId) {
                let block_id = params?.blockId;
                //console.log(district_id);
                const reDataBlock = {
                    method: 'Post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'block_id': block_id })
                }
                /************chc************** */
                const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
                const getChcData = await getChc.json();
                console.log(getChcData);
                if (getChcData.Rdata.length > 0) {
                    setChcData(getChcData.Rdata);
                }
            }
        }

    }, []);


    const handleState = async (e) => {
        setState(e.target.value);
        if (e.target.value) {
            let state_id = e.target.value;
            const regDataDistrict = {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'state_id': state_id })
            }
            const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
            const getdataDistrict = await getDistrict.json();
            // console.log(getdataDistrict.Rdata);
            if (getdataDistrict.Rdata.length > 0) {
                setDistrictData(getdataDistrict.Rdata);
            }
        }
    }

    const handleDistric = async (e) => {
        setDistrict(e.target.value);
        if (e.target.value) {
            let district_id = e.target.value;
            //console.log(district_id);
            const reDataDistrict = {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'district_id': district_id })
            }
            const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
            const getBlockData = await getBlock.json();
            //console.log(getBlockData);
            if (getBlockData.Rdata.length > 0) {
                setBlockData(getBlockData.Rdata);
            }
        }
    }

    const handleBlock = async (e) => {
        setBlock(e.target.value);
        if (e.target.value) {
            let block_id = e.target.value;
            //console.log(district_id);
            const reDataBlock = {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'block_id': block_id })
            }
            /************chc************** */
            const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
            const getChcData = await getChc.json();
            console.log(getChcData);
            if (getChcData.Rdata.length > 0) {
                setChcData(getChcData.Rdata);
            }
        }
    }

    const handleCHC = (e) => {
        setChc(e.target.value);
    }

    const handleSubCenter = (e) => {
        setSubCenter(e.target.value);
    }



    /********State*********** */
    let getStateData = async () => {
        const regDataState = {
            method: 'Get',
            headers: { 'Content-Type': 'application/json' }
        }
        const getState = await fetch(API_URL + 'admin/state', regDataState);
        const getdatastate = await getState.json();
        setStateData(getdatastate);
    }

/****************************Validation*************************** */
const handleValidation = () => {
    let formIsValid = true;

    if (!state && state === "") {
        formIsValid = false;
        swal('Please Select State');
    }
    if (!district && district === "") {
        formIsValid = false;
        swal('Please Select District');
    }
    if (!block && block === "") {
        formIsValid = false;
        swal('Please Select Block');
    }
    if (!chc && chc === "") {
        formIsValid = false;
        swal('Please Select CHC');
    }
    if (!subCenter) {
        formIsValid = false;
        swal('Please enter Sub Center');
    }
    
    
    return formIsValid;
}

    const AddSubCenter = async () => {
        
            let formData = {

                "sub_center": subCenter,
                "chc_phc_id": chc
            };

            const regDatas = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            }

            const getResps = await fetch(API_URL + 'admin/add-sub-center', regDatas);
            const getDatas = await getResps.json();

            swal(getDatas.msg);
      


    }

    const UpdateSubCenter = async () => {

        let formData = {

            'sub_center': subCenter,
            'id': params?.id

        };

        const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const getResps = await fetch(API_URL + 'admin/update-sub-center', regDatas);
        const getDatas = await getResps.json();

        swal(getDatas.msg);

    }


    const handleSubmit = (events) => {
        if(handleValidation()){
            events.preventDefault();
            if (params?.id) {
                UpdateSubCenter();
            } else {
                AddSubCenter();
            }
        }
       

    }






    return (
        <div className="container-scroller">

            <Hor_header />

            <div className="container-fluid page-body-wrapper">


                <Ver_header />


                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">

                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">{(params?.id)?"Update Sub Center":"Add Sub Center"}</h4>
                                        {/* <form className="form-sample" > */}


                                       
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select State *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={state} onChange={handleState} required disabled={(params?.id) ? true : false} >
                                                            <option value="" >Select State</option>
                                                            {stateData.map(states => (
                                                                <option value={states.id}>{states.state}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select District *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={district} onChange={handleDistric} required disabled={(params?.id) ? true : false}>
                                                            <option value="">Select District</option>
                                                            {districtData.map(district => (
                                                                <option value={district.id}>{district.name} </option>

                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select Block *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={block} onChange={handleBlock} required disabled={(params?.id) ? true : false}>
                                                            <option value="">Select Block</option>
                                                            {blockData.map(blocks => (
                                                                <option value={blocks.id}>{blocks.name}</option>

                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select CHC/PHC *</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control" value={chc} onChange={handleCHC} required disabled={(params?.id) ? true : false}>
                                                            <option value="">Select CHC/PHC</option>
                                                            {chcData.map(chc => (
                                                                <option value={chc.id}>{chc.name}</option>

                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row" >
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Enter Sub Center *</label>
                                                    <div className="col-sm-12">
                                                        <input type="text" className="form-control" value={subCenter} onChange={handleSubCenter} autoComplete="off" required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <button type="submit" className="btn btn-primary mr-2" onClick={handleSubmit}>Submit</button>
                                        <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                                        {/* </form> */}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <Footer />

                </div>

            </div>


        </div>


    );
}

export default AddSubCenter;