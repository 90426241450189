import React, { useState } from "react";
import Chart from "react-apexcharts";


function LineApexes(props){
	
	const {options,series,totalTest}=props;
//	console.log(props); // 650
	
	return(
	    <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <h4 className="card-title mb-3">Total Test Performed: {totalTest} </h4>
            <Chart
              options={options}
              series={series}
              type="line"
              width="100%"
              height="400"
            />
          </div>
        </div>
      </div>
	
	);
}

export default LineApexes;