import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import swal from 'sweetalert';
import { API_URL } from '../../components/constant.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isDisabled } from '@testing-library/user-event/dist/utils';



function ChcLogin() {

  /******************************** */
  const navigat = useNavigate();
  const isLogin = reactLocalStorage.get('isLogin');
  useEffect(() => {
    if (isLogin) {
      return navigat('/dashboard')

    }
    document.title = 'Login';
  });


  /********************disable enable************************* */

  const [disDiv, setDisDiv] = useState(false);

  /*************Submit******************** */

  let doLogin = async (e) => {
    setDisDiv(true);
    e.preventDefault();
    if (validate(e)) {
      const data = new FormData(e.target);
      let formData = {
        'mobile': data.get('mobile'),
        'password': data.get('password')
      };
      //  console.log(formData); 

      const regData = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }
      const getResp = await fetch(API_URL + 'chc/login', regData);
      const getdatas = await getResp.json();

      //  console.log(getdatas);
      if (getdatas.status == '200') {
        reactLocalStorage.set('isLogin', true);
        reactLocalStorage.set('user_id', getdatas.res_data.user_id);
        reactLocalStorage.set('user_email', getdatas.res_data.mobile_no);

        if (getdatas.res_data.user_type == 'hospital') {
          reactLocalStorage.set('user_name', getdatas.res_data.chc_name);
          reactLocalStorage.set('chc_phc', getdatas.res_data.chc_phc_id);
          reactLocalStorage.set('block', getdatas.res_data.block_id);
          reactLocalStorage.set('district', getdatas.res_data.district_id);
          reactLocalStorage.set('userType', getdatas.res_data.chc_phc_id);  /// for block use block_id,for district user district_id
          reactLocalStorage.set('userLoginType', "Chc");         //Chc //Block//District

          setDisDiv(false);
          return navigat('/dashboard');

        }
        if (getdatas.res_data.user_type == 'block') {
          reactLocalStorage.set('user_name', getdatas.res_data.block_name);
          reactLocalStorage.set('chc_phc', "");
          reactLocalStorage.set('block', getdatas.res_data.block_id);
          reactLocalStorage.set('district', getdatas.res_data.district_id);
          reactLocalStorage.set('userType', getdatas.res_data.block_id);  /// for block use block_id,for district user district_id
          reactLocalStorage.set('userLoginType', "Block");         //Chc //Block//District

          setDisDiv(false);
          return navigat('/dashboard');

        }
        if (getdatas.res_data.user_type == 'district') {
          reactLocalStorage.set('user_name', getdatas.res_data.district_name);
          reactLocalStorage.set('chc_phc', "");
          reactLocalStorage.set('block', "");
          reactLocalStorage.set('district', getdatas.res_data.district_id);
          reactLocalStorage.set('userType', getdatas.res_data.district_id);  /// for block use block_id,for district user district_id
          reactLocalStorage.set('userLoginType', "District");         //Chc //Block//District

          setDisDiv(false);
          return navigat('/dashboard');

        }
        if (getdatas.res_data.user_type == 'store') {

          let formData = {
            "state_id": getdatas.res_data.state_id,
            "district_id": getdatas.res_data.district_id,
            "block_id": getdatas.res_data.block_id,
            "chc_id": getdatas.res_data.chc_phc_id,
          };
          console.log(formData);

          const regData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
          }
          const getRespss = await fetch(API_URL + 'chc/getHospitalStore', regData);
          const getdatass = await getRespss.json();
          console.log("getdatass", getdatass);

          if (getdatass.status == '200') {
            reactLocalStorage.set('user_id', getdatass.Rdata[0].user_id);
            reactLocalStorage.set('user_name', getdatass.Rdata[0].chc_name);
            reactLocalStorage.set('chc_phc', getdatass.Rdata[0].chc_phc_id);
            reactLocalStorage.set('block', getdatass.Rdata[0].block_id);
            reactLocalStorage.set('district', getdatass.Rdata[0].district_id);
            reactLocalStorage.set('userType', getdatass.Rdata[0].chc_phc_id);  /// for block use block_id,for district user district_id
            reactLocalStorage.set('userLoginType', "Store");         //Chc //Block//District
            navigat('/my_inventory_req');
            setDisDiv(false);
            //return navigat('/dashboard');

          } else {

            notify("Please Create CHC/SDH/DH");
            setDisDiv(false);

          }
        }

        if (getdatas.res_data.user_type == 'districtStore') {

          let formData = {
            "state_id": getdatas.res_data.state_id,
            "district_id": getdatas.res_data.district_id,
            "block_id": getdatas.res_data.block_id,
            "chc_id": getdatas.res_data.chc_phc_id,
          };
          console.log(formData);

          const regData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
          }
          const getRespss = await fetch(API_URL + 'chc/getDistrictStoreDatas', regData);
          const getdatass = await getRespss.json();
          console.log("getdatassdata---->", getdatass);

          if (getdatass.status == '200') {
            reactLocalStorage.set('user_id', getdatass.Rdata[0].user_id);
            reactLocalStorage.set('user_name', getdatass.Rdata[0].district_name);
            reactLocalStorage.set('chc_phc', "");
            reactLocalStorage.set('block', "");
            reactLocalStorage.set('district', getdatass.Rdata[0].district_id);
            reactLocalStorage.set('userType', getdatass.Rdata[0].district_id);  /// for block use block_id,for district user district_id
            reactLocalStorage.set('userLoginType', "DistrictStore");         //Chc //Block//District
            navigat('/my_inventory_req');
            setDisDiv(false);
            //return navigat('/dashboard');

          } else {

            notify("Please Create District User");
            setDisDiv(false);

          }
        }  

        //setDisDiv(false);
        //return navigat('/dashboard');
      } else {

        notify("Wrong Username/Password");
        setDisDiv(false);
      }


    }

  }
  /**************Validation************************* */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    // console.log(data.get);
    let validat = true;
    if (data.get('mobile') == '') {
      notify('Please enter mobile no. ');
      validat = false;
    } else {
      let mobRegExp = "^[0-9]{10}";
      //let testemail= emailRegExp.test(data.get('email'));
      let mobname = data.get('mobile').match(mobRegExp);
      if (mobname) {
        return validat = true;
      } else {
        notify('Please enter valid mobile no.');
        return validat = false;
      }
    }
  }
  /*********************Toast****************************** */
  const notify = (e) => toast(e);
  /******************************************************* */

  return (
    <>
      <ToastContainer />
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo" style={{ "textAlign": "center" }}>
                    <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="logo" style={{ "width": "65px" }} />
                  </div>
                  <h4>T4 User Login</h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6>
                  <form className="pt-3" onSubmit={doLogin}>
                    <div className="form-group">
                      <input type="tel" name="mobile" pattern='[6789][0-9]{9}' maxLength={10} className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Mobile Number" required />

                    </div>
                    <div className="form-group">
                      <input type="password" name="password" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" required />
                    </div>
                    <div className="mt-3">
                      <button type="submit" id="btn" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={disDiv}  >SIGN IN</button>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      {/* <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input"/>
                      Keep me signed in
                    </label>
                  </div> */}
                      {/* <Link to="/forgot_password" className="auth-link text-black">Forgot password?</Link> */}
                    </div>

                    {/* <div className="text-center mt-4 font-weight-light">
                  Don't have an account? <a href="register.html" className="text-primary">Create</a>
                </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </>
  );
}

export default ChcLogin;