import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate } from "react-router-dom";
//import DatePicker from "react-datepicker";
import { reactLocalStorage } from 'reactjs-localstorage';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddChcUser() {

  /********************************************* */
  //  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [chcData, setChcData] = useState([]);

  /******************pass & Cpass************************** */
  const [type, setType] = useState('all');
  const [tid, setTid] = useState('all');

  /********************medicalteam Hide Show************************* */


  const navegate = useNavigate();
  let clickCancel = () => {
    return navegate('/');
  }

  /*****************Date****************************** */
  const [startDate, setStartDate] = useState();
  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    document.title = 'Send Notification';
    // getStateData();
    getDistrictList();
  }, [1]);

  let handleUserType = (e) => {
    //getting state name   
    //getStateData();

  }

  /********State*********** */
  //   let getStateData = async() => {
  //     const regDataState = {
  //         method: 'Get',
  //         headers: { 'Content-Type': 'application/json' }
  //        }
  //        const getState = await fetch(API_URL+'admin/state',regDataState);
  //        const getdatastate = await getState.json();
  //      //  setStateData(getdatastate);
  //   }

  /********District based on state*********** */
  let getDistrictList = async (e) => {
    setDistrictData([]);
    let state_id = '1';
    const regDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    }
    const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
    const getdataDistrict = await getDistrict.json();
    // console.log(getdataDistrict.Rdata);
    if (getdataDistrict.Rdata.length > 0) {
      setDistrictData(getdataDistrict.Rdata);
    }
  }
  /********Block based on district*********** */
  let getBlock = async (e) => {
    setBlockData([]);
    let district_id = e.target.value;
    //console.log(district_id);
    const reDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'district_id': district_id })
    }
    const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
    const getBlockData = await getBlock.json();
    //console.log(getBlockData);
    if (getBlockData.Rdata.length > 0) {
      setBlockData(getBlockData.Rdata);
    }

  }



  /****************Get Chc ,school,village based on block**************************** */

  let getChc = async (e) => {
    setChcData([]);

    let block_id = e.target.value;
    //console.log(district_id);
    const reDataBlock = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'block_id': block_id })
    }
    /************chc***************/
    const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
    const getChcData = await getChc.json();
    //console.log(getBlockData);
    if (getChcData.Rdata.length > 0) {
      setChcData(getChcData.Rdata);
    }

  }


  /*****************submit******************************* */
  const submitUser = async (events) => {
    events.preventDefault();
    if (validate(events)) {
      const data = new FormData(events.target);
      let formData = {};
      if (reactLocalStorage.get("userLoginType") != 'Admin') {

        formData = {
          ...formData,

          'title': data.get('title'),
          'body': data.get('body'),
          'type': type,
          'tid': tid,
          'login_user_type': reactLocalStorage.get("userLoginType"),
          'login_user_type_id': reactLocalStorage.get("userType")
        };
      } else {
        formData = {
          ...formData,

          'title': data.get('title'),
          'body': data.get('body'),
          'type': type,
          'tid': tid,
          'login_user_type': reactLocalStorage.get("userLoginType")

        };
      }
      //'req_user_type' : "admin" // for approving user by default approved
      //console.log(formData); 

      const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      const getResps = await fetch(API_URL + 'firebase/notification', regDatas);
      const getDatas = await getResps.json();

      swal(getDatas.msg);

    }
  }


  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    // console.log(data.get);
    let validat = true;

    if (data.get('title') == '') {
      swal('Please enter title of message');
      return validat = false;
    } else {
      validat = true;
    }

    if (data.get('message') == "") {
      swal('Please enter message');
      return validat = false;
    } else {
      validat = true;
    }
    return validat;
  }


  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Send Notification</h4>
                    <form className="form-sample" onSubmit={submitUser}>


                      <div className="row">
                        {/* <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select State *</label>
                          <div className="col-sm-12">
                            <select className="form-control" name="state" onChange={getDistrictList} required>
                            <option>Select State</option>
                            {stateData.map(states => (
                                <option value={states.id}>{states.state}</option>
                            ))}
                            </select>
                          </div>
                        </div>
                      </div> */}

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select District</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="district" onChange={(e) => { getBlock(e); setType('District'); setTid(e.target.value); }} required>
                                <option value='all'>Select</option>
                                {districtData.map(district => (
                                  ((reactLocalStorage.get("userLoginType") == "Chc") || (reactLocalStorage.get("userLoginType") == "Block") || (reactLocalStorage.get("userLoginType") == "District")) ? ((reactLocalStorage.get("district") == district.id) ? <option value={district.id}  >{district.name} </option> : "") : <option value={district.id}>{district.name} </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select Block</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="block" onChange={(e) => { getChc(e); setType('Block'); setTid(e.target.value); }} required>
                                <option value='all'>Select</option>
                                {blockData.map(blocks => (
                                  ((reactLocalStorage.get("userLoginType") == 'Chc') || (reactLocalStorage.get("userLoginType") == 'Block')) ? (reactLocalStorage.get("block") == blocks.id) ? <option value={blocks.id}    >{blocks.name}</option> : "" : <option value={blocks.id}>{blocks.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select CHC/PHC</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="chc" onChange={(e) => { setType('Chc'); setTid(e.target.value); }} required>
                                <option value='all'>Select</option>
                                {chcData.map(chc => (
                                  (reactLocalStorage.get("userLoginType") == 'Chc') ? ((reactLocalStorage.get("userType") == chc.id) ? <option value={chc.id} >{chc.name}</option> : "") : <option value={chc.id}>{chc.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select ANM *</label>
                          <div className="col-sm-12">
                            <select className="form-control" name="chc" onChange={(e)=>{setType('chc');setTid(e.target.value);}} required>
                            <option>Select</option>
                            {chcData.map( chc => (
                               <option value={chc.id}>{chc.name}</option>
                             ))}
                            </select>
                          </div>
                        </div>
                           selected={(reactLocalStorage.get("chc_phc")==chc.id)?"sectected":""}
                           selected={(reactLocalStorage.get("block")==blocks.id)?"sectected":""}
                            selected={(reactLocalStorage.get("district")==district.id)?"sectected":""}  

                      </div> */}

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Message Title *</label>
                            <div className="col-sm-12">
                              <input name="title" type="text" className="form-control" autoComplete="off" required />
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Message *</label>
                            <div className="col-sm-12">

                              <textarea name="body" className="form-control" rows="4" cols="50" required >
                              </textarea>

                            </div>
                          </div>
                        </div>

                      </div>



                      <button type="submit" className="btn btn-primary mr-2">Send</button>
                      <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}

export default AddChcUser;