import React , {useEffect,useState} from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
/************get parameter from url******************* */
import { useParams,useNavigate } from 'react-router-dom';
/*******************Date Picker**************************** */
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function EditSetting(){

   /********************************************* */
      const [setting,setSetting]=useState([]);
   /********************medical team Hide Show************************* */

  
 /*****************Date****************************** */
  
 /********************back***************************************** */
      const navegate = useNavigate();
     let clickCancel =()=>{
            return navegate('/setting');
    }
   /*********************get parameter from url*********************************** */
   const params = useParams();
   /*************userd as document.ready -  useEffect****************** */
     useEffect( () => {
     document.title='Edit Setting';

     getSettingData();
    
    
  },[1]
  );

  //console.log(userFormData.gender);

   let getSettingData = async() => {
       const data = {'id':params.id} ;
       var res = await axios.post(API_URL+"admin/getSettingData",data).then( (ress) => {
           // console.warn(ress.data.Rdata[0]);
           setSetting(ress.data.Rdata[0]);
       });
 
   } 
   //console.log(userFormData.dob);

 
  
  


/*****************submit******************************* */
 const submitUser = async(events)=>{
        events.preventDefault();
     
          const data = new FormData(events.target);
     
          let formData = {
            'link':data.get('link'),
            'id':params.id
          };
       //   console.log(formData); 
           
          const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
          }
      
          const getResps = await fetch(API_URL+'admin/updateSettingData',regDatas);
          const getDatas = await getResps.json();  
            
           swal(getDatas.msg);
          
        
 }




    /******************************* */
    return ( 
        <div className="container-scroller">
   
    <Hor_header/>
   
    <div className="container-fluid page-body-wrapper">
     
      
      <Ver_header/>
      
  
      <div className="main-panel">        
        <div className="content-wrapper">
          <div className="row">
        
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Update Setting</h4>
                  <form className="form-sample" onSubmit={submitUser}>
                  <p className="card-description">
                     
                    </p>
                  <div className="row">
                      
                   
                      
                    </div>

                    
                    <div className="row">
                     
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Link</label>
                          <div className="col-sm-12">
                            <input name="link" type="text"  className="form-control" value={setting.link} onChange={e => setSetting({link:e.target.link})} required />
                          </div>
                        </div>
                      </div>
                    </div>
    
                    <button type="submit" className="btn btn-primary mr-2">Submit</button>
                    <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                  </form>
                </div>
              </div>
            </div>
            
            
          </div>
        </div>
      
          <Footer/>
            
      </div>
    
    </div>
    
    
  </div>
 
   
    );
}

export default EditSetting;