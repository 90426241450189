import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { readyException } from 'jquery';


function RefTestListOfMember() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  $('#example').DataTable().destroy();

  $(document).ready(function () {

    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
      responsive: true
    });

    var dt = $('#example').DataTable();
    dt.columns([2, 3, 16]).visible(false);
  });
  /*********************get parameter from url*********************************** */
  const params = useParams();

  /******************************************************** */

  useEffect(() => {
    document.title = "All Test data";
    getAllPatientData();
  }, [1]
  );
  /************Get all patient test list********************** */
  let getAllPatientData = async () => {
    const datas = { 'pid': params.pid };
    //  console.log(datas);
    const res = await axios.post(API_URL + 'admin/allPatientTestData', datas)
      .then(ress => {
        //    console.log(ress.data);
        setUserData(ress.data.Rdata);
        setIsLoading(false);
      });
    //  console.log(res.data);
  }

  /***************** on click of mobile no************************************* */

  let getTreatmentResult = () => {
    let pid = params.pid;
    let mob = params.mob;
    let name = params.name;
    return navigat('/ref_patient_all_treatment_list/' + pid + '/' + mob + '/' + name);
  }
  /***************** Anm details************************************ */

  const getAnmDetails = (anm_id, patient_mob) => {
    return navigat('/anm_mediacal_team_user/' + anm_id + '/' + patient_mob);
  }
  /***************** Back************************************ */

  let backto = () => {
    return navigat('/reffered_cases');
  }
  /****************************************** */

  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">


              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">All test data of patient : {params.name}  </h4><Link className="btn btn-outline-primary" to={'/reffered_cases'}>Back</Link>
                   
                      <div className="table-responsive pt-3">
                        {/* <table className="table table-bordered display responsive nowrap" id="example"> */}
                        <table className="table table-bordered display  nowrap" id="example">
                          <thead>
                            <tr>
                              {/* <th className="nosort"> S.No.</th> */}
                              <th></th>
                              <th>Treatment details</th>
                              <th> Name </th>
                              <th>Patient ID</th>
                              <th>Haemoglobin value</th>
                              <th>Haemoglobin status</th>
                              {/* <th>Mid Upper Arm Curcumference</th> */}
                              <th>Height</th>
                              <th>Weight</th>
                              <th>BMI value</th>
                              <th>BMI Status</th>
                              <th>Geo Location - Test </th>
                              <th>Associated Anm/RBSK/Nurse</th>
                              <th>Test Done By</th>
                              <th>Venue</th>
                              <th>Current Address</th>
                              <th>Referral status</th>
                              <th>Referred center id</th>
                              <th>Referred center name</th>
                              <th>Referred by hospital</th>
                              <th>Suggestion</th>
                              {/* <th>Follow up date</th> */}
                              <th>Investigated on</th>

                            </tr>
                          </thead>
                          <tbody>

                            {userData.map((udata, index) => (
                              <tr key={index}>
                                <td></td>
                                <td>
                                  <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="View" onClick={() => { getTreatmentResult() }}>
                                    <i className="typcn typcn-eye  text-primary"></i>
                                  </button>
                                </td>
                                {/* <td> { index+1 }</td> */}
                                {/* <td> <a href="#" onClick={()=>getTestResult(udata.patient_id,params.mob)}>{udata.name }</a></td> */}

                                <td> {params.name}</td>
                                <td> {udata.patient_id}</td>
                                <td> {udata.haemoglobin_value}</td>
                                <td> {udata.haemoglobin_based_status != null ? udata.haemoglobin_based_status.toUpperCase() : ""}</td>
                                {/* <td>{udata.muac_value}</td> */}
                                <td>{udata.height}</td>
                                <td>{udata.weight}</td>
                                <td>{udata.bmi_value}</td>
                                <td>{udata.bmi_category}</td>
                                <td> Latitude - {udata.ts_lat} , Longitude - {udata.ts_log}</td>

                                {/* <td>  <a href="#" onClick={()=>getAnmDetails(udata.investigation_done_by,params.mob)}>{udata.investigation_done_by}</a></td>	 */}
                                <td> <Link to={'/ref_anm_medical_team/' + udata.investigation_done_by + '/' + params.mob} >{udata.anm_name}</Link></td>
                                <td> {udata.referral_status == 'reffered' ? udata.test_hos_name : udata.anm_name}</td>
                                <td> {udata.venue}</td>
                                <td> {udata.curr_address}</td>
                                <td> {udata.referral_status == 'reffered' ? "Referred" : "Not Referred"}</td>
                                <td> {udata.referred_center_id}</td>
                                <td> {udata.referred_center_name}</td>
                                <td> {udata.referred_by_hos_name}</td>
                                <td> {udata.suggestion}</td>
                                {/* <td> {udata.follow_up_date }</td> */}

                                <td><Moment format="DD-MM-YYYY" >{udata.investigated_on}</Moment></td>

                              </tr>
                            )
                            )}

                          </tbody>
                        </table>
                      </div>
                  
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>

    </div>);
}

export default RefTestListOfMember;