import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Button, Modal } from "react-bootstrap";
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "../front/loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { param, readyException } from 'jquery';


function MyInventoryReqList() {
  const navigat = useNavigate();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [listData, setListData] = useState([]);
  $('#example').DataTable().destroy();
  $(document).ready(function () {
    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort'],
        "order": [[2, "desc"]]
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print']
    });
    var dt = $('#example').DataTable();

    $('#example1').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort'],
        "order": [[2, "desc"]]
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print']
    });
    var dt1 = $('#example1').DataTable();
    dt1.columns([0, 1]).visible(false);

  });
  useEffect(() => {
    document.title = "My Inventory Request";
    getItemData();
  }, [1]
  );
  let getItemData = async () => {
    let user_type_id = '';
    if (reactLocalStorage.get("userLoginType") == "Admin") {
      user_type_id = 'admin';
    }
    if (reactLocalStorage.get("userLoginType") == "Chc" || reactLocalStorage.get("userLoginType") == "Store") {
      user_type_id = reactLocalStorage.get("user_id");
    }
    if (reactLocalStorage.get("userLoginType") == "District"  || reactLocalStorage.get("userLoginType") == "DistrictStore" ) {
      user_type_id = reactLocalStorage.get("user_id");
    }
    let formData = {
      'req_by': user_type_id
    };
    const res = await axios.post(API_URL + 'admin/get-all-request-list', formData).then(ress => {
      setUserData(ress.data.Rdata);
      setIsLoading(false);
    });
  }

  /******************accept item****************************** */


  let updateStatus = async (trans_id) => {
    const dataStatusUpdate = { "trans_id": trans_id }
    const res = await axios.post(API_URL + 'admin/req_item_received', dataStatusUpdate)
      .then(ress => {
        if (ress.status == '200') {
          // swal("Status Changed.");
          swal("Request accepted successfully.");
          getItemData();

        }

      });

  }

  /******************Redirecting to edit page***************************** */
  let editUser = (id) => {
    //console.warn("id="+data);
    return navigat('/edit_inv_item/' + id);
  }
  const viewList = async (trans_id) => {
    const data = { 'trans_id': trans_id }
    const res = await axios.post(API_URL + 'admin/get-requested-list', data).then(ress => {
      if (ress.status == '200') {
        setModal(true);
        setListData(ress.data.Rdata)
        // console.log(ress.data.Rdata);
      }
    });
  }
  const close = () => {
    setModal(false)
  }

  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">

              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">My Request </h4>

                  
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered display  nowrap" id="example">
                          <thead>
                            <tr>
                              <th>List</th>
                              <th>Requested To</th>
                              <th>Request Type</th>
                              <th>Request Date</th>
                              <th>Issued Date</th>
                              <th>Received Date</th>

                            </tr>
                          </thead>
                          <tbody>

                            {userData.map((udata, index) => (
                              <tr key={index} className={(udata.status == 0) ? 'red' : (udata.status == 1) ? 'green' : (udata.status == 2) ? 'orange' : ''}>
                                <td>
                                  <button className="btn btn-success btn-sm cust_btn" onClick={() => viewList(udata.trans_id)}>View List</button>
                                  <button className={(udata.status == 0) ? 'btn btn-danger btn-sm cust_btn' : (udata.status == 1) ? 'btn btn-primary btn-sm cust_btn' : (udata.status == 2) ? 'btn btn-warning btn-sm cust_btn' : ''} onClick={() => { if (udata.status == 1) { if (window.confirm(udata.status == 1 ? 'Are you sure to Accept Request?' : '')) { updateStatus(udata.trans_id) } } }}  >{(udata.status == 0) ? 'Pending' : (udata.status == 1) ? 'Issued' : (udata.status == 2) ? 'Received' : ''}</button>
                                </td>
                                <td> {udata.district}</td>
                                <td> {udata.req_type}</td>
                                <td> {udata.date_of_req}</td>
                                <td> {udata.issued_date}</td>
                                <td> {((udata.date_of_receive == null) ? "" : udata.date_of_receive)}</td>
                              </tr>
                            )
                            )}

                          </tbody>
                        </table>
                      </div>
                 
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>
      <Modal size="lg" show={modal}>
        <Modal.Header closeButton onClick={close}>
          <Modal.Title id="contained-modal-title-vcenter" className="heading-request">Requested List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <table className="table table-bordered padding-tr"> */}
          <table className="table table-bordered display  nowrap" id="example1">
            <thead>
              <tr>
                <th>Req By</th>
                <th>Req Date</th>
                <th>Type</th>
                <th>Item Name</th>
                <th>Requested Qty</th>
                <th>Issued Qty</th>
              </tr>
            </thead>
            <tbody>
              {listData.map((val, key) => (
                <tr>
                  <td>{val.req_by_name}</td>
                  <td><Moment format="DD-MM-YYYY" >{val.date_of_req}</Moment></td>
                  <td>{val.type}</td>
                  <td>{val.item_name}</td>
                  <td>{val.req_qty}</td>
                  <td>{val.giv_qty}</td>
                </tr>
              )
              )}
            </tbody>
          </table>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </div>);
}

export default MyInventoryReqList;