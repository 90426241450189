import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from 'reactjs-localstorage';

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";
import ResetModal from "./InventoryItemAssignModel";
import ViewInv from "./inventory_view_model";

import $, { param, readyException } from 'jquery';


function InventoryReqList() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  const [showLogin, setShowLogin] = useState(false);
  const [showInv, setShowInv] = useState(false);
  const [rcid, setRcid] = useState();
  const [avqt, setAvqt] = useState();
  const [reqItem, setReqItem] = useState();

  const [invUsrId, setInvUsrId] = useState();
  const [itemId, setItemId] = useState();

  const [userID, setUserID] = useState([]);
  const [invUser, setInvUser] = useState("");

  //  console.log(userID);
  $('#example').DataTable().destroy();

  $(document).ready(function () {

    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort'],
        "order": [[2, "desc"]]
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
      responsive: true
    });
    var dt = $('#example').DataTable();
    dt.columns([2, 8]).visible(false);
    // dt.DefaultView.Sort = "Request Status desc";
  });
  /*********************get parameter from url*********************************** */
  const params = useParams();

  /******************************************************** */
  //debugger;
  useEffect(() => {
    document.title = "Inventory Item Request list";
    getItemData();
  }, [1]
  );
  /************Get all state data********************** */
  let getItemData = async () => {

    let user_type_id = '';
    if (reactLocalStorage.get("userLoginType") == "Admin") {
      user_type_id = 'admin';
    }
    if (reactLocalStorage.get("userLoginType") == "Chc") {
      user_type_id = reactLocalStorage.get("user_id");
    }

    let formData = {
      'userId': user_type_id
    };

    const res = await axios.post(API_URL + 'admin/getInvenoryItemReqListWithMe', formData)
      .then(ress => {
        //  console.log(ress.data);
        setUserData(ress.data.Rdata);
        setIsLoading(false);
      });
    //  console.log(res.data);
  }


  let getDataInv = async (edata, req_by_name) => {
    let formData = {
      'user_id': edata

    };

    const res = await axios.post(API_URL + 'admin/view_inventory', formData)
      .then(ress => {
        // console.log(ress.data);
        setUserID(ress.data.Rdata);
        setInvUser(req_by_name);
        setShowInv(true);
      });
  }

  /******************update****************************** */
  //   let updateStatus = async (id,status,req_by,req_to,giv_qty,item_id) =>{
  //     const dataStatusUpdate={'record_id':id,'status':status,"receiver":req_by,"sender":req_to,"qty":giv_qty,"item_id":item_id}
  //    const res = await axios.post(API_URL+'admin/itemReceived',dataStatusUpdate)
  //    .then(ress => { if(ress.status=='200'){
  //     // swal("Status Changed.");
  //      swal("Request accepted successfully.");
  //      getItemData();

  //      }

  //     });

  //   } 
  /******************Redirecting to edit page***************************** */
  let editUser = (id) => {
    //console.warn("id="+data);
    return navigat('/edit_inv_item/' + id);
  }


  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
          {isLoading ? (<Loader />) : (
            <>
              <div className="content-wrapper">
                <div className="row">

                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title"> Item Requests</h4>


                        <div className="table-responsive pt-3">
                          {/* <table className="table table-bordered display responsive nowrap" id="example"> */}
                          <table className="table table-bordered display  nowrap" id="example">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Request Status</th>
                                <th>Requested Id</th>
                                <th>Requested By</th>
                                <th>Item</th>
                                <th>Requested Quantity</th>
                                <th>Issued Quantity </th>
                                <th>Requested To</th>
                                <th>Requested Id</th>
                                <th>Available Quantity</th>
                                <th>Request Type</th>
                                <th>Request Date</th>
                                <th>Issued Date</th>
                                <th>Received Date</th>

                              </tr>
                            </thead>
                            <tbody>

                              {userData.map((udata, index) => (
                                <tr key={index}>
                                  <td></td>
                                  <td> <button className={(udata.status == 0) ? 'btn btn-danger btn-sm cust_btn' : (udata.status == 1) ? 'btn btn-success btn-sm cust_btn' : (udata.status == 2) ? 'btn btn-warning btn-sm cust_btn' : ''} onClick={() => { if (udata.status == 0) { if (window.confirm(udata.status == 0 ? 'Are you sure to Accept Request?' : '')) { setShowLogin(true); setRcid(udata.id); setAvqt(udata.avail_qty); setReqItem(udata.req_qty); setInvUsrId(udata.req_to); setItemId(udata.item_id) } } }}  >{(udata.status == 0) ? 'Pending' : (udata.status == 1) ? 'Accepted' : (udata.status == 2) ? 'Completed' : ''}</button></td>
                                  <td> {udata.req_by}</td>
                                  <td> <a href="#" onClick={() => { getDataInv(udata.req_by, udata.req_by_name); }}>{udata.req_by_name}</a></td>
                                  <td> {udata.item_name}</td>
                                  <td> {udata.req_qty}</td>
                                  <td> {udata.giv_qty}</td>
                                  <td> {udata.req_to_name}</td>
                                  <td> {udata.req_to}</td>
                                  <td> {udata.avail_qty}</td>
                                  <td> {udata.req_type}</td>
                                  {/* <td> <Moment format="DD-MM-YYYY">{udata.date_of_req}</Moment></td>	
                                 <td> {((udata.issued_date==null)?"":<Moment format="DD-MM-YYYY">{udata.issued_date}</Moment>)}</td>	
                                 <td> {((udata.date_of_receive==null)?"":<Moment format="DD-MM-YYYY">{udata.date_of_receive}</Moment>)}</td>		 */}

                                  <td> {udata.date_of_req}</td>
                                  <td> {((udata.issued_date == null) ? "" : udata.issued_date)}</td>
                                  <td> {((udata.date_of_receive == null) ? "" : udata.date_of_receive)}</td>

                                  {/* <td> <Moment format="DD-MM-YYYY">{udata.last_updated_on}</Moment> </td>  */}


                                </tr>
                              )
                              )}

                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>

                  <ResetModal show={showLogin} data={rcid} avdata={avqt} reqData={reqItem} itmId={itemId} usrId={invUsrId} close={() => setShowLogin(false)} />

                  <ViewInv show={showInv} data={userID} invUser={invUser} close={() => setShowInv(false)} />

                </div>
              </div>

              <Footer />
            </>
          )}
        </div>

      </div>

    </div>);
}

export default InventoryReqList;