import React , {useEffect,useState} from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddDistrictStore() {

   /********************************************* */
   const [stateData, setStateData] = useState([]);
   const [districtData, setDistrictData] =useState([]);
  // const [blockData,setBlockData] =useState([]);
  // const [chcData,setChcData] =useState([]);
   
   /******************pass & Cpass************************** */
   const [passData,setPassData] = useState();
   const [cpassData,setCpassData] = useState();
  
   /********************medicalteam Hide Show************************* */

   const [hideDiv, setHideDiv] = useState(false);

   const navegate = useNavigate();
   let clickCancel =()=>{
    return navegate('/');
}

   /*****************Date****************************** */
   const [startDate, setStartDate] = useState();
   /*************userd as document.ready -  useEffect****************** */
   useEffect( () => {
     document.title='Add District User';
       getStateData();
  },[1]);
 
  let handleUserType = (e) =>{
    //getting state name   
    //getStateData();
  
  }

   /********State*********** */
  let getStateData = async() => {
    const regDataState = {
        method: 'Get',
        headers: { 'Content-Type': 'application/json' }
       }
       const getState = await fetch(API_URL+'admin/state',regDataState);
       const getdatastate = await getState.json();
       setStateData(getdatastate);
  }

/********District based on state*********** */
  let getDistrictList = async(e) =>{
    setDistrictData([]);
      let state_id = e.target.value;
      const regDataDistrict = {
          method: 'Post',
          headers: { 'Content-Type': 'application/json' },
          body : JSON.stringify({'state_id':state_id})
     }
     const getDistrict = await fetch(API_URL+'admin/district',regDataDistrict);
     const getdataDistrict = await getDistrict.json();
     // console.log(getdataDistrict.Rdata);
      if(getdataDistrict.Rdata.length > 0){
          setDistrictData(getdataDistrict.Rdata);
      }
  }
 /********Block based on district*********** */
//  let getBlock = async(e)=>{
//   setBlockData([]);
//    let district_id = e.target.value;
//      //console.log(district_id);
//    const reDataDistrict = {
//      method: 'Post',
//      headers: {'Content-Type' : 'application/json'},
//      body: JSON.stringify({'district_id':district_id})
//    }
//    const getBlock = await fetch(API_URL+"admin/block",reDataDistrict);
//    const getBlockData = await getBlock.json();
//    //console.log(getBlockData);
//    if(getBlockData.Rdata.length > 0){
//       setBlockData(getBlockData.Rdata);
//    }
   
//  }



/****************Get Chc ,school,village based on block**************************** */
/*
let getChc = async(e)=>{
    setChcData([]);
   
  let block_id = e.target.value;
    //console.log(district_id);
   const reDataBlock = {
    method: 'Post',
    headers: {'Content-Type' : 'application/json'},
    body: JSON.stringify({'block_id':block_id})
  }
     
      const getChc = await fetch(API_URL+"admin/getChc",reDataBlock);
      const getChcData = await getChc.json();
      //console.log(getBlockData);
      if(getChcData.Rdata.length > 0){
      setChcData(getChcData.Rdata);
      }
     
}  */
/****************set pass and cpass*********************/
let getPassword =(e)=>{  
  setPassData(e.target.value) ;
 
 }
 let getConfirm =(e) => {
  setCpassData(e.target.value);
 
  // if(passData!=cpassData){
  //   swal("Password  and Confirm Pasword not matched");

  //  }
 }

/*****************submit******************************* */
 const submitUser = async(events)=>{
        events.preventDefault();
        if(validate(events)){
          const data = new FormData(events.target);
     
          let formData = {
            
            'mobile_no':data.get('mobile_no'),
            'state_id':data.get('state'),
            'district_id':data.get('district'),
          //  'block_id':data.get('block'),
          //  'chc_phc_id':data.get('chc'),
        //  'auto_analyser':data.get('auto_analyser'),
           'auto_analyser':'no',
            'password':data.get('password'),
            'req_user_type' : "admin"
           
          };
          //'req_user_type' : "admin" // for approving user by default approved
          console.log(formData); 
           
          const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
          }
      
          const getResps = await fetch(API_URL+'chc/registerDistrictStore',regDatas);
          const getDatas = await getResps.json();  
            
           swal(getDatas.msg);
          
        }
 }


 /***************validation**************************** */
 let validate = (ev) => {
  const data = new FormData(ev.target);
 // console.log(data.get);
  let validat = true;
  

   if(data.get('mobile_no') == ''){
    swal('Please enter mobile');
    return validat = false;
  }else{
   let mobRegExp="^[0-9]{10}";
    //let testemail= emailRegExp.test(data.get('email'));
    let mobname= data.get('mobile_no').match(mobRegExp);
    if(mobname){
      validat = true;
    }else{ swal('Invalid mobile');
    return validat = false;
    }
  }

  if(data.get('password') == ''){
    swal('Please enter password');
    return validat = false;
  }else{
        validat = true;
  }
  if(data.get('cpassword') == ''){
    swal('Please enter confirm password');
    return validat = false;
  }else{
        validat = true;
  }

  if(passData!=cpassData){
    swal("Password  and confirm pasword not matched");
    return validat = false;
   }else{
    validat = true;
   }
   if(data.get('state')=="Select"){
    swal("Please select state");
    return validat = false;
   }else{
    validat = true;
   }
   if(data.get('district')=="Select"){
    swal("Please select district");
    return validat = false;
   }else{
    validat = true;
   }
//    if(data.get('block')=="Select"){
//     swal("Please select block");
//     return validat = false;
//    }else{
//     validat = true;
//    }
   if(data.get('user_type')=="Select"){
    swal("Please select user type");
    return validat = false;
   }else{
    validat = true;
   }
//    if(data.get('chc')=="Select"){
//     swal('Please select Chc');
//     return validat = false;
//   }else{
//     validat = true;
//   }
   
 

  return validat;
}


    /******************************* */
    return (
        <div className="container-scroller">
   
    <Hor_header/>
   
    <div className="container-fluid page-body-wrapper">
     
      
      <Ver_header/>
      
  
      <div className="main-panel">        
        <div className="content-wrapper">
          <div className="row">
        
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Add District Store User</h4>
                  <form className="form-sample" onSubmit={submitUser}>
                  <p className="card-description">
                      User Info
                    </p>
                  <div className="row">
                      {/* <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12"> User Type *</label>
                          <div className="col-sm-12">
                            <select className="form-control" onChange={handleUserType} name="user_type">
                            <option value="">Select</option>
                              <option value="District Hospital">District Hospital</option>
                              <option value="Sub District Hospital">Sub District Hospital</option>
                              <option value="Health Center">Health Center</option>
                            </select>
                          </div>
                        </div>
                      </div> */}

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Mobile Number *</label>
                          <div className="col-sm-12">
                            <input name="mobile_no" type="tel" pattern="[6789][0-9]{9}" maxLength={10} className="form-control" autoComplete="off" required />
                          </div>
                        </div>
                      </div> 
                      
                    </div>

                     
                    <p className="card-description">
                     Geographical Data
                    </p>
                  <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select State *</label>
                          <div className="col-sm-12">
                            <select className="form-control" name="state" onChange={getDistrictList} required>
                            <option>Select State</option>
                            {stateData.map(states => (
                                <option value={states.id}>{states.state}</option>
                            ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select District *</label>
                          <div className="col-sm-12">
                            <select className="form-control" name="district"  required>
                            <option>Select</option>
                             {districtData.map(district => (
                               <option value={district.id}>{district.name}  { district.code } </option>
                             ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div className="row">
                      {/* <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select Block *</label>
                          <div className="col-sm-12">
                            <select className="form-control" name="block"  required>
                            <option>Select</option>
                             {blockData.map( blocks => (
                               <option value={blocks.id}>{blocks.name}</option>
                             ))}
                            </select>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select CHC/PHC *</label>
                          <div className="col-sm-12">
                            <select className="form-control" name="chc" required>
                            <option>Select</option>
                            {chcData.map( chc => (
                               <option value={chc.id}>{chc.name}</option>
                             ))}
                            </select>
                          </div>
                        </div>
                      </div> */}
                      
                    </div>
                    {/* <div className="row" >
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Do you have Auto Analyser*</label>
                          <div className="col-sm-12">
                         
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input"  name="auto_analyser" id="membershipRadios5" value="yes" />
                                Yes
                              <i className="input-helper"></i></label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="auto_analyser" id="membershipRadios6" value="no" defaultChecked  />
                                No
                              <i className="input-helper"></i></label>
                            </div>
                         
                          </div>
                        </div>
                      </div>
                      
                    </div>   */}
                    <p className="card-description">
                      
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Password *</label>
                          <div className="col-sm-12">
                          <input type="password" className="form-control" id="exampleInputPassword1" onChange={getPassword} minlength="4" maxlength="15" name="password" placeholder="Password" autoComplete="off" required/>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Re-enter Password *</label>
                          <div className="col-sm-12">
                          <input type="password" className="form-control" id="exampleInputConfirmPassword1" onChange={getConfirm} minlength="4" maxlength="15" name="cpassword"  placeholder="Re-enter Password" autoComplete="off" required/>
                          </div>
                        </div>
                      </div>
                    </div>
                   
               
                    
                    <button type="submit" className="btn btn-primary mr-2">Submit</button>
                    <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                  </form>
                </div>
              </div>
            </div>
            
            
          </div>
        </div>
      
          <Footer/>
            
      </div>
    
    </div>
    
    
  </div>
 
   
    );
}


export default AddDistrictStore;