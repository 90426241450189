import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
/************get parameter from url******************* */
import { useParams, useNavigate } from 'react-router-dom';
/*******************Date Picker**************************** */
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from "moment";
import Moment from 'react-moment';

function EditPatient() {


  /********************************************* */


  const [villageData, setVillageData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);

 
  const [beneficiaryType, setBeneficiaryType] = useState("");
  const [name, setName] = useState("");
  const [guardianName, setGuardianName] = useState("");
  const [addharNumber, setAddharNumber] = useState("");
  const [pregDuration, setPregDuration] = useState("");
  const [gender, setGender] = useState("");
  const [cast, setCast] = useState("");
  const [address, setAddress] = useState("");
  const [village, setVillage] = useState("");
  const [school, setSchool] = useState("");
  const [showPreg, setShowPreg] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showSchool, setShowSchool] = useState(false);


  const [startDate, setStartDate] = useState(null);

  const [mob, setMob] = useState(0);
  const [age, setAge] = useState("");


  /********************back***************************************** */
  const navegate = useNavigate();
  let clickCancel = () => {
    return navegate('/patient_all_member_list/vb/' + mob);
  }
  /*********************get parameter from url*********************************** */
  const params = useParams();
  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    document.title = 'Edit Patient';

    getDataOfUser();


  }, [1]
  );

  useEffect(() => {
    let newGender = "";
    if (beneficiaryType === 'PREGNANT WOMEN' ||
        beneficiaryType === 'LACTATING WOMEN' ||
        beneficiaryType === 'WOMEN OF REPRODUCTIVEAGE') {
        newGender = "female";
        setDisable(true);
    } else {
        setDisable(false);
    }

    setGender(newGender);

}, [beneficiaryType]);

  const handleBeneficiaryType = (e) => {
    setBeneficiaryType(e.target.value);
  }


  const handleName = (e) => {
    setName(e.target.value);
  }

  const handleMobile = (e) => {
    setMob(e.target.value)
  }


  const hanleGuardianName = (e) => {
    setGuardianName(e.target.value);
  }

  const handleAadharNumber = (e) => {
    setAddharNumber(e.target.value);
  }

  const handlePregduration = (e) => {
    setPregDuration(e.target.value);
  }

  const handleCast = (e) => {
    setCast(e.target.value);
  }

  const handleGender = (e) => {
    setGender(e.target.value)

  }

  const handleAddress = (e) => {
    setAddress(e.target.value);
  }

  const handleVillage = (e) => {
    setVillage(e.target.value);
  }

  const handleSchool = (e) => {
    setSchool(e.target.value);
  }

  const handleDateChange = (date) => {
    setStartDate(date);
    getAge(date);
  }

  const handleAge = (e) => {
    setAge(e.target.value);
  }

  let getDataOfUser = async () => {
    const data = { 'patient_id': params.id };
    var res = await axios.post(API_URL + "admin/getPatientData", data).then(async (ress) => {
    
     setBeneficiaryType(ress.data.Rdata[0]?.beneficiary_type);
      setName(ress.data.Rdata[0]?.name);
      setGender(ress.data.Rdata[0]?.gender);
      setGuardianName(ress.data.Rdata[0]?.guardian_name);
      setAddharNumber(ress.data.Rdata[0]?.aadhar_no);
      setPregDuration(ress.data.Rdata[0]?.preg_duration ? ress.data.Rdata[0]?.preg_duration : "");
      setAddress(ress.data.Rdata[0]?.address);
      setCast(ress.data.Rdata[0]?.caste);
      setAge(ress.data.Rdata[0]?.age);
      setMob(ress.data.Rdata[0].mobile_no);
      setVillage(ress.data.Rdata[0]?.village_id);
      setSchool(ress.data.Rdata[0]?.school_id)
      if (ress.data.Rdata[0].block_id) {
        let block_id = ress.data.Rdata[0].block_id
        const reDataBlock = {
          method: 'Post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 'block_id': block_id })
        }

        const getVillage = await fetch(API_URL + "admin/getVillage", reDataBlock);
        const getVillageData = await getVillage.json();
        const getSchool = await fetch(API_URL + "admin/getSchool", reDataBlock);
        const getSchoolData = await getSchool.json();


        if (getVillageData.Rdata.length > 0) {
          setVillageData(getVillageData.Rdata);

        }
        if (getSchoolData.Rdata.length > 0) {
          setSchoolData(getSchoolData.Rdata);
        }
      }
      if (ress.data.Rdata[0]?.beneficiary_type === 'PREGNANT WOMEN') {
        setShowPreg(true);
      } else {
        setShowPreg(false);
      }

      if (ress.data.Rdata[0]?.beneficiary_type === 'ADOLESCENT 10-19 YEARS') {
        setShowSchool(true);
      } else {
        setShowSchool(false);
      }

    });

  }












  /*****************submit******************************* */
  const submitUser = async () => {

    if (handleValidation()) {


      let formData = {

        'name': name,
        'gender': gender,
        'age': age,
        'mobile_no': mob,
        'caste': cast,
        'address': address,
        'village_id': village,
        'preg_duration': pregDuration ? pregDuration : "",
        'aadhar_no': addharNumber,
        'guardian_name': guardianName,
        'patient_id': params.id,
        'school_id': (beneficiaryType === 'ADOLESCENT 10-19 YEARS') ? school : ""
      };
      //   console.log(formData); 

      const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      const getResps = await fetch(API_URL + 'admin/editPatientData', regDatas); //editPatientData
      const getDatas = await getResps.json();

      swal(getDatas.msg);

    }
  }





  const handleValidation = () => {
    let formIsValid = true;


    if (!name) {
     
      formIsValid = false;
      swal('Please Enter Name.');
    }

    if (!mob) {
      formIsValid = false;
      swal('Plaese Enter Mobile Number.');
    }

    if (!gender && gender === "") {
      formIsValid = false;
      swal('Please Select Gender');
    }

    if (!cast && cast === "") {
      formIsValid = false;
      swal('Please Select Cast')
    }

    if (!village && village === "") {
      formIsValid = false;
      swal('Please Select Village');
    }
    if (beneficiaryType === 'ADOLESCENT 10-19 YEARS') {
      if (!school) {
        formIsValid = false;
        swal('Please Select School');
      }

    }

    return formIsValid;
  }


  const getAge = (dateStrings) => {
    // var dateString = dateStrings.toLocaleDateString("en-US")
    //console.log("dateString",dateStrings.toLocaleDateString("en-US"));


    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dateStrings);
    let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(dateStrings);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(dateStrings);
    //console.log(`${day}-${month}-${year}`);
    var dateString = `${month}/${day}/${year}`;

    var now = new Date();
    var today = new Date(now.getYear(), now.getMonth(), now.getDate());

    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var dob = new Date(dateString.substring(6, 10),
      dateString.substring(0, 2) - 1,
      dateString.substring(3, 5)
    );
    // console.log("dateString",dateString.substring(6,10),dateString.substring(0,2)-1,dateString.substring(3,5));
    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearString = "";
    var monthString = "";
    var dayString = "";


    var yearAge = yearNow - yearDob;

    if (monthNow >= monthDob)
      var monthAge = monthNow - monthDob;
    else {
      yearAge--;
      var monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob)
      var dateAge = dateNow - dateDob;
    else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge
    };

    if (age.years > 1) yearString = " years ";
    else yearString = " year ";
    if (age.months > 1) monthString = " months ";
    else monthString = " month ";
    if (age.days > 1) dayString = " days ";
    else dayString = " day ";


    if ((age.years > 0) && (age.months > 0) && (age.days > 0))
      ageString = age.years + yearString + age.months + monthString + age.days + dayString;
    else if ((age.years == 0) && (age.months == 0) && (age.days > 0))
      ageString = age.days + dayString;
    else if ((age.years > 0) && (age.months == 0) && (age.days == 0))
      ageString = age.years + yearString;
    else if ((age.years > 0) && (age.months > 0) && (age.days == 0))
      ageString = age.years + yearString + age.months + monthString;
    else if ((age.years == 0) && (age.months > 0) && (age.days > 0))
      ageString = age.months + monthString + age.days + dayString;
    else if ((age.years > 0) && (age.months == 0) && (age.days > 0))
      ageString = age.years + yearString + age.days + dayString;
    else if ((age.years == 0) && (age.months > 0) && (age.days == 0))
      ageString = age.months + monthString;
    else ageString = "Oops! Could not calculate age!";
    //console.log("ageString",ageString);
    // return ageString;
   // setUserFormData({ age: ageString })
  }










  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Update Patient Registration</h4>
                    {/* <form className="form-sample" > */}
                    <p className="card-description">

                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12"> Beneficiary type </label>
                          <div className="col-sm-12">
                            <input className="form-control" value={beneficiaryType} onChange={handleBeneficiaryType} readOnly />

                          </div>
                        </div>
                      </div>

                    </div>

                    <p className="card-description">
                      Personal info
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Name *</label>
                          <div className="col-sm-12">
                            <input type="text" className="form-control" value={name} onChange={handleName} required />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Mobile Number *</label>
                          <div className="col-sm-12">
                            <input type="tel" pattern="[0-9]{10}" className="form-control" value={mob} onChange={handleMobile} required />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12"> Gender *</label>
                          <div className="col-sm-12">
                            {/* <select className="form-control" name="gender" onChange={(e) => { setGender(e.target.value); hideShow(e.target.value) }}> */}
                            <select className="form-control" value={gender} onChange={handleGender} disabled={disable}>
                              <option value="" >Select</option>
                              <option value="male" >Male</option>
                              <option value="female">Female</option>
                              <option value="other" >Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Date of Birth </label>
                          <div className="col-sm-12">
                            {/* <input name="dob" type="date"className="form-control" placeholder="dd/mm/yyyy" required/> */}
                            <DatePicker
                              selected={startDate}
                              onChange={handleDateChange}
                              name="dob"
                              dateFormat="dd-MM-yyyy"
                              dropdownMode="select"
                              showMonthDropdown
                              showYearDropdown
                              adjustDateOnChange
                              minDate={new Date('01-01-1950')}
                              maxDate={new Date()}
                              placeholderText="select the date to change the age"
                              className="form-control"
                              id="dobpicker"
                              tabIndex="5"
                              onKeyDown={e => e.preventDefault()}
                            // value={userFormData.dob}

                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Guardian Name *</label>
                          <div className="col-sm-12">
                            <input type="text" className="form-control" value={guardianName} onChange={hanleGuardianName} required />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Age </label>
                          <div className="col-sm-12">
                            <input type="text" className="form-control" value={age} onChange={handleAge} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Aadhar Number </label>
                          <div className="col-sm-12">
                            <input type="tel" pattern="[0-9]{12}" max={12} value={addharNumber} onChange={handleAadharNumber} className="form-control" />
                          </div>
                        </div>
                      </div>

                      {(showPreg) ? <div className="col-md-6" >
                        <div className="form-group row">
                          <label className="col-sm-12">Pregnancy Duration</label>
                          <div className="col-sm-12">
                            {/* <input name='preg_duration' type="text" className="form-control" value={userFormData.preg_duration} onChange={e => setUserFormData({preg_duration:e.target.value})} required /> */}
                            <select className="form-control" value={pregDuration} onChange={handlePregduration} >
                              <option value="">Select</option>
                              <option value="1st trimester (1-3 months)">1st trimester (1-3 months)</option>
                              <option value="2nd trimester (4-6 months)">2nd trimester (4-6 months)</option>
                              <option value="3rd trimester (6-9 months)">3rd trimester (6-9 months)</option>
                            </select>
                          </div>
                        </div>
                      </div> : ""}
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Caste* </label>
                          <div className="col-sm-12">
                            {/* <input name='caste' type="text" className="form-control" value={userFormData.caste} onChange={e => setUserFormData({caste:e.target.value})} required /> */}
                            {/* <select className="form-control" name="caste" onChange={(e) => { setCast(e.target.value) }} required> */}
                            <select className="form-control" value={cast} onChange={handleCast} required>
                              <option value="">Select</option>
                              <option value="general" >General</option>
                              <option value="sc" >SC</option>
                              <option value="st" >ST</option>
                              <option value="other" >Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Address </label>
                          <div className="col-sm-12">
                            <input type="textarea" className="form-control" value={address} onChange={handleAddress} />
                          </div>
                        </div>
                      </div>
                    </div>






                    <p className="card-description">
                      Geographical Data
                    </p>

                    <div className="row" >


                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select Village *</label>
                          <div className="col-sm-12">
                            <select className="form-control" value={village} onChange={handleVillage} required>
                              <option value="">Select</option>
                              {villageData.map(vil => (
                                <option value={vil.id}>{vil.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      {(showSchool) ? <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select School *</label>
                          <div className="col-sm-12">
                            <select className="form-control" value={school} onChange={handleSchool} required>
                              <option value="">Select</option>
                              {schoolData.map(sch => (
                                <option value={sch.id}>{sch.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div> : ""}

                    </div>


                    <button type="submit" className="btn btn-primary mr-2" onClick={submitUser}>Submit</button>
                    <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                    {/* </form> */}
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );

}
export default EditPatient;