import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import { reactLocalStorage } from 'reactjs-localstorage';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function UpdateInvWasteItem() {


  /*********************get parameter from url*********************************** */
  const params = useParams();

  /********************medicalteam Hide Show************************* */

  const [hideDiv, setHideDiv] = useState(false);
  const [itemAV, setItemAV] = useState([]);
  const [myitem, setMyitem] = useState([]);

  const navegate = useNavigate();
  let clickCancel = () => {
    return navegate('/view_inventory');
  }

  /*****************Date****************************** */
  const [startDate, setStartDate] = useState();
  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    document.title = 'Update Inventory Item';
    getSelectedInvItem();
    //getMyInvItem();
  }, [1]);


  /******************get selected inventory items ******************** */
  let getSelectedInvItem = async () => {

    let user_type_id = '';
    if (reactLocalStorage.get("userLoginType") == "Admin") {
      user_type_id = 'admin';
    }
    else {
      user_type_id = reactLocalStorage.get("user_id");
    }

    let datas = { "id": params.id, "user_id": user_type_id }
    const res = await axios.post(API_URL + 'admin/getSelectedInvItem', datas)
      .then(ress => {
        console.log(ress.data.Rdata);
        setItemAV(ress.data.Rdata[0]);
        // setIsLoading(false);
      });
    //  console.log(res.data);
  }

  /******************get all inventory items ******************** */

  //console.log(params.id);
  /*****************submit******************************* */
  const submitUser = async (events) => {
    events.preventDefault();
    if (validate(events)) {
      const data = new FormData(events.target);
      let user_type_id = '';
      if (reactLocalStorage.get("userLoginType") == "Admin") {
        user_type_id = 'admin';
      }
      else {
        user_type_id = reactLocalStorage.get("user_id");
      }

      let formData = {
        // 'rc_id':params.id,
        'user_id': user_type_id,
        'qty': data.get('qty'),
        'waste': data.get('waste'),
        'item_id': data.get('item_id'),

      };
      //   console.log(formData);

      const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      const getResps = await fetch(API_URL + 'admin/itemUsed', regDatas);
      const getDatas = await getResps.json();

      swal(getDatas.msg);

    }
  }


  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    // console.log(data.get);
    let validat = true;



    if (data.get('qty') == "") {
      swal("Please enter used quantity");
      return validat = false;
    } else {
      if (parseInt(data.get('qty')) <= parseInt(data.get('avail_qty'))) {
        if (parseInt(data.get('waste') + parseInt(data.get('qty'))) <= parseInt(data.get('avail_qty'))) {
          validat = true;
        } else {
          swal("Sum of consumed quantity and waste quantity must be equal or less than available quantity");
        }

      } else {
        swal("Consumed quantity must be equal or less than available quantity");
        return validat = false;

      }

    }


    if (data.get('waste') == "") {
      swal("Please enter waste quantity");
      return validat = false;
    } else {
      if (parseInt(data.get('waste')) <= parseInt(data.get('avail_qty'))) {
        if (parseInt(data.get('waste') + parseInt(data.get('qty'))) <= parseInt(data.get('avail_qty'))) {
          validat = true;
        } else {
          swal("Sum of consumed quantity and waste quantity must be equal or less than available quantity");
        }

      } else {
        swal("Waste quantity must be equal or less than available quantity");
        return validat = false;

      }
    }

    //console.log(itemAV);

    return validat;
  }


  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Update Items </h4>
                    <form className="form-sample" onSubmit={submitUser}>
                      <p className="card-description">

                      </p>
                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group row">


                            <label className="col-sm-12"> Item</label>
                            <div className="col-md-12">
                              <input type="text" className="form-control" disabled value={itemAV.item_name} />
                              <input type="hidden" name="item_id" className="form-control" value={itemAV.item_id} />
                              <input type="hidden" name="avail_qty" className="form-control" value={itemAV.avail_qty} />
                            </div>
                          </div>

                        </div>

                        {(reactLocalStorage.get("userLoginType") != "Admin") ?
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-sm-12"> Consumed Quantity*</label>
                              <div className="col-md-12">
                                <input name="qty" type="number" className="form-control" autoComplete="off" min="0" required />
                              </div>
                            </div>
                          </div> : <input type="hidden" name="qty" className="form-control" value="0" />}

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12"> Waste Quantity*</label>
                            <div className="col-md-12">
                              <input name="waste" type="number" className="form-control" autoComplete="off" min="0" required />
                            </div>
                          </div>
                        </div>

                      </div>


                      <button type="submit" className="btn btn-primary mr-2">Update</button>
                      <button type="button" className="btn btn-light" onClick={clickCancel}>Back</button>
                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}

export default UpdateInvWasteItem;