import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";


function TransferAnmMediacal() {



  /******************************************** */
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [chcData, setChcData] = useState([]);
  const [anmData, setAnmData] = useState([]);
  const [userData, setUserData] = useState();

  const [stateData1, setStateData1] = useState([]);
  const [districtData1, setDistrictData1] = useState([]);
  const [blockData1, setBlockData1] = useState([]);
  const [chcData1, setChcData1] = useState([]);
  //   const [anmData1,setAnmData1] =useState([]);

  const [fromData, setFromData] = useState([]);
  //    const [toData,setToData] = useState([]);

  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    getStateData();
    getStateData1();
    document.title = 'Transfer Anm & Medial Team';

  }, [1]);





  /********State*********** */
  let getStateData = async () => {
    const regDataState = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' }
    }
    const getState = await fetch(API_URL + 'admin/state', regDataState);
    const getdatastate = await getState.json();
    setStateData(getdatastate);
  }

  /********District based on state*********** */
  let getDistrictList = async (e) => {
    setDistrictData([]);
    let state_id = e.target.value;
    const regDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    }
    const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
    const getdataDistrict = await getDistrict.json();
    // console.log(getdataDistrict.Rdata);
    if (getdataDistrict.Rdata.length > 0) {
      setDistrictData(getdataDistrict.Rdata);
    }
  }
  /********Block based on district*********** */
  let getBlock = async (e) => {
    setBlockData([]);
    let district_id = e.target.value;
    //console.log(district_id);
    const reDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'district_id': district_id })
    }
    const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
    const getBlockData = await getBlock.json();
    //console.log(getBlockData);
    if (getBlockData.Rdata.length > 0) {
      setBlockData(getBlockData.Rdata);
    }

  }



  /****************Get Chc ,school,village based on block**************************** */

  let getChc = async (e) => {
    setChcData([]);

    let block_id = e.target.value;
    //console.log(district_id);
    const reDataBlock = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'block_id': block_id })
    }
    /************chc************** */
    const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
    const getChcData = await getChc.json();
    //console.log(getBlockData);
    if (getChcData.Rdata.length > 0) {
      setChcData(getChcData.Rdata);
    }

  }

  let getAnm = async (e) => {
    setAnmData([]);

    let chc_id = e.target.value;
    //console.log(district_id);
    const reDataBlock = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'chc_id': chc_id })
    }
    /************anm************** */
    const getANM = await fetch(API_URL + "admin/getAnm", reDataBlock);
    const getAnmData = await getANM.json();
    //console.log(getBlockData);
    if (getAnmData.Rdata.length > 0) {
      setAnmData(getAnmData.Rdata);
    }

  }


  /**************************transfer to************************************** */



  /********State*********** */
  let getStateData1 = async () => {
    const regDataState = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' }
    }
    const getState = await fetch(API_URL + 'admin/state', regDataState);
    const getdatastate = await getState.json();
    setStateData1(getdatastate);
  }

  /********District based on state*********** */
  let getDistrictList1 = async (e) => {
    setDistrictData1([]);
    let state_id = e.target.value;
    const regDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    }
    const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
    const getdataDistrict = await getDistrict.json();
    // console.log(getdataDistrict.Rdata);
    if (getdataDistrict.Rdata.length > 0) {
      setDistrictData1(getdataDistrict.Rdata);
    }
  }
  /********Block based on district*********** */
  let getBlock1 = async (e) => {
    setBlockData1([]);
    let district_id = e.target.value;
    //console.log(district_id);
    const reDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'district_id': district_id })
    }
    const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
    const getBlockData = await getBlock.json();
    //console.log(getBlockData);
    if (getBlockData.Rdata.length > 0) {
      setBlockData1(getBlockData.Rdata);
    }

  }



  /****************Get Chc ,school,village based on block**************************** */

  let getChc1 = async (e) => {
    setChcData1([]);

    let block_id = e.target.value;
    //console.log(district_id);
    const reDataBlock = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'block_id': block_id })
    }
    /************chc************** */
    const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
    const getChcData = await getChc.json();
    //console.log(getBlockData);
    if (getChcData.Rdata.length > 0) {
      setChcData1(getChcData.Rdata);
    }

  }




  /*****************submit******************************* */
  //   const submitFromUser = async(events)=>{
  //          events.preventDefault();
  //          if(validate(events)){
  //            const data = new FormData(events.target);

  //            let formData = {
  //                 'mobile_no':data.get('mobile_no'),
  //             };
  //            const res = await axios.post(API_URL+'admin/TransferData',formData)
  //            .then(ress => { if(ress.data.status=='200'){
  //                console.log(ress.data.Rdata);
  //                   setFromData(ress.data.Rdata);

  //              }else{
  //                 swal(data.get('mobile_no')+ "-" + ress.data.msg)
  //             }

  //             });

  //   }
  // }

  // const submitToUser = async(events)=>{
  //     events.preventDefault();
  //     if(validate(events)){
  //       const data = new FormData(events.target);

  //       let formData = {

  //         'mobile_no':data.get('mobile_no'),

  //       };
  //       const res = await axios.post(API_URL+'admin/TransferData',formData)
  //       .then(ress => { if(ress.data.status=='200'){
  //        // setToData(ress.data.Rdata);
  //         }else{
  //             swal(data.get('mobile_no')+ "-" + ress.data.msg)
  //         }

  //        });

  // }
  // }




  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    console.log(data);
    let validat = true;

    if (data.get('state') == "Select") {
      swal("Please select state");
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('district') == "Select") {
      swal("Please select district");
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('block') == "Select") {
      swal("Please select block");
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('user_id') == "Select" || data.get('user_id') == '') {
      swal("Please select Anm/medical team member ");
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('chc') == "Select") {
      swal('Please select Chc');
      return validat = false;
    } else {
      validat = true;
    }

    return validat;
  }

  /******************************************************** */

  const submitFrom = async (events) => {
    const data = new FormData(events.target); console.log(data);
    events.preventDefault();
    if (validate(events)) {
      const data = new FormData(events.target);
      //  var schoolDa='';  var villageDa='';

      let formData = {

        'state_id': data.get('state'),
        'district_id': data.get('district'),
        'block_id': data.get('block'),
        'chc_phc_id': data.get('chc'),
        'user_id': data.get('user_id')

      };

      console.log(formData);

      const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      const getResps = await fetch(API_URL + 'admin/transferAnmMedicalTeam', regDatas);
      const getDatas = await getResps.json();
      setAnmData([]);
      setUserData();
      swal(getDatas.msg);


    }
  }


  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Transfer Anm/ Medical Team</h4>
                    <form className="form-sample" onSubmit={submitFrom}>
                      <div className="row mt-3">
                        <div className="col-xl-6 d-flex grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <div className="col-md-12">
                                <h5 className="card-title">Transfer From</h5>

                                < div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group row">
                                      <label className="col-sm-12">Select State *</label>
                                      <div className="col-sm-12">
                                        <select className="form-control" name="state1" onChange={getDistrictList} required>
                                          <option>Select State</option>
                                          {stateData.map(states => (
                                            <option value={states.id}>{states.state}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group row">
                                      <label className="col-sm-12">Select District *</label>
                                      <div className="col-sm-12">
                                        <select className="form-control" name="district1" onChange={getBlock} required>
                                          <option>Select</option>
                                          {districtData.map(district => (
                                            <option value={district.id}>{district.name} </option>

                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group row">
                                      <label className="col-sm-12">Select Block *</label>
                                      <div className="col-sm-12">
                                        <select className="form-control" name="block1" onChange={getChc} required>
                                          <option>Select</option>
                                          {blockData.map(blocks => (
                                            <option value={blocks.id}>{blocks.name}</option>

                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group row">
                                      <label className="col-sm-12">Select CHC/PHC *</label>
                                      <div className="col-sm-12">
                                        <select className="form-control" name="chc1" onChange={getAnm} required>
                                          <option>Select</option>
                                          {chcData.map(chc => (
                                            <option value={chc.id}>{chc.name}</option>

                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                </div>





                                {/* <form className="form-sample" onSubmit={submitFromUser}> */}
                                <div className="row">

                                  <div className="col-md-12">
                                    <div className="form-group row">
                                      <label className="col-sm-8">Select Anm/Medical Team *</label>
                                      <div className="col-sm-8">
                                        <select className="form-control" name="mobile_no1" onChange={e => { setUserData(e.target.value) }} required>
                                          <option>Select</option>
                                          {anmData.map(anm => (
                                            <option value={anm.user_id}>{anm.name} - {anm.mobile_no}</option>

                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-sm-4 mt-4"><button type="submit" className="btn btn-primary mr-2">Search</button></div> */}

                                </div>


                                {/* </form> */}
                                {/* {fromData.map((item,index)=>

                                    <div key="{index}">
                                    
                                    <label className="col-sm-12">Mobile Number :  {item.mobile_no} </label>
                                    <label className="col-sm-12">Name  :  {item.name} </label>
                                    <label className="col-sm-12">User Id :  {item.user_id} </label>
                                    <label className="col-sm-12">User Type  :  {item.user_type} </label>
                                    <label className="col-sm-12">State :  {item.state} </label>
                                    <label className="col-sm-12">District :  {item.district_name} </label>
                                    <label className="col-sm-12">Block :  {item.block_name} </label>
                                    <label className="col-sm-12">CHC :  {item.chc_name} </label>
                                     
                                  
                                     
                                    </div> 
                                     )}    */}

                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 d-flex grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title">Transfer To</h5>


                              < div className="row">
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-sm-12">Select State *</label>
                                    <div className="col-sm-12">
                                      <select className="form-control" name="state" onChange={getDistrictList1} required>
                                        <option>Select State</option>
                                        {stateData1.map(states => (
                                          <option value={states.id}>{states.state}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-sm-12">Select District *</label>
                                    <div className="col-sm-12">
                                      <select className="form-control" name="district" onChange={getBlock1} required>
                                        <option>Select</option>
                                        {districtData1.map(district => (
                                          <option value={district.id}>{district.name} </option>

                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-sm-12">Select Block *</label>
                                    <div className="col-sm-12">
                                      <select className="form-control" name="block" onChange={getChc1} required>
                                        <option>Select</option>
                                        {blockData1.map(blocks => (
                                          <option value={blocks.id}>{blocks.name}</option>

                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-sm-12">Select CHC/PHC *</label>
                                    <div className="col-sm-12">
                                      <select className="form-control" name="chc" required>
                                        <option>Select</option>
                                        {chcData1.map(chc => (
                                          <option value={chc.id}>{chc.name}</option>

                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <input name="user_id" type="hidden" value={userData} />
                              </div>
                              {/* <div className="row mt-3"><div className="col-sm-12"><button style={{"marginLeft":"47%"}} type="submit" className="btn btn-primary btn-sm mr-2" >Transfer</button></div></div> */}


                            </div>
                          </div>
                        </div>
                        <div className="row mt-3"><div className="col-sm-12"><button style={{ "marginLeft": "47%" }} type="submit" onClick={submitFrom} className="btn btn-primary btn-sm mr-2" >Transfer</button></div></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}



export default TransferAnmMediacal; 