import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { reactLocalStorage } from "reactjs-localstorage";
import { API_URL } from "../constant";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
const TreatmentModal = (props) => {
   
    // const [loginModalShow, setLoginModalShow] = useState(props.showModal);
    /******************pass & Cpass************************** */
    const [medicine, setMedicine] = useState("");
    const [dose, setDose] = useState("");
    const [followUpDate, setFollowUpDate] = useState(null);
    const [treatAddedDate, SetTreatAddedDate] = useState(null);
    const [suggestion, setSuggestion] = useState("");

    /********************************************************** */
    const handleMedicine = (e) => {
        setMedicine(e.target.value);
    }

    const handleDose = (e) => {
        setDose(e.target.value);
    }
    const handleFollowUp = (date) => {
        setFollowUpDate(date);
    }
    const handleAddedDate = (date) => {
        SetTreatAddedDate(date);
    }
    const handleSuggestion = (e) => {
        setSuggestion(e.target.value);
    }

    /***************validation**************************** */
    const handleValidation = () => {
        let formIsValid = true;

        if (!medicine && medicine === "") {
            formIsValid = false;
            swal('Please Select Medicine');
        }
        if(!dose && dose === ""){
            formIsValid = false;
            swal("Please Select Dose");
        }
       if(!followUpDate){
        formIsValid = false;
        swal("Please Select Follow-Up-Date");

       }
       if(!treatAddedDate){
        formIsValid = false;
        swal("Please Select Treatment added date");
       }

        return formIsValid;
    }

    //**************************Medicine Option************************** */
    const medicineOption = () => {
        if (props.benType === 'CHILDREN 6-59 MONTHS') {
            if ((reactLocalStorage.get("hbResult") === "Mild") || (reactLocalStorage.get("hbResult")) === 'Moderate' || reactLocalStorage.get("hbResult") === 'Severe') {
                return <option value="IFA syrup">IFA syrup</option>;
            }
            if (reactLocalStorage.get("hbResult") === 'Normal') {
                return <option value="You are anemia free and continue with the prophylactic IFA dose once a week">You are anemia free and continue with the prophylactic IFA dose once a week</option>;
            }
        }
        if(props.benType === 'CHILDREN 5-9 YEARS'){
            if((reactLocalStorage.get("hbResult") === "Mild") || (reactLocalStorage.get("hbResult")) === 'Moderate' || reactLocalStorage.get("hbResult") === 'Severe'){
                return <option value="1 IFA pink tablet per day for 2 months">1 IFA pink tablet per day for 2 months</option>;
            }
            if(reactLocalStorage.get("hbResult") === 'Normal'){
                return <option value="You are anemia free and continue with the prophylactic IFA dose once a week">You are anemia free and continue with the prophylactic IFA dose once a week</option>;
            }
        }
        if(props.benType === 'ADOLESCENT 10-19 YEARS' || props.benType === 'MEDICAL TEAM' || props.benType === 'OTHERS'){
            if((reactLocalStorage.get("hbResult") === "Mild") || (reactLocalStorage.get("hbResult")) === 'Moderate' || reactLocalStorage.get("hbResult") === 'Severe'){
                return <option value="2 IFA blue tablets per day for 3 months">2 IFA blue tablets per day for 3 months</option>;
            }
            if(reactLocalStorage.get("hbResult") === 'Normal'){
                return <option value="You are anemia free and continue with the prophylactic IFA dose once a week">You are anemia free and continue with the prophylactic IFA dose once a week</option>;
            }
        }
        if(props.benType === 'PREGNANT WOMEN' || props.benType === 'WOMEN OF REPRODUCTIVEAGE' || props.benType === 'LACTATING WOMEN'){
            if((reactLocalStorage.get("hbResult") === "Mild") || (reactLocalStorage.get("hbResult")) === 'Moderate' || reactLocalStorage.get("hbResult") === 'Severe'){
                return <option value="2 IFA red tablet per day for 3 months">2 IFA red tablet per day for 3 months</option>
            }
            if(reactLocalStorage.get("hbResult") === 'Normal' && props.benType === 'PREGNANT WOMEN' || props.benType === 'LACTATING WOMEN'){
                return <option value="You are anemia free and continue with the prophylactic IFA dose (daily 1)">You are anemia free and continue with the prophylactic IFA dose (daily 1)</option>;
            }
        }
        return null;
    }
/***********************************************************Dose Option **************************************************************************** */
    const doseOptions = () => {
        if (props.benType === 'CHILDREN 6-59 MONTHS') {
            if ((reactLocalStorage.get("hbResult") === "Mild") || (reactLocalStorage.get("hbResult") === "Moderate") || (reactLocalStorage.get("hbResult") === "Severe")) {
                if (props.age === 0) {
                    return <option value="1 ml once in a day">1 ml once in a day</option>;
                } else if (props.age >= 1 && props.age <= 3) {
                    return <option value="11.5 ml once in a day">11.5 ml once in a day</option>;
                } else {
                    return <option value="2 ml once in a day">2 ml once in a day</option>;
                }
            } else if ((reactLocalStorage.get("hbResult") === "Normal")) {
                return <option value="IFA dose once a week">IFA dose once a week</option>;
            }
        }
        if (props.benType === 'CHILDREN 5-9 YEARS') {
            if ((reactLocalStorage.get("hbResult") === "Mild") || (reactLocalStorage.get("hbResult") === "Moderate") || (reactLocalStorage.get("hbResult") === "Severe")) {
                return <option value="1 tablet per day for 2 months">1 tablet per day for 2 months</option>;
            }
            if ((reactLocalStorage.get("hbResult") === "Normal")) {
                return <option value="IFA dose once a week">IFA dose once a week </option>;
            }
        }
        if (props.benType === 'ADOLESCENT 10-19 YEARS' || props.benType === 'MEDICAL TEAM' || props.benType === 'OTHERS') {
            if ((reactLocalStorage.get("hbResult") === "Mild") || (reactLocalStorage.get("hbResult") === "Moderate") || (reactLocalStorage.get("hbResult") === "Severe")) {
                return <option value="2 tablet per day for 3 months">2 tablet per day for 3 months</option>;
            }
            if ((reactLocalStorage.get("hbResult") === "Normal")) {
                return <option value="IFA dose once a week">IFA dose once a week </option>;
            }
        }
        if (props.benType === 'PREGNANT WOMEN' || props.benType === 'WOMEN OF REPRODUCTIVEAGE' || props.hbResult === 'LACTATING WOMEN') {
            if ((reactLocalStorage.get("hbResult") === "Mild") || (reactLocalStorage.get("hbResult") === "Moderate") || (reactLocalStorage.get("hbResult") === "Severe")) {
                return <option value="2 tablet per day for 3 months">2 tablet per day for 3 months</option>;
            } else if ((reactLocalStorage.get("hbResult") === "Normal") && props.benType === 'WOMEN OF REPRODUCTIVEAGE') {
                return <option value="IFA dose once a week">IFA dose once a week </option>;
            } else {
                return <option value="IFA dose daily">IFA dose daily</option>;
            }
        }
        return null;
    };

    const resetForm = () => {
        setMedicine("");
        setDose("");
        setFollowUpDate("");
        SetTreatAddedDate("");
        setSuggestion("");
    };

    useEffect(() => {
        if (!props.show) {
            resetForm();
        }
    }, [props.show]);

    const handleSubmit = async (events) => {
        if(handleValidation()){
            let formData = {
                "patient_treat_id":props?.patientId,
                "lid":props?.testId,
                "treatment_as_per_amb":"",
                "treatment_given_by_id":props?.anmId?.id,
                "follow_up_date_server":followUpDate? format(followUpDate, 'dd-MM-yyyy') : null,
                "suggestion":suggestion,
                "medician":medicine,
                "dose":dose,
                "treat_giv_by_name":props?.anmId?.name,
                "investigated_on_server":treatAddedDate
            }
    
    
            console.log("formData", formData);
    
            const regDatas = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            }
    
            const getResps = await fetch(API_URL + 'admin/add-treatment-data-bkend', regDatas);
            console.log("asdhgsdasd", getResps);
            const getDatas = await getResps.json();
            if ((reactLocalStorage.get("userLoginType") == "Admin")) {
                swal(getDatas?.msg)
              props.close();
                
            } else {
                swal(getDatas?.msg);
            }
            
         
        }

        


    }
    // console.log("PROPS in MODAL", props);
    return (
        <>
            <Modal
                show={props.show}
                cancel={props.close}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <Modal.Header closeButton> */}
                <Modal.Header closeButton onClick={props.close} className="task-header">
                    <Modal.Title id="contained-modal-title-vcenter ">TREATMENT</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label className="col-sm-12">Select medicine *</label>
                                    <div className="col-sm-12">
                                        <select className="form-control" value={medicine} onChange={handleMedicine} required>
                                            <option value="">Select</option>
                                            {medicineOption()}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label className="col-sm-12">Select dose *</label>
                                    <div className="col-sm-12">
                                        <select className="form-control" value={dose} onChange={handleDose} required>
                                            <option value="">Select</option>
                                            {doseOptions()}

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label className="col-sm-12">Next follow up date *</label>
                                    <div className="col-sm-12">
                                        <DatePicker
                                            selected={followUpDate}
                                            onChange={handleFollowUp}
                                            name="dob"
                                            dateFormat="dd-MM-yyyy"
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                            minDate={new Date('01-01-1950')}
                                           // maxDate={new Date()}
                                            placeholderText="Next follow up date"
                                            className="form-control"
                                            id="dobpicker"
                                            tabIndex="5"
                                            onKeyDown={e => e.preventDefault()}
                                            autoComplete="off"

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label className="col-sm-12">Treatment added date *</label>
                                    <div className="col-sm-12">
                                        <DatePicker
                                            selected={treatAddedDate}
                                            onChange={handleAddedDate}
                                            name="dob"
                                            dateFormat="dd-MM-yyyy"
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                            minDate={new Date('01-01-1950')}
                                            maxDate={new Date()}
                                            placeholderText="Treatment added date"
                                            className="form-control"
                                            id="dobpicker"
                                            tabIndex="5"
                                            onKeyDown={e => e.preventDefault()}
                                            autoComplete="off"

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label className="col-sm-12">Any Suggestion</label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" autoComplete="off" value={suggestion} onChange={handleSuggestion} required />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary mr-2" onClick={handleSubmit}>Submit</button>
                        <button type="button" onClick={props.close} className="btn btn-primary mr-2">Cancel</button>
                   
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button onClick={props.close}>Cancel</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TreatmentModal;
