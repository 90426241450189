import React, { useState } from "react";
import Chart from "react-apexcharts";


function StackedBarApexes(props){
	
	const {options,series}=props;
//	console.log(props); //990
	
	return(
	    <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={options}
              series={series}
              type="bar"
              width="100%"
              height="450"
            />
          </div>
        </div>
      </div>
	
	);
}

export default StackedBarApexes;