import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from './loading';
import { reactLocalStorage } from 'reactjs-localstorage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules

import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { toExcel } from 'to-excel';


import $, { readyException } from 'jquery';


function Reffered() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pages, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [totalData, setTotalData] = useState();



  /*********************get parameter from url*********************************** */
  const params = useParams();

  /******************************************************** */

  useEffect(() => {
    document.title = "All Referred Case List";
    getTotalPatientData();
  }, [1]
  );


  useEffect(() => {

    getAllPatientData(pages);

  }, [pages]);



  /************Get all patient user********************** */
  let getAllPatientData = async (page) => {
    setUserData([]);
    let datas = { page: page, per_page: perPage };

    var url = "";
    if (reactLocalStorage.get('userLoginType') == 'Admin') {
      url = 'admin/refferedCases_server';
    } else {
      url = 'chc/refferedCases_server';
      datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
    }
    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        // console.log(ress.data);
        setUserData(ress.data.Rdata);
        setIsLoading(false);
      });
    //  console.log(res.data);
  }

  /************Get total user********************** */
  let getTotalPatientData = async () => {

    let datas = {};
    var url = "";
    if (reactLocalStorage.get('userLoginType') == 'Admin') {
      url = 'admin/refferedCases';
    } else {
      url = 'chc/refferedCases';
      datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
    }
    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        // console.log(ress.data);
        setTotalData(ress.data.Rdata);
        setTotalRows(ress.data.Rdata.length);
        setIsLoading(false);
      });
    //  console.log(res.data);

  }




  /***************** on click of mobile no************************************* */

  const getTestResult = (pid, mob, name) => {
    return navigat('/ref_patient_all_test_list/' + pid + '/' + mob + '/' + name);
  }
  /***************** Anm details************************************ */

  const getAnmDetails = (anm_id, patient_mob) => {
    return navigat('/anm_mediacal_team_user/' + anm_id + '/' + patient_mob);
  }

  /*****************Back************************ */
  let backto = () => {
    return navigat('/all_patient/');
  }
  /****************************************** */

  /********************************************************************* */


  var headers = [
    { label: 'Beneficiary Type', field: 'beneficiary_type' },
    { label: 'Name', field: 'name' },
    { label: 'Patient ID', field: 'patient_id' },
    { label: 'Gender', field: 'gender' },
    { label: 'Pregnancy Duration', field: 'preg_duration' },
    { label: 'HB Value', field: 'haemoglobin_value' },
    { label: 'HB Status', field: 'haemoglobin_based_status' },
    { label: 'Referred', field: 'reffered_center' },
    { label: 'Test At', field: 'venue' },
    { label: 'Age', field: 'age' },
    { label: 'Aadhar no.', field: 'aadhar_no' },
    { label: 'Guardian Name', field: 'guardian_name' },
    { label: 'Mobile No', field: 'mobile_no' },
    { label: 'Address', field: 'address' },
    { label: 'Anm/Medical Team member', field: 'anm_name' },
    { label: 'District', field: 'district' },
    { label: 'Block', field: 'block' },
    { label: 'CHC/PHC', field: 'chc' },
    { label: 'Village', field: 'village' },
    { label: 'School', field: 'school' },
    { label: 'Added On', field: 'added_on' },

  ]


  const dwn = () => {
    var content = toExcel.exportXLS(headers, totalData, 'RefferedCaseList');

  }


  /*********************************************************** */

  const fetchUsers = async page => {
    setLoading(true);
    getAllPatientData(page);
    setLoading(false);
  };

  const handlePageChange = page => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    getAllPatientData(page)
    setPerPage(newPerPage);
    setLoading(false);
  };



  const columns = [

    {
      name: 'Beneficiary Type',
      selector: row => row.beneficiary_type,
      sortable: true,

    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      cell: (row) => <Link to={'/ref_patient_all_test_list/' + row.patient_id + '/' + row.mobile_no + '/' + row.name} >{row.name}</Link>
    },
    {
      name: 'Gender',
      selector: row => row.gender,
      sortable: true,
      cell: (row) => <>{row.gender != null ? row.gender.toUpperCase() : ""}</>,
      width: "100px"
    },

    {
      name: 'Pregnancy Duration',
      selector: row => row.preg_duration,
      sortable: true,
      width: "200px"

    },
    {
      name: 'HB Value',
      selector: row => row.haemoglobin_value,
      sortable: true,
      width: "100px"
    },
    {
      name: ' HB Status',
      selector: row => row.chc_name,
      sortable: true,
      cell: (row) => <>{row.haemoglobin_based_status != null ? row.haemoglobin_based_status.toUpperCase() : ""}</>

    },
    {
      name: "Referred To",
      selector: row => row.reffered_center,
      sortable: true,
      cell: (row) => <><span title={row.reffered_center}>{row.reffered_center}</span></>
    },
    {
      name: "Test At",
      selector: row => row.venue,
      sortable: true,
    },
    {
      name: "Age",
      selector: row => row.age,
      sortable: true,
      cell: (row) => <><span title={row.age}>{row.age}</span></>

    },
    {
      name: "Aadhar no.",
      selector: row => row.aadhar_no,
      sortable: true,

    },

  ];




  const ExpandedComponent = ({ data }) => {
    return (

      <>
        <table className='table table-striped  sadi'>
          <tr><td> <b>Guardian Name. - </b> {data.guardian_name}</td></tr>
          <tr><td> <b>Mobile No. - </b> {data.mobile_no}</td></tr>
          <tr><td> <b>Address</b>  - {data.address}</td></tr>
          <tr><td> <b>Anm/Medical Team member - </b> <Link to={'/ref_anm_medical_team/' + data.anm_teacher_id + '/' + data.mobile_no} >{data.anm_name}</Link></td></tr>
          <tr><td> <b>District - </b> {data.district}</td></tr>
          <tr><td> <b>Block - </b> {data.block}</td></tr>
          <tr><td> <b>CHC/PHC - </b> {data.chc}</td></tr>
          <tr><td> <b>Village - </b> {data.village}</td></tr>
          <tr><td> <b>School - </b> {data.school}</td></tr>
          <tr><td> <b>Added On - </b> <Moment format="DD-MM-YYYY" >{data.added_on}</Moment></td></tr>
          <tr><td> <b>Action - </b>  <Link to={'/ref_patient_all_test_list/' + data.patient_id + '/' + data.mobile_no + '/' + data.name} >View Test Details</Link></td></tr>
        </table>
      </>

    )


  }



  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">


              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">All Referred Cases </h4>
                    <button className="btn btn-success" onClick={dwn} >Export</button>
                   
                      <div className="table-responsive pt-3">

                        <DataTable

                          columns={columns}
                          data={userData}
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          expandableRows={true}
                          expandableRowsComponent={ExpandedComponent}
                        />


                      </div>
                   
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>

    </div>);
}

export default Reffered;


