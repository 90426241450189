import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { reactLocalStorage } from "reactjs-localstorage";
import { API_URL } from "../constant";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TreatmentModel from './treatment_beneficiary_model';
const TestingModal = (props) => {
    console.log("propsTestModel", props);
    // const [loginModalShow, setLoginModalShow] = useState(props.showModal);
    /******************pass & Cpass************************** */
    const [vanueTest, setVanueTest] = useState("");
    const [hbRange, setHbRange] = useState("");
    const [rangeValue, setRangeValue] = useState("");
    const [testDate, setTestDate] = useState(null);
    const [bmiWeight, setBmiWeight] = useState("");
    const [bmiHeight, setBmiHeight] = useState("");
    const [bmiCalculateValue, setBmiCalculateValue] = useState("");
    const [bmiValue, setBmiValue] = useState("");
    const [bmiResult, setBmiResult] = useState("");
    const [showTreatmentModel, setShowTreatmentModel] = useState(false);
    const [hbResult, setHbResult] = useState("");
    const [patientId, setPatientId] = useState("");
    const [testId, setTestId] = useState("")

    /********************************************************** */
    const handleVanueTest = (e) => {
        setVanueTest(e.target.value);
    }
    const handleHbRange = (e) => {
        setHbRange(e.target.value);
    }

    const validateHbRange = () => {
        if (hbRange < 1.8 || hbRange > 18.5) {
            swal("Please enter a value between 1.8 and 18.5");
            setHbRange(""); // Reset the value if it's invalid
        }
    }

    const handleRangeValue = (e) => {
        setRangeValue(e.target.value);
    }

    const handleTestDate = (date) => {
        setTestDate(date);
    }

    const handleBmiWeight = (e) => {
        setBmiWeight(e.target.value);
    }

    const handleBmiHeight = (e) => {
        setBmiHeight(e.target.value);
    }
    const handleHbResult = (e) => {
        setHbResult(e.target.value);
        reactLocalStorage.set("hbResult", e.target.value);
    }

    /***************validation**************************** */
    const handleValidation = () => {
        let formIsValid = true;

        if (!vanueTest && vanueTest === "") {
            formIsValid = false;
            swal('Please Select Vanue of the test');
        }
        if (!hbResult && hbResult === "") {
            formIsValid = false;
            swal('Please Select Hb Result');
        }
        if (!hbRange) {
            formIsValid = false;
            swal("Please Enter Today's Hb range");
        }

        if (!testDate) {
            formIsValid = false;
            swal("Please Select Test Added Date");
        }
        if (!bmiWeight) {
            formIsValid = false;
            swal("Plaese Enter Weight");
        }
        if (!bmiHeight) {
            formIsValid = false;
            swal("Please Enter Height");
        }


        return formIsValid;
    }

    const handleSubmit = async (events) => {
        if (handleValidation()) {
            let formData = {
                "prid": props?.id,
                "patient_id": props?.patientId,
                "haemoglobin_value": hbRange,
                "haemoglobin_based_status": hbResult,
                "investigation_done_by": props?.anmId?.id,
                "venue": vanueTest,
                "referral_status": "",
                "referred_center_id": "",
                "referred_center_name": "",
                "suggestion": "",
                "investigated_test": testDate,
                "follow_up_date": "",
                "muac": "",
                "weight": bmiWeight,
                "height": bmiHeight,
                "bmi_value": bmiValue,
                "bmi_result": bmiResult,
                "latitude": "",
                "longitude": ""
            }


            console.log("formData", formData);

            const regDatas = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            }

            const getResps = await fetch(API_URL + 'admin/add-test-data-bkend', regDatas);

            const getDatas = await getResps.json();
            console.log("asdhgsdasd", getDatas?.RData?.patient_id);
            if ((reactLocalStorage.get("userLoginType") == "Admin")) {
                setPatientId(getDatas?.RData?.patient_id);
                setTestId(getDatas?.RData?.test_id);
                swal(getDatas?.msg)
                // setShowTreatmentModel(true);

            } else {
                swal(getDatas?.msg);
            }
            setShowTreatmentModel(true);
            props.close();
            // setVanueTest("");
            // setHbRange("");
            // setRangeValue("");
            // setTestDate(null);
            // setBmiWeight("");
            // setBmiHeight("");
            // setBmiCalculateValue("");
        }




    }

    // const handleSubmit = (e) => {
    //     
    //     console.log("jsdon", formData)
    //     setShowTreatmentModel(true);
    // }



    const resetForm = () => {
        setVanueTest("");
        setHbRange("");
        setTestDate(null);
        setBmiWeight("");
        setBmiHeight("");
        setBmiCalculateValue("");
        setHbResult("");
    };
    useEffect(() => {
        if (!props.show) {
            resetForm();
        }
    }, [props.show]);


    useEffect(() => {
        if (bmiWeight && bmiHeight) {
            const bmiValue = (bmiWeight / (bmiHeight ** 2)).toFixed(2);

            let category = "";
            if (bmiValue < 18.5) {
                category = "Underweight";
            } else if (bmiValue >= 18.5 && bmiValue <= 24.9) {
                category = "Normal weight";
            } else if (bmiValue >= 25 && bmiValue <= 29.9) {
                category = "Overweight";
            } else if (bmiValue >= 30) {
                category = "Obesity";
            }
            setBmiValue(bmiValue);
            setBmiResult(category);
            setBmiCalculateValue(`${bmiValue} (${category})`);
        } else {
            setBmiValue("");
            setBmiResult("");
            setBmiCalculateValue("");
        }
    }, [bmiWeight, bmiHeight]);

    const extractYear = (ageString) => {
        const yearString = ageString?.split(' ')[0]; // Split the string and take the first element
        const yearInt = parseInt(yearString, 10); // Convert the extracted year to an integer
        return yearInt;
    };

    // console.log("PROPS in MODAL", props);

    return (
        <>
            <Modal
                show={props.show}
                cancel={props.close}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <Modal.Header closeButton> */}
                <Modal.Header closeButton onClick={props.close} className="task-header">
                    <Modal.Title id="contained-modal-title-vcenter ">TEST</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <form className="form-sample"> */}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-12">Venue of the test *</label>
                                <div className="col-sm-12">
                                    <select className="form-control" value={vanueTest} onChange={handleVanueTest} required>
                                        <option value="">Select</option>
                                        <option value="VHSND">VHSND</option>
                                        <option value="T3 camp">T4 Camp</option>
                                        <option value="RBSK Camp">RBSK Camp</option>
                                        <option value="School">School</option>
                                        <option value="Health Facility">Health Facility</option>
                                        <option value="PMSMA">PMSMA</option>
                                        <option value="AMB Jharkhand Week">AMB Jharkhand Week</option>
                                        <option value="Other">OTHERS</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-12">Today's Hb value *</label>
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" autoComplete="off" value={hbRange} onChange={handleHbRange} onBlur={validateHbRange} required />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-12">Hb Result *</label>
                                <div className="col-sm-12">
                                    <select className="form-control" value={hbResult} onChange={handleHbResult} required>
                                        <option value="">Select</option>
                                        <option value="Normal">Normal</option>
                                        <option value="Mild">Mild</option>
                                        <option value="Moderate">Moderate</option>
                                        <option value="Severe">Severe</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-12">Enter today's Hb range value *</label>
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" autoComplete="off" required value={rangeValue} onChange={handleRangeValue} />
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-12">Select Test Added Date *</label>
                                <div className="col-sm-12">
                                    <DatePicker
                                        selected={testDate}
                                        onChange={handleTestDate}
                                        name="dob"
                                        dateFormat="dd-MM-yyyy"
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showYearDropdown
                                        adjustDateOnChange
                                        minDate={new Date('01-01-1950')}
                                        maxDate={new Date()}
                                        placeholderText="Test Added Date"
                                        className="form-control"
                                        id="dobpicker"
                                        tabIndex="5"
                                        onKeyDown={e => e.preventDefault()}
                                        autoComplete="off"

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">


                    </div>
                    <div className="row">
                        <h6>BMI (Body mass indexing) calculation *</h6>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-12">Weight in kg *</label>
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" autoComplete="off" required value={bmiWeight} onChange={handleBmiWeight} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-12">Height in meter *</label>
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" autoComplete="off" required value={bmiHeight} onChange={handleBmiHeight} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-12">BMI Result (kg/m²) *</label>
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" autoComplete="off" required value={bmiCalculateValue} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary mr-2" onClick={handleSubmit}>Submit</button>
                    <button type="button" onClick={props.close} className="btn btn-primary mr-2">Cancel</button>
                    {/* </form> */}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button onClick={props.close}>Cancel</Button> */}
                </Modal.Footer>
            </Modal>
            <TreatmentModel show={showTreatmentModel} close={() => setShowTreatmentModel(false)} benType={props.benType} hbResult={hbResult} age={extractYear(props.age)} patientId={patientId} testId={testId} anmId={props.anmId} />
        </>
    );
};

export default TestingModal;
