import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { reactLocalStorage } from 'reactjs-localstorage';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddChcs() {

  /********************************************* */
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [state, setState] = useState("");
  const [block, setBlock] = useState("");
  const [district, setDistrict] = useState("");
  const [chc, setChc] = useState("");
  const params = useParams();




  const navegate = useNavigate();
  let clickCancel = () => {
    return navegate('/');
  }


  useEffect(() => {
    getStateData();
  }, [1]);


  useEffect(async () => {

    document.title = (params.id) ? 'Edit CHCs' : 'Add CHCs';
    if (params.id) {
      setState(params?.storeId);
      setDistrict(params?.districtId);
      setBlock(params?.blockId);
      setChc(params?.name);
      if (params?.storeId) {
        let state_id = params?.storeId;
        const regDataDistrict = {
          method: 'Post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 'state_id': state_id })
        }
        const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
        const getdataDistrict = await getDistrict.json();
        // console.log(getdataDistrict.Rdata);
        if (getdataDistrict.Rdata.length > 0) {
          setDistrictData(getdataDistrict.Rdata);
        }
      }

      if (params?.blockId) {
        let district_id = params?.districtId;

        const reDataDistrict = {
          method: 'Post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 'district_id': district_id })
        }
        const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
        const getBlockData = await getBlock.json();
        //console.log(getBlockData);
        if (getBlockData.Rdata.length > 0) {
          setBlockData(getBlockData.Rdata);
        }
      }

    }

  }, []);


  /******************Handle States********************* */

  const handleState = async (e) => {
    setState(e.target.value);
    if (e.target.value) {
      let state_id = e.target.value;
      const regDataDistrict = {
        method: 'Post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'state_id': state_id })
      }
      const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
      const getdataDistrict = await getDistrict.json();
      // console.log(getdataDistrict.Rdata);
      if (getdataDistrict.Rdata.length > 0) {
        setDistrictData(getdataDistrict.Rdata);
      }
    }
  }

  /********************Handle District********************** */

  const handleDistrict = async (e) => {
    setDistrict(e.target.value);
    if (e.target.value) {
      let district_id = e.target.value;

      const reDataDistrict = {
        method: 'Post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'district_id': district_id })
      }
      const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
      const getBlockData = await getBlock.json();
      //console.log(getBlockData);
      if (getBlockData.Rdata.length > 0) {
        setBlockData(getBlockData.Rdata);
      }
    }

  }

  //***************************Handle Blocks************************ */

  const handleBlocks = (e) => {
    setBlock(e.target.value);
  }

  //**************************Handle CHC********************* */
  const handleCHC = (e) => {
    setChc(e.target.value)
  }

  /********State*********** */
  let getStateData = async () => {
    const regDataState = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' }
    }
    const getState = await fetch(API_URL + 'admin/state', regDataState);
    const getdatastate = await getState.json();
    setStateData(getdatastate);
    
  }

  /******************************Validation************************** */
  const handleValidation = () => {
    let formIsValid = true;

    if (!state && state === "") {
        formIsValid = false;
        swal('Please Select State');
    }
    if (!district && district ==="") {
        formIsValid = false;
        swal('Please Select District');
    }
    if (!block && block === "") {
        formIsValid = false;
        swal('Please Select Block');
    }
    if (!chc) {
        formIsValid = false;
        swal('Please Enter CHC');
    }
   
    
    
    return formIsValid;
}

  /***************************Add CHCs**************************** */

  const AddCHc = async () => {
    // if (chc && state && district && block) {
      let formData = {
        "name": chc,
        "block_id": block
      };

      const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      const getResps = await fetch(API_URL + 'admin/add-chc', regDatas);
      const getDatas = await getResps.json();

      swal(getDatas.msg);
    //}


  }

  /*********************************Update CHc************************************ */
  const UpdateCHCs = async () => {

    let formData = {

      'name': chc,
      'id': params?.id

    };

    const regDatas = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    }

    const getResps = await fetch(API_URL + 'admin/update-chc', regDatas);
    const getDatas = await getResps.json();

    swal(getDatas.msg);

  }

  const handleSubmit = (events) => {
    if(handleValidation()){
      events.preventDefault();
      if (params?.id) {
        UpdateCHCs();
      } else {
        AddCHc();
      }
    }
   

  }



  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">{(params?.id)?"Update CHC":"Add CHC"}</h4>
                    {/* <form className="form-sample" > */}


                    
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select State *</label>
                          <div className="col-sm-12">
                            <select className="form-control" value={state} onChange={handleState} required disabled={(params?.id) ? true : false} >
                              <option value="">Select State</option>
                              {stateData.map(states => (
                                <option value={states.id}>{states.state}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select District *</label>
                          <div className="col-sm-12">
                            <select className="form-control" value={district} onChange={handleDistrict} required disabled={(params?.id) ? true : false}>
                              <option value="">Select District</option>
                              {districtData.map(district => (
                                <option value={district.id}>{district.name} </option>

                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select Block *</label>
                          <div className="col-sm-12">
                            <select className="form-control" value={block} onChange={handleBlocks} required disabled={(params?.id) ? true : false}>
                              <option value="">Select Block</option>
                              {blockData.map(blocks => (
                                <option value={blocks.id}>{blocks.name}</option>

                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">CHC Name *</label>
                          <div className="col-sm-12">
                            <input type="text" className="form-control" value={chc} onChange={handleCHC} autoComplete="off" required />
                          </div>
                        </div>
                      </div>

                    </div>




                    <button type="submit" className="btn btn-primary mr-2" onClick={handleSubmit} >Submit</button>
                    <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                    {/* </form> */}
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}

export default AddChcs;