import React , {useEffect,useState} from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
/************get parameter from url******************* */
import { useParams,useNavigate } from 'react-router-dom';
/*******************Date Picker**************************** */
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function EditItems(){

   /********************************************* */
   const [stateData, setStateData] = useState([]);
   const [districtData, setDistrictData] =useState([]);
  // const [blockData,setBlockData] =useState([]);
  // const [chcData,setChcData] =useState([]);
    const [typItem,setTypItem] =useState([]);

   const [userFormData,setUserFormData] = useState([]);
   const [dhFun,setDhFun] = useState(true);
   /******************pass & Cpass************************** */
  //  const [passData,setPassData] = useState();
  //  const [cpassData,setCpassData] = useState();
  
   /********************medical team Hide Show************************* */

   const [hideDiv, setHideDiv] = useState(false);
 /*****************Date****************************** */
  const [startDate, setStartDate] = useState(new Date());
 /***********************radio button***************************** */ 
    const [rad,setRad] = useState(false);
  
 /********************back***************************************** */
      const navegate = useNavigate();
     let clickCancel =()=>{
            return navegate('/view_item_list');
    }
   /*********************get parameter from url*********************************** */
   const params = useParams();
   /*************userd as document.ready -  useEffect****************** */
     useEffect( () => {
     document.title='Edit Items ';

      getDataOfUser();
     // getStateData();
    
  },[1]
  );

  //console.log(userFormData.gender);getEditItems

   let getDataOfUser = async() => {
       const data = {'id':params.id} ;
       var res = await axios.post(API_URL+"admin/getItemDetails",data).then( (ress) => {
         //   console.warn(ress.data.Rdata[0]);
            (ress.data.Rdata[0].anm_status=='1')?setDhFun(true):setDhFun(false);
          //  console.log(dhFun);
         setUserFormData(ress.data.Rdata[0]);
         
       });
 
   } 
   //console.log(userFormData.dob);

 
  


/*****************submit******************************* */
 const submitUser = async(events)=>{
        events.preventDefault();
        if(validate(events)){
          const data = new FormData(events.target);
     
          let formData = {
            'item':data.get('item'),           
            'id':params.id,
            'type':data.get('type'),
            'model':data.get('model'),
            'description':data.get('description'),
            'anm_status':data.get('anm_status')
          };
       //   console.log(formData); 
           
          const regDatas = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
          }
      
          const getResps = await fetch(API_URL+'admin/editInventoryItem',regDatas);
          const getDatas = await getResps.json();  
            
           swal(getDatas.msg);
          
        }
 }


 /***************validation**************************** */
 let validate = (ev) => {
  const data = new FormData(ev.target);
 // console.log(data.get);
  let validat = true;

  
  if(data.get('item') == ''){
    swal('Please enter item name');
    return validat = false;
  }else{
    let nameRegExp="^[a-zA-Z]";
     //let testemail= emailRegExp.test(data.get('email'));
     let testname= data.get('item').match(nameRegExp);
     if(testname){
       validat = true;
     }else{ swal('invalid item name');
     return validat = false;
     }
  }
  if(data.get('type')=="Select"){
    swal("Please select item");
    return validat = false;
   }else{
    validat = true;
   }

  return validat;
}


    /******************************* */
    return ( 
        <div className="container-scroller">
   
    <Hor_header/>
   
    <div className="container-fluid page-body-wrapper">
     
      
      <Ver_header/>
      
  
      <div className="main-panel">        
        <div className="content-wrapper">
          <div className="row">
        
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Update Inventory Item</h4>
                  <form className="form-sample" onSubmit={submitUser}>
                 
                   
                    <div className="row">
                     
                     <div className="col-md-6">
                       <div className="form-group row">
                         <label className="col-sm-12">Add Items Name*</label>
                         <div className="col-sm-12">
                           <input name="item" type="text"  className="form-control" autoComplete="off"  value={userFormData.item_name} onChange={e => setUserFormData({item:e.target.value})} required />
                         </div>
                       </div>
                     </div> 
                     </div>
                     <div className="row">
                     <div className="col-md-6">
                          <div className="form-group row">
                                                         
                          <label className="col-sm-12">Select Type*</label>
                          <div className="col-md-12">
                          
                              <select className="form-control" name="type" onChange={e => setUserFormData({type:e.target.value})}  required>
                                   <option>Select</option>
                                   <option value="Consumable" selected={(userFormData.type=="Consumable")?"selected":""}>Consumable</option>
                                   <option value="Non consumable" selected={(userFormData.type=="Non consumable")?"selected":""}>Non consumable</option>
                               </select>
                          </div>
                          </div>
                                            
                       </div> 
                     
                   </div>

                   <div className="row">
                            <div className="col-md-6">
                                <div className="form-group row">
                                  <label className="col-sm-12">Is this item visible to ANM/RBSK/Nurse*</label>
                                  <div className="col-sm-12">
                                
                                    <div className="form-check">
                                      <label className="form-check-label">
                                        <input type="radio" className="form-check-input"  name="anm_status" id="membershipRadios7" value="1"  checked={(dhFun)?true:false} onChange={(e)=>{setDhFun(!dhFun) ;setUserFormData({"anm_status":e.target.value})}}  />
                                        Yes
                                      <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check">
                                      <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="anm_status" id="membershipRadios8" value="0"  checked={(dhFun)?false:true} onChange={(e)=>{setDhFun(!dhFun) ;setUserFormData({"anm_status":e.target.value})}}   />
                                        No
                                      <i className="input-helper"></i></label>
                                    </div>
                                
                                  </div>
                                </div>
                              </div>
                              </div> 

                   <div className="row">
                                <div className="col-md-6">
                                <div className="form-group row">
                                <label className="col-sm-12">Model / Make </label>
                                <div className="col-md-12">
                                  <input name="model" type="text"  className="form-control" autoComplete="off" value={userFormData.make_model} onChange={e => setUserFormData({model:e.target.value})}  /> 
                                
                                  </div>
                                </div>
                                </div></div>
                                <div className="row">
                                <div className="col-md-6">
                                <div className="form-group row">
                                                              
                                  <label className="col-sm-12">Description</label>
                                    <div className="col-md-12">
                                        {/* <textarea name="description" className="form-control" onChange={e => setUserFormData({description:e.target.value})} >{userFormData.description} </textarea> */}
                                        <textarea name="description" rows="5" className="form-control" value={userFormData.description} onChange={e => setUserFormData({description:e.target.value})} ></textarea>
                                    </div>
                                </div>
                            
                                </div> 

                            </div>
                   
                    <button type="submit" className="btn btn-primary mr-2">Update</button>
                    <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                  </form>
                </div>
              </div>
            </div>
            
            
          </div>
        </div>
      
          <Footer/>
            
      </div>
    
    </div>
    
    
  </div>
 
   
    );
}

export default EditItems;