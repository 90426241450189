import React,{useEffect,useState} from 'react';
import {Link ,useNavigate } from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import swal from 'sweetalert';
import {API_URL} from '../../components/constant.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isDisabled } from '@testing-library/user-event/dist/utils';



function Login(){

  /******************************** */
  const navigat =useNavigate();
  const isLogin=reactLocalStorage.get('isLogin');
  useEffect(() => {
      if(isLogin){
      return  navigat('/dashboard')
      
       }
       document.title='Login';
    });

  
   /********************disable enable************************* */

   const [disDiv, setDisDiv] = useState(false);

 /*************Submit******************** */
  
  let doLogin = async(e)=>{
    setDisDiv(true);
    e.preventDefault();
    if(validate(e)){
      const data = new FormData(e.target);
      let formData = {
        'email':data.get('email'),
        'password':data.get('password') 
      };
    //  console.log(formData); 
     
    const regData = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
     }
     const getResp = await fetch(API_URL+'admin/login',regData);
     const getdatas = await getResp.json();   
     
   //  console.log(getdatas);
      if(getdatas.status=='200'){
        reactLocalStorage.set('isLogin',true);
        reactLocalStorage.set('user_name',getdatas.res_data.name);
        reactLocalStorage.set('user_email',getdatas.res_data.email);
        reactLocalStorage.set('userLoginType',"Admin");

        reactLocalStorage.set('chc_phc',"");
        reactLocalStorage.set('block',"");
        reactLocalStorage.set('district',"");
        
      // console.log(getdatas.res_data.name);
      setDisDiv(false);
          return navigat('/dashboard');
      }else{
       
        notify("Wrong Username/Password");
        setDisDiv(false);
      }

       
    }

}
/**************Validation************************* */
let validate = (ev) => {
  const data = new FormData(ev.target);
 // console.log(data.get);
  let validat = true;
   if(data.get('email') == ''){
    notify('Please enter email');
     validat = false;
   }else{
    let emailRegExp="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
     //let testemail= emailRegExp.test(data.get('email'));
     let testemail= data.get('email').match(emailRegExp);
     if(testemail){
      return validat = true;
     }else{ notify('Wrong email');
     return validat = false;
     }
   }
  }
 /*********************Toast****************************** */
 const notify = (e) => toast(e);
 /******************************************************* */
 
    return (
<>
<ToastContainer />
<div className="container-scroller">
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo" style={{"textAlign":"center"}}>
                <img src={process.env.PUBLIC_URL+"/images/logo.png"} alt="logo" style={{"width":"65px"}} />
              </div>
              <h4>T4 Admin Login</h4>
              <h6 className="font-weight-light">Sign in to continue.</h6>
              <form className="pt-3" onSubmit={doLogin}>
                <div className="form-group">
                  <input type="email" name="email" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email"  required/>
                  
                </div>
                <div className="form-group">
                  <input type="password" name="password" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" required/>
                </div>
                <div className="mt-3">
                  <button type="submit" id="btn" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={disDiv}  >SIGN IN</button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  {/* <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input"/>
                      Keep me signed in
                    </label>
                  </div> */}
                  {/* <Link to="/forgot_password" className="auth-link text-black">Forgot password?</Link> */}
                </div>
              
                {/* <div className="text-center mt-4 font-weight-light">
                  Don't have an account? <a href="register.html" className="text-primary">Create</a>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
   
    </div>
  
  </div>
  </>
    );
}

export default Login ;