import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";


function Transfer() {



  /******************************************** */
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [chcData, setChcData] = useState([]);
  const [anmData, setAnmData] = useState([]);

  const [stateData1, setStateData1] = useState([]);
  const [districtData1, setDistrictData1] = useState([]);
  const [blockData1, setBlockData1] = useState([]);
  const [chcData1, setChcData1] = useState([]);
  const [anmData1, setAnmData1] = useState([]);

  const [fromData, setFromData] = useState([]);
  const [toData, setToData] = useState([]);

  const [textTransfer, setTextTransfer] = useState('Transfer');

  /********************disable enable************************* */

  const [disDiv, setDisDiv] = useState(false);
  const [mob, setMOb] = useState();

  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    getStateData();
    // getStateData1();
    document.title = 'Transfer Beneficiary';

  }, [1]);





  /********State*********** */
  let getStateData = async () => {
    const regDataState = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' }
    }
    const getState = await fetch(API_URL + 'admin/state', regDataState);
    const getdatastate = await getState.json();
    setStateData(getdatastate);
  }

  /********District based on state*********** */
  let getDistrictList = async (e) => {
    setDistrictData([]);
    let state_id = e.target.value;
    const regDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    }
    const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
    const getdataDistrict = await getDistrict.json();
    // console.log(getdataDistrict.Rdata);
    if (getdataDistrict.Rdata.length > 0) {
      setDistrictData(getdataDistrict.Rdata);
    }
  }
  /********Block based on district*********** */
  let getBlock = async (e) => {
    setBlockData([]);
    let district_id = e.target.value;
    //console.log(district_id);
    const reDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'district_id': district_id })
    }
    const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
    const getBlockData = await getBlock.json();
    //console.log(getBlockData);
    if (getBlockData.Rdata.length > 0) {
      setBlockData(getBlockData.Rdata);
    }

  }



  /****************Get Chc ,school,village based on block**************************** */

  let getChc = async (e) => {
    setChcData([]);

    let block_id = e.target.value;
    //console.log(district_id);
    const reDataBlock = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'block_id': block_id })
    }
    /************chc************** */
    const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
    const getChcData = await getChc.json();
    //console.log(getBlockData);
    if (getChcData.Rdata.length > 0) {
      setChcData(getChcData.Rdata);
    }

  }

  let getAnm = async (e) => {
    setAnmData([]);
    setAnmData1([]);
    let chc_id = e.target.value;
    //console.log(district_id);
    const reDataBlock = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'chc_id': chc_id })
    }
    /************anm************** */
    const getANM = await fetch(API_URL + "admin/getAnm", reDataBlock);
    const getAnmData = await getANM.json();
    //console.log(getBlockData);
    if (getAnmData.Rdata.length > 0) {
      setAnmData(getAnmData.Rdata);
      setAnmData1(getAnmData.Rdata);
    }

  }


  /**************************transfer to************************************** */






  const submitFromUser = async (events) => {
    // console.log(events);
    //   events.preventDefault();
    //   if(validate(events)){
    //    const data = new FormData(events.target);
    setMOb(events);
    let formData = {
      'mobile_no': events,
    };
    const res = await axios.post(API_URL + 'admin/TransferData', formData)
      .then(ress => {
        if (ress.data.status == '200') {
          // console.log(ress.data.Rdata);
          setFromData(ress.data.Rdata);

        } else {
          swal(events + "-" + ress.data.msg)
          //  swal( ress.data.msg);
        }

      });

    //}
  }




  const submitToUser = async (events) => {
    // events.preventDefault();
    // if(validate(events)){
    // const data = new FormData(events.target);

    let formData = {

      'mobile_no': events,

    };
    const res = await axios.post(API_URL + 'admin/TransferData', formData)
      .then(ress => {
        if (ress.data.status == '200') {
          setToData(ress.data.Rdata);
        } else {
          swal(events + "-" + ress.data.msg)
        }

      });

    // }
  }


  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);

    let validat = true;

    if (data.get('mobile_no') == '') {
      swal('Please enter mobile');
      return validat = false;
    } else {
      let mobRegExp = "^[0-9]{10}";
      //let testemail= emailRegExp.test(data.get('email'));
      let mobname = data.get('mobile_no').match(mobRegExp);
      if (mobname) {
        validat = true;
      } else {
        swal('Invalid mobile');
        return validat = false;
      }
    }

    return validat;
  }

  /******************************************************** */
  let getTransfered = async () => {

    // console.log(fromData.length);
    if ((fromData.length > 0) && (toData.length > 0)) {


      setDisDiv(true);
      setTextTransfer("Transfering...");

      if ((fromData[0].user_id != 'undefined') && (toData[0].user_id != 'undefined')) {
        let formData = { "fromId": fromData[0].user_id, "toId": toData[0].user_id }

        const res = await axios.post(API_URL + 'admin/updateTransferedDataAnmTeacherId', formData)
          .then(ress => {
            if (ress.data.status == '200') {
              swal(ress.data.msg);
            } else {
              swal(ress.data.msg)
            }
            setFromData([]);
            setToData([]);

            setAnmData([]);
            setAnmData1([]);
            setTextTransfer("Transfer");
            setDisDiv(false);
          });
      }
    } else {
      swal("Please select all mandatory fields");
    }

  }


  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Transfer Beneficiary</h4>

                    <div className="row mt-3">
                      <div className="col-xl-12 d-flex grid-margin stretch-card">
                        <div className="card">
                          <div className="card-body">
                            <div className="col-md-12">
                              <h5 className="card-title">Transfer From</h5>

                              < div className="row">
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-sm-12">Select State *</label>
                                    <div className="col-sm-12">
                                      <select className="form-control" name="state" onChange={getDistrictList} required>
                                        <option>Select State</option>
                                        {stateData.map(states => (
                                          <option value={states.id}>{states.state}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-sm-12">Select District *</label>
                                    <div className="col-sm-12">
                                      <select className="form-control" name="district" onChange={getBlock} required>
                                        <option>Select</option>
                                        {districtData.map(district => (
                                          <option value={district.id}>{district.name} </option>

                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-sm-12">Select Block *</label>
                                    <div className="col-sm-12">
                                      <select className="form-control" name="block" onChange={getChc} required>
                                        <option>Select</option>
                                        {blockData.map(blocks => (
                                          <option value={blocks.id}>{blocks.name}</option>

                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-sm-12">Select CHC/PHC *</label>
                                    <div className="col-sm-12">
                                      <select className="form-control" name="chc" onChange={getAnm} required>
                                        <option>Select</option>
                                        {chcData.map(chc => (
                                          <option value={chc.id}>{chc.name}</option>

                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                              </div>





                              {/* <form className="form-sample" onSubmit={submitFromUser}> */}
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-sm-12">Select Anm/Medical Team (Transfer from)*</label>
                                    <div className="col-sm-12">
                                      {/* <select className="form-control" name="mobile_no" onChange={e => {setFromData([]);setToData([]);submitFromUser(e.target.value);}} required> */}
                                      <select className="form-control" name="mobile_no" onChange={e => { submitFromUser(e.target.value); }} required>
                                        <option>Select</option>
                                        {anmData.map(anm => (
                                          <option value={anm.mobile_no}>{anm.name} - {anm.mobile_no} - cases {anm.case_amn}</option>

                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-sm-4 mt-4"><button type="submit" className="btn btn-primary mr-2">Search</button></div> */}




                                <div className="col-md-6">
                                  <div className="form-group row">


                                    <label className="col-sm-12">Select Anm/Medical Team (Transfer To)*</label>
                                    <div className="col-md-12">
                                      {/* <select className="form-control" name="mobile_no" onChange={e => {setFromData([]);setToData([]);submitToUser(e.target.value)}}  required> */}
                                      <select className="form-control" name="mobile_no" onChange={e => { submitToUser(e.target.value) }} required>
                                        <option>Select</option>
                                        {anmData1.map(anm => (
                                          (anm.mobile_no == mob) ? "" : <option value={anm.mobile_no}>{anm.name} - {anm.mobile_no} - cases {anm.case_amn}</option>

                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  {/* <div className="col-sm-4 mt-4"><button type="submit" className="btn btn-primary mr-2">Search</button></div> */}
                                </div>
                              </div>
                              {/* </form> */}
                              {/* {fromData.map((item,index)=>

                                    <div key="{index}">
                                    
                                    <label className="col-sm-12">Mobile Number :  {item.mobile_no} </label>
                                    <label className="col-sm-12">Name  :  {item.name} </label>
                                    <label className="col-sm-12">User Id :  {item.user_id} </label>
                                    <label className="col-sm-12">User Type  :  {item.user_type} </label>
                                    <label className="col-sm-12">State :  {item.state} </label>
                                    <label className="col-sm-12">District :  {item.district_name} </label>
                                    <label className="col-sm-12">Block :  {item.block_name} </label>
                                    <label className="col-sm-12">CHC :  {item.chc_name} </label>
                                     
                                  
                                     
                                    </div> 
                                     )}    */}

                            </div>

                          </div>
                        </div>
                      </div>



                    </div>
                    <div className="row mt-3"><div className="col-sm-12"><button style={{ "marginLeft": "47%" }} type="submit" className="btn btn-primary btn-sm mr-2" disabled={disDiv} onClick={() => { if (window.confirm('Are you sure to tranfer beneficiary,if done transfered data will not revert back?')) { getTransfered() } }}>{textTransfer}</button></div></div>

                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}



export default Transfer; 