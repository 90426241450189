import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { param, readyException } from 'jquery';


function Setting() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  $('#example').DataTable().destroy();

  $(document).ready(function () {

    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print']
      //  responsive: true
    });
  });
  /*********************get parameter from url*********************************** */
  const params = useParams();

  /******************************************************** */

  useEffect(() => {
    document.title = "Settings";
    getAllSettingData();
  }, [1]
  );
  /************Get all state data********************** */
  let getAllSettingData = async () => {
    // const datas = {'pid':params.pid} ;
    //  console.log(datas);
    const res = await axios.post(API_URL + 'admin/getAllSettingData')
      .then(ress => {
        // console.log(ress.data);
        setUserData(ress.data.Rdata);
        setIsLoading(false);
      });
    //  console.log(res.data);
  }
  /******************Redirecting to edit page***************************** */
  let editSetting = (data) => {
    //console.warn("id="+data);
    return navigat('/edit_setting/' + data);
  }




  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">


              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Settings </h4>
                   
                      <div className="table-responsive pt-3">
                        {/* <table className="table table-bordered display responsive nowrap" id="example"> */}
                        <table className="table table-bordered display  nowrap" id="example">
                          <thead>
                            <tr>

                              {/* <th>Last Updated On </th> */}
                              <th>App Link</th>
                              <th>Updated on</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>

                            {userData.map((udata, index) => (
                              <tr key={index}>
                                {/* <td> {udata.last_update}</td> */}
                                <td> {udata.link}</td>
                                <td><Moment format="DD-MM-YYYY h:m:s A">{udata.updated_on}</Moment></td>
                                <td>
                                  <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Edit" onClick={() => { editSetting(udata.id) }}>
                                    <i className="typcn typcn-edit  text-primary"></i>
                                  </button>
                                </td>


                              </tr>
                            )
                            )}

                          </tbody>
                        </table>
                      </div>
                    
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>

    </div>);
}

export default Setting;