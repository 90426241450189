import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate } from "react-router-dom";
//import DatePicker from "react-datepicker";
import { reactLocalStorage } from 'reactjs-localstorage';
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import swal from "sweetalert";

function Mpr() {

  /********************************************* */

  /******************pass & Cpass************************** */
  const [mnt, setMnt] = useState([]);
  const [mntno, setMntno] = useState([]);
  const [yrs, setYrs] = useState([]);

  /********************medicalteam Hide Show************************* */


  const navegate = useNavigate();
  let clickCancel = () => {
    return navegate('/');
  }

  /*****************Date****************************** */
  const [startDate, setStartDate] = useState();
  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    document.title = 'MPR';

    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var d = new Date();
    //var monthName=months[];
    let arr = [];
    let arrno = []
    let month_no = d.getMonth();
    for (let i = 0; i < month_no; i++) {
      arr.push({ "name": months[i], "val": i + 1 });
      //arrno.push(i+1);
    }

    //setMntno(arrno);

    //console.log(arr);

    let yrss = d.getFullYear();
    let ys = [];
    for (let i = yrss; i >= 2022; i--) {
      ys.push({ "year": i });
    }
    // console.log(ys);

    setMnt(arr);
    setYrs(ys);


  }, [1]);

  //console.log(yrs);
  //console.log(mnt);




  /*****************submit******************************* */
  const submitUser = async (events) => {
    events.preventDefault();
    if (validate(events)) {
      const data = new FormData(events.target);
     
      let formData = {};
      if (reactLocalStorage.get("userLoginType") != 'Admin') {

        formData = {
          ...formData,

          'month': data.get('month'),
          'year': data.get('year'),
          'type': reactLocalStorage.get("userLoginType").toLowerCase(),
          'id': reactLocalStorage.get("userType"),
          'user_id': reactLocalStorage.get("user_id")
        };

        let url_create_mpr = "";
        let url_download_mpr = "";
        if ((reactLocalStorage.get("user_name").match(/(^|\W)SDH($|\W)/))) {
          url_create_mpr = "mpr_sdh/report";
          url_download_mpr = 'mpr_sdh/downloadsFile/';
        }
        else if ((reactLocalStorage.get("user_name").match(/(^|\W)DH($|\W)/))) {
          url_create_mpr = "mpr_sdh/report";
          url_download_mpr = 'mpr_sdh/downloadsFile/';
        }
        else {
          url_create_mpr = "mpr_chcs/report";
          url_download_mpr = 'mpr_chcs/downloadsFile/';
        }

        


        const res = await axios.post(API_URL + url_create_mpr, formData).then(async ress => {
          //    console.log(ress.data);
          swal((ress.data.msg));

          if (ress.data.status == '200') {
            // let url={"url":ress.data.url};  
            let file = ress.data.url.replace("mprs/", "");

            window.open(API_URL + url_download_mpr + file, '_blank');

            const response = await fetch(API_URL + url_download_mpr + file);
            const blob = await response.blob();

            const blobUrl = URL.createObjectURL(blob);

            // Open the blob URL in a new tab
            window.open(blobUrl, '_blank');


          }

        });

      }
    }
  }


  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    // console.log(data.get);
    let validat = true;

    if (data.get('year') == 'select') {
      swal('Please select year');
      return validat = false;
    } else {
      validat = true;
    }

    if (data.get('month') == "select") {
      swal('Please select month');
      return validat = false;
    } else {
      validat = true;
    }
    return validat;
  }

  const getTheMonthList = (e) => {
    var x = new Date();
    if (e.target.value != "") {
      if (Number(e.target.value) < x.getFullYear()) {
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let arr = [];

        let month_no = 12;
        for (let i = 0; i < month_no; i++) {
          arr.push({ "name": months[i], "val": i + 1 });
        }
        setMnt(arr);
      } else {
        var months1 = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var d = new Date();
        let arr = [];
        let month_no = d.getMonth();
        for (let i = 0; i < month_no; i++) {
          arr.push({ "name": months1[i], "val": i + 1 });
        }
        setMnt(arr);
      }
    }

    //  console.log("test",typeof e.target.value);

    //  console.log("test",typeof );
    // var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // var d = new Date();
  }


  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Generate MPR</h4>
                    <form className="form-sample" onSubmit={submitUser}>


                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select Year</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="year" required onChange={e => getTheMonthList(e)}>
                                <option value=''>Select</option>
                                {yrs.map(itm => (
                                  <option value={itm.year}>{itm.year}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select Month</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="month" required>
                                <option value=''>Select</option>

                                {mnt.map((itm) => (
                                  <option value={itm.val}>{itm.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        


                      </div>
                      <button type="submit" className="btn btn-primary mr-2">Generate</button>
                      <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}

export default Mpr;