import React from 'react';
import { BrowserRouter ,Route ,Routes} from 'react-router-dom';
// import './App.css';
import { reactLocalStorage } from 'reactjs-localstorage';

import Login from './components/front/login';
import ChcLogin from './components/front/chcLogin';



import Dashboard from './components/front/dashboard';
import AddUser from './components/front/add_user';
import AllUser from './components/front/all_users';
import EditUser from './components/front/edit_user';
import AllPatient from './components/front/all_patient';
import AllMemberList from './components/front/patient_all_member_list';
import TestListOfMember from './components/front/patient_all_test_list';
import TreatmentDataOfMember from './components/front/patient_all_treatment_list';
import AnmMedicalUser from './components/front/anm_mediacal_team_user';
import States from './components/front/view_states';
import Districts from './components/front/view_districts';
import Blocks from './components/front/view_blocks';
import CHC from './components/front/view_chc';
import School from './components/front/view_school';
import Villages from './components/front/view_village';
import AddChc from "./components/front/addChc";
import AllChc from "./components/front/allChc";
import Transfer from "./components/front/transfer_beneficiary";
import EditChc from "./components/front/editChcReg";
import HbMeter from "./components/front/all_hb_meter_req";
import AllPatient_csv from "./components/front/allPatient_csv";
import Setting from "./components/front/setting";
import EditSetting from "./components/front/edit_setting";
import AddBlockUser from "./components/front/addBlock";
import AllBlockUser from "./components/front/allBlockUser";
import EditBlockUser from './components/front/editBlockUser';
import Privacy from './components/front/privacyPolicy';
import TransferArchive from './components/front/view_ben_archive';
import TransferAnmMediacal from './components/front/transfer_anm_medical';
import TransferAnmArchive from './components/front/view_anm_archive';
import Reffered from './components/front/reffered_cases';
import RefTestListOfMember from './components/front/ref_patient_all_test_list';
import RefTreatmentDataOfMember from './components/front/ref_patient_all_treatment_list';
import RefsAnm from './components/front/ref_anm_medical_team';
import AddDistrictUser from './components/front/addDistrict';
import AllDistrictUser from './components/front/allDistrictUser';
import EditDistrictUser from './components/front/editDistrictUser';
import SubCenter from './components/front/view_sub_center';
import AllDataMemberList from './components/front/all_data_member_list';
import AllDataMemberTest from './components/front/all_data_member_test';
import AllDataMemberTreatment from './components/front/all_data_member_treatment';
import MemberAnmMedicalUser from './components/front/all_member_anm_medical_team';
import HbMeterChc from './components/front/hbmeter_chc_req';
import AddItems from './components/front/add_item';
import ItemList from './components/front/view_item_list';
import EditItems from './components/front/editItems';
//import AddInvItems from './components/front/add_inv_item';
import AddInvItems from './components/inventory/addInStock';
/*import InventoryList from './components/front/view_inventory';*/
import InventoryList from './components/inventory/viewInStock';
import EditInvItem from './components/front/edit_inv_item';
/*import RequestInvItem from './components/front/add_req_inventory';*/
import RequestInvItem from './components/inventory/addRequest';
/*import MyInventoryReqList from './components/front/my_inventory_req';*/
import MyInventoryReqList from './components/inventory/myRequest';
import InventoryReqList from './components/front/inventory_req';
import UpdateInvWasteItem from './components/inventory/update_inv_item_waste';
import Notification from './components/front/notification';
import InventoryHistory from './components/front/inv_item_state_his';
import InventoryAssign from './components/front/inventory_item_assign_wr';
import MyCHCRequest from './components/inventory/myCHCRequest';
import MyANMReq from './components/inventory/myAnmReq';
import DistrictRequest from './components/inventory/districtRequest';
import AssignInvWR from "./components/inventory/assign_item_wr";
import AssignInvDistWR from "./components/inventory/assign_item_dist_wr";
import AssignInvChcWR from "./components/inventory/assign_item_chc_wr";
import MPR from "./components/front/mpr";
import AllIssuedItemList from "./components/inventory/allIssuedItemList";
import DetailOfIssuedItem from "./components/inventory/getDetailsOfIssuedItem";
import EditPatient from './components/front/edit_patient';
//import Test from './components/front/test';
import AddStates from "./components/front/add_states";
import AddDistrictName  from './components/front/add_distric_name';
import AddBlocks from './components/front/addBlocks';
import ChcsName from './components/front/add_chs_name';
import AddSubCeter from './components/front/add_sub_center_name';
import AddVillages from './components/front/add_villages';
import AddSchools from './components/front/add_schools';
import AddBeneficiary from './components/front/add_beneficiary';
import BeneficiaryList from './components/front/beneficiary_list';
import RecoveredList from './components/front/recovered_list';
import NewFollowReports from './components/front/new_follow-up_reports';
import AddStore from './components/front/aad_store';
import AllStoreList from './components/front/all_store_list';
import AddDistrictStore from './components/front/addDistrictStore';
import DistrictStore from './components/front/districtStore';
import TestPerformance from './components/front/view_all_performance';


function App() {
  const isLogin =reactLocalStorage.get('isLogin');
 // console.log(isLogin);
  return (
    <>
    <BrowserRouter>
      <Routes>
         <Route path="/admin" element={<Login/>} />
         <Route path="/" element={<ChcLogin/>} />

         <Route path="/dashboard" element={<Dashboard/>} />
         <Route path="/add_user" element={<AddUser/>} />
         <Route path="/all_users" element={<AllUser/>} />
         <Route path="/edit_user/:id" element={<EditUser/>} />
         <Route path="/all_patient" element={<AllPatient/>} />
         <Route path="/patient_all_member_list/:typ/:mob" element={<AllMemberList/>} />
         <Route path="/patient_all_test_list/:typ/:pid/:mob/:name" element={<TestListOfMember/>} />
         <Route path="/patient_all_treatment_list/:typ/:pid/:mob/:name" element={<TreatmentDataOfMember/>}/>
         <Route path="/anm_mediacal_team_user/:typ/:anm_id/:mob" element={<AnmMedicalUser/>}/>
         <Route path="/view_states" element={<States/>}/>
         <Route path="/view_districts" element={<Districts/>}/>
         <Route path="/view_blocks" element={<Blocks/>}/>
         <Route path="/view_chc" element={<CHC/>}/>
         <Route path="/view_school" element={<School/>}/>
         <Route path="/view_village" element={<Villages/>}/>
         <Route path="/addChc" element={<AddChc/>}/>
         <Route path="/allChc" element={<AllChc/>}/>
         <Route path="/tranfer_beneficiary" element={<Transfer/>}/>
         <Route path="/editChcReg/:id" element={<EditChc/>} />
         <Route path="/all_hb_meter_req/" element={<HbMeter/>} />
         <Route path="/allPatient_csv/" element={<AllPatient_csv/>} />
         <Route path="/setting" element ={<Setting/>} />
         <Route path="/edit_setting/:id" element={<EditSetting/>} />
         <Route path="/addBlock" element={<AddBlockUser/>}  />
         <Route path="/allBlockUser" element={<AllBlockUser/>} />
         <Route path="/editBlockUser/:id" element={<EditBlockUser/>} />
         <Route path="/privacyPolicy" element={<Privacy/>} />
         <Route path="/view_ben_archive" element={<TransferArchive/>} />
         <Route path="/transfer_anm_medical" element={<TransferAnmMediacal/>} />
         <Route path="/view_anm_archive" element={<TransferAnmArchive/>} />
         <Route path="/reffered_cases" element={<Reffered/>} />
         <Route path="/ref_patient_all_test_list/:pid/:mob/:name" element={<RefTestListOfMember/>} />
         <Route path="/ref_patient_all_treatment_list/:pid/:mob/:name" element={<RefTreatmentDataOfMember/>}/>
         <Route path="/ref_anm_medical_team/:anm_id/:mob" element={<RefsAnm/>}/>
         <Route path="/addDistrict" element={<AddDistrictUser/>}/> 
         <Route path="/allDistrictUser" element={<AllDistrictUser/>}/> 
         <Route path="/editDistrictUser/:id" element={<EditDistrictUser/>}/> 
         <Route path="/view_sub_center" element={<SubCenter/>} />
         <Route path="/all_data_member_list/:typ/:val" element={<AllDataMemberList/>} />
         <Route path="/all_data_member_test/:pid/:mob/:name/:typ/:val" element={<AllDataMemberTest/>} />
         <Route path="/all_data_member_treatment/:pid/:mob/:name/:typ/:val" element={<AllDataMemberTreatment/>}/>
         <Route path="/all_member_anm_medical_team/:anm_id/:mob/:typ/:val" element={<MemberAnmMedicalUser/>}/>
         <Route path="/hbmeter_chc_req" element={<HbMeterChc/>}/>
         <Route path="/add_item" element={<AddItems/>} />
         <Route path="/view_item_list" element={<ItemList/>} />
         <Route path="/edit_item/:id" element={<EditItems/>}/>
         <Route path="/add_inv_item" element={<AddInvItems/>}/>
         <Route path="/view_inventory" element={<InventoryList/>}/>
         <Route path="/edit_inv_item/:id" element={<EditInvItem/>}/>
         <Route path="/add_req_inventory" element={<RequestInvItem/>}/>
         <Route path="/my_inventory_req" element={<MyInventoryReqList/>}/>
         <Route path="/inventory_req" element={<InventoryReqList/>}/>
         <Route path="/update_inv_item_waste/:id" element={<UpdateInvWasteItem/>}/>
         <Route path="/notification" element={<Notification/>}/>
         <Route path="/inv_item_state_his" element={<InventoryHistory/>}/>
         <Route path="/inventory_item_assign_wr" element={<InventoryAssign/>}/>
         <Route path="/my_chc_req" element={<MyCHCRequest/>}/>
         <Route path="/district-request" element={<MyCHCRequest />} />
         <Route path="/my_anm_req" element={<MyANMReq />} />
         <Route path="/assign_item_wr" element={<AssignInvWR/>} />
         <Route path="/assign_item_dist_wr" element={<AssignInvDistWR/>} />
         <Route path="/assign_item_chc_wr" element={<AssignInvChcWR/>} />
         <Route path="/mpr" element={<MPR/>} />
         <Route path="/allIssuedItemList" element={<AllIssuedItemList/>} />
         <Route path="/getDetailsOfIssuedItem/:id" element={<DetailOfIssuedItem/>} />
         <Route path="/edit_patient/:id" element={<EditPatient/>} />
         {/* <Route path="/test" element={<Test/>} /> */}
         <Route path="/addStates" element={<AddStates/>}  />
         <Route path="/edit_state/:id/:name" element={<AddStates/>} />
         <Route path="/addDistrict-name" element={<AddDistrictName/>}  />
         <Route path="/edit-district-name/:id/:name/:stateId" element={<AddDistrictName/>}  />
         <Route path="/addBlocks" element={<AddBlocks/>}  />
         <Route path="/edit_blocks/:id/:name/:stateId/:districtId" element={<AddBlocks/>} />
         <Route path="/add-chc" element={<ChcsName/>}  />
         <Route path="/edit_chc/:id/:name/:storeId/:blockId/:districtId" element={<ChcsName/>}  />
         <Route path="/add-sub-center" element={<AddSubCeter/>}  />
         <Route path="/edit-sub-center/:id/:name/:storeId/:blockId/:districtId/:chc" element={<AddSubCeter/>}  />
         <Route path="/add-villages" element={<AddVillages/>}  />
         <Route path="/edit-villages/:id/:name/:storeId/:blockId/:districtId" element={<AddVillages/>}  />
         <Route path="/add-schools" element={<AddSchools/>}  />
         <Route path="/edit-schools/:id/:name/:stateId/:blockId/:districtId/:village" element={<AddSchools/>}  />
         <Route path="/add-beneficiary" element={<AddBeneficiary/>}  />
         <Route path="/beneficiary-list" element={<BeneficiaryList/>}  />
         <Route path="/edit-beneficiary/:id" element={<AddBeneficiary/>}  />
         <Route path="/recovered-list" element={<RecoveredList/>}  />
         <Route path="/follow-up-list" element={<NewFollowReports/>}  />
         <Route path="/add-store" element={<AddStore/>}  />
         <Route path="/all-store-list" element={<AllStoreList/>}  />
         <Route path="/edit-store-list/:id/:mob/:stateId/:districtId/:blockId/:chc" element={<AddStore/>}  />
         <Route path="/add-district-store" element={<AddDistrictStore/>}  /> 
         <Route path="/district-store-list" element={<DistrictStore/>}  />
         <Route path="/test-performance" element={<TestPerformance/>}  />

      </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
