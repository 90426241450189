import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddItems() {

  /********************************************* */

  /******************pass & Cpass************************** */


  /********************medicalteam Hide Show************************* */

  const [hideDiv, setHideDiv] = useState(false);

  const navegate = useNavigate();
  let clickCancel = () => {
    return navegate('/');
  }

  /*****************Date****************************** */
  const [startDate, setStartDate] = useState();
  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    document.title = 'Add Items';

  }, [1]);




  /*****************submit******************************* */
  const submitUser = async (events) => {
    events.preventDefault();
    if (validate(events)) {
      const data = new FormData(events.target);

      let formData = {

        'item': data.get('item'),
        'type': data.get('type'),
        'model': data.get('model'),
        'description': data.get('description'),
        'anm_status': data.get('anm_status')

      };
      //'req_user_type' : "admin" // for approving user by default approved
      //   console.log(formData); 

      const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      const getResps = await fetch(API_URL + 'admin/add_inv_item', regDatas);
      const getDatas = await getResps.json();

      swal(getDatas.msg);

    }
  }


  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    // console.log(data.get);
    let validat = true;


    if (data.get('item') == '') {
      swal('Please enter item name');
      return validat = false;
    } else {
      let nameRegExp = "^[a-zA-Z]";
      //let testemail= emailRegExp.test(data.get('email'));
      let testname = data.get('item').match(nameRegExp);
      if (testname) {
        validat = true;
      } else {
        swal('invalid item name');
        return validat = false;
      }
    }
    if (data.get('type') == "Select") {
      swal("Please select type of item");
      return validat = false;
    } else {
      validat = true;
    }


    return validat;
  }


  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Add Items </h4>
                    <form className="form-sample" onSubmit={submitUser}>
                      <p className="card-description">

                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">

                            <label className="col-sm-12">Select Type*</label>
                            <div className="col-md-12">

                              <select className="form-control" name="type" required>
                                <option>Select</option>
                                <option value="Consumable">Consumable</option>
                                <option value="Non consumable">Non consumable</option>
                              </select>
                            </div>
                          </div>

                        </div> </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Add Items Name*</label>
                            <div className="col-sm-12">
                              <input name="item" type="text" className="form-control" autoComplete="off" required />
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Is this item visible to ANM/RBSK/Nurse*</label>
                            <div className="col-sm-12">

                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="anm_status" id="membershipRadios7" value="1" defaultChecked />
                                  Yes
                                  <i className="input-helper"></i></label>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="anm_status" id="membershipRadios8" value="0" />
                                  No
                                  <i className="input-helper"></i></label>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Model / Make </label>
                            <div className="col-md-12">
                              <input name="model" type="text" className="form-control" autoComplete="off" />

                            </div>
                          </div>
                        </div></div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">

                            <label className="col-sm-12">Description</label>
                            <div className="col-md-12">
                              <textarea name="description" className="form-control" ></textarea>
                            </div>
                          </div>

                        </div>

                      </div>


                      <button type="submit" className="btn btn-primary mr-2">Add</button>
                      <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}

export default AddItems;