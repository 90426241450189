import React, { useEffect, useState } from 'react';
import axios from "axios";
import { reactLocalStorage } from 'reactjs-localstorage';
import { Button, Modal } from "react-bootstrap";
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "../front/loading";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Moment from 'react-moment';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { param, readyException } from 'jquery';

toast.configure({ position: "top-center" });
function MyAnmReq() {
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalAccept, setModalAccept] = useState(false);
  const [listData, setListData] = useState([]);

  const [tranId, setTranId] = useState("");


  // const Error = {0:"",1:"",2:"",3:"",4:""};
  // const [error, setError] = useState(Error);

  $('#example').DataTable().destroy();
  $(document).ready(function () {
    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort'],
        "order": [[2, "desc"]]
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print']
    });
    var dt = $('#example').DataTable();
    $(".num").val(0);


    $('#example1').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort'],
        "order": [[2, "desc"]]
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print']
    });
    var dt1 = $('#example1').DataTable();
    dt1.columns([0, 1]).visible(false);



  });
  useEffect(() => {
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      document.title = "District Request";
    } else {
      document.title = "Anm Request";
    }
    getItemData();
  }, [1]
  );
  let getItemData = async () => {
    let user_type_id = '';
    if (reactLocalStorage.get("userLoginType") == "Chc" || reactLocalStorage.get("userLoginType") == "Store") {
      user_type_id = reactLocalStorage.get("user_id");
    }

    let formData = {
      'req_to': user_type_id
    };
    const res = await axios.post(API_URL + 'admin/get-all-ANM-request-list', formData).then(ress => {
      setUserData(ress.data.Rdata);
      setIsLoading(false);
    });
  }

  /******************accept item****************************** */
  let updateStatus = async (id, status, req_by, req_to, giv_qty, item_id) => {
    const dataStatusUpdate = { 'record_id': id, 'status': status, "receiver": req_by, "sender": req_to, "qty": giv_qty, "item_id": item_id }
    const res = await axios.post(API_URL + 'admin/itemReceived', dataStatusUpdate)
      .then(ress => {
        if (ress.status == '200') {
          // swal("Status Changed.");
          swal("Request accepted successfully.");
          getItemData();

        }

      });

  }
  /******************Redirecting to edit page***************************** */

  const viewList = async (trans_id) => {
    const data = { 'trans_id': trans_id }
    const res = await axios.post(API_URL + 'admin/get-requested-list', data).then(ress => {
      if (ress.status == '200') {
        setModal(true);
        setListData(ress.data.Rdata)
      }
    });
  }
  const close = () => {
    setModal(false)
  }

  /*************Accepting data and assigning item qty******************************** */

  const viewListAccept = async (trans_id) => {
    const data = { 'trans_id': trans_id }
    const res = await axios.post(API_URL + 'admin/get-requested-list', data).then(ress => {
      if (ress.status == '200') {
        setModalAccept(true);
        setListData(ress.data.Rdata);
        setTranId(trans_id);
        // console.log(tranId);
        // let len= (Object.keys(ress.data.Rdata).length);   


      }
    });
  }

  const closing = () => {
    setModalAccept(false);
  }

  const CheckAvail = async (edata, item_id, req_qty) => {
    $("#btns").attr("disabled", true);
    if (edata != "") {
      if (req_qty >= edata) {
        var inv_user = "";
        if (reactLocalStorage.get("userLoginType") == "Admin") {
          inv_user = "admin";
        } else {
          inv_user = reactLocalStorage.get("user_id");
        }


        var data = { "inv_user_id": inv_user, "qty": edata, "item_id": item_id }

        const res = await axios.post(API_URL + 'admin/check_avail_qty', data).then(ress => {
          if (ress.data.status == 201) {
            toast.error(ress.data.msg);
            $("#" + item_id).val(0);
          } else {

            $("#btns").removeAttr("disabled");
          }

        });
      }
      else {
        toast.error("Issued item Qty. must be less than or equal to Required Qty.");
        $("#" + item_id).val(0);
        $("#btns").attr("disabled", true);
      }
    }
  }

  /*************************************** */
  const disableAtt = () => {
    $("#btns").attr("disabled", true);
  }


  /*****************submit******************************* */
  const submitUser = async (events) => {
    events.preventDefault();

    //console.log(listData[0].req_by);

    let req_by_usr = listData[0].req_by;

    const data = new FormData(events.target);
    const temp = [];
    let user_type_id = '';
    if (reactLocalStorage.get("userLoginType") == "Admin") {
      user_type_id = 'admin';
    } else {
      user_type_id = reactLocalStorage.get("user_id");
    }

    //console.log(listData);

    listData.forEach(function (val, key) {
      if (data.get(val.item_id) != '') {
        temp.push([val.item_id, data.get(val.item_id)]);
      } else {
        temp.push([val.item_id, '0']);
      }
    });
    //  console.log(temp);
    // console.log(Object.keys(temp).lenght);
    // if(Object.keys(temp).length>0){

    let formData = { data: temp, req_to: user_type_id, req_by: req_by_usr, tran_id: tranId };
    const regDatas = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    }
    // console.log(regDatas);
    const getResps = await fetch(API_URL + 'admin/accept_req_for_items', regDatas);
    const getDatas = await getResps.json();
    swal(getDatas.msg);
    if (getDatas.status == 200) {
      setModalAccept(false);
      getItemData();
    }
    // }

  }



  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">

              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">{(reactLocalStorage.get("userLoginType") == 'Admin') ? "District Request" : "ANM Request"}</h4>
                   
                      <div className="table-responsive pt-3">
                        <table className="table table-bordered display  nowrap" id="example">
                          <thead>
                            <tr>
                              <th>List</th>
                              <th>Requested From</th>
                              <th>Request Type</th>
                              <th>Request Date</th>
                              <th>Issued Date</th>
                              <th>Received Date</th>

                            </tr>
                          </thead>
                          <tbody>

                            {userData.map((udata, index) => (
                              <tr key={index} className={(udata.status == 0) ? 'red' : (udata.status == 1) ? 'green' : (udata.status == 2) ? 'orange' : ''}>
                                <td> <button className="btn btn-success btn-sm cust_btn" onClick={() => viewList(udata.trans_id)}>View List</button> {udata.status == 0 ? <button className="btn btn-danger btn-sm cust_btn" onClick={() => viewListAccept(udata.trans_id)}>Issue </button> : <button className="btn btn-dark btn-sm cust_btn">Issued </button>}</td>
                                <td> {udata.req_by_name}</td>
                                <td> {udata.req_type}</td>
                                <td> {udata.date_of_req}</td>
                                <td> {udata.issued_date}</td>
                                <td> {((udata.date_of_receive == null) ? "" : udata.date_of_receive)}</td>
                              </tr>
                            )
                            )}

                          </tbody>
                        </table>
                      </div>
                   
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>

      {/*  Requested Items  */}
      <Modal size="lg" show={modal}>
        <Modal.Header closeButton onClick={close}>
          <Modal.Title id="contained-modal-title-vcenter" className="heading-request">Requested List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered display  nowrap" id="example1">
            <thead>

              <tr>
                <th>Req By</th>
                <th>Req Date</th>
                <th>Type</th>
                <th>Name</th>
                <th>Requested Qty</th>
                <th>Issued Qty</th>
              </tr>
            </thead>
            <tbody>

              {listData.map((val, key) => (
                <tr key={key}>
                  <td>{val.req_by_name}</td>
                  <td><Moment format="DD-MM-YYYY" >{val.date_of_req}</Moment></td>
                  <td>{val.type}</td>
                  <td>{val.item_name}</td>
                  <td>{val.req_qty}</td>
                  <td>{val.giv_qty}</td>
                </tr>
              )
              )}
            </tbody>
          </table>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>

      {/* Accept Requested Items  */}

      <Modal size="lg" show={modalAccept}>
        <Modal.Header closeButton onClick={closing}>
          <Modal.Title id="contained-modal-title-vcenter" className="heading-request">Requested List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="inventory-class" id="in_stock_form" onSubmit={submitUser}>
            <table className="table table-bordered padding-tr">
              <tr>
                <th>Name</th>
                <th>Requested Qty</th>
                <th>Issued Qty</th>
              </tr>
              {listData.map((val, key) => (
                <tr>
                  <td>{val.item_name}</td>
                  <td>{val.req_qty}</td>
                  <td>
                    <input id={val.item_id} className='form-control num' type="number" min="0" name={val.item_id} onClick={disableAtt} onBlur={(e) => { CheckAvail(e.target.value, val.item_id, val.req_qty); }} />
                  </td>
                </tr>
              )
              )}
            </table>
            <button id="btns" type="submit" disabled className="btn btn-primary mr-2">Issue</button>
            <button type="reset" onClick={closing} className="btn btn-light">Cancel</button>
          </form>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>

    </div>);
}

export default MyAnmReq;