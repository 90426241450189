import React, { useEffect, useState } from "react";
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import swal from "sweetalert";

function EditDistrictUser() {
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [userFormData, setUserFormData] = useState({
    mobile_no: '',
    state_id: '',
    district_id: ''
  });

  const [rad, setRad] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    document.title = 'Edit District User';
    getDataOfUser();
    getStateData();
  }, [params.id]);

  const getDataOfUser = async () => {
    const data = { 'user_id': params.id };
    const res = await axios.post(API_URL + "chc/getDistrictUserDetails", data);
    const userData = res.data.Rdata[0];
    setUserFormData(userData);
    getDistrictList(userData.state_id);
    setRad(userData.auto_analyser === 'yes');
  }

  const getStateData = async () => {
    const response = await fetch(API_URL + 'admin/state');
    const data = await response.json();
    setStateData(data);
  }

  const getDistrictList = async (state_id) => {
    const response = await fetch(API_URL + 'admin/district', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    });
    const data = await response.json();
    setDistrictData(data.Rdata);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const submitUser = async (e) => {
    e.preventDefault();
    if (validate(e)) {
      const formData = {
        mobile_no: userFormData.mobile_no,
        state_id: userFormData.state_id,
        district_id: userFormData.district_id,
        auto_analyser: 'no',
        user_id: params.id
      };

      const response = await fetch(API_URL + 'chc/editDistrictUserData', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      const result = await response.json();
      swal(result.msg);
    }
  }

  const validate = (e) => {
    const data = new FormData(e.target);
    if (!data.get('mobile_no')) {
      swal('Please enter mobile number');
      return false;
    }
    if (!data.get('mobile_no').match(/^[0-9]{10}$/)) {
      swal('Invalid mobile number');
      return false;
    }
    if (data.get('state') === 'Select') {
      swal('Please select state');
      return false;
    }
    if (data.get('district') === 'Select') {
      swal('Please select district');
      return false;
    }
    return true;
  }

  return (
    <div className="container-scroller">
      <Hor_header />
      <div className="container-fluid page-body-wrapper">
        <Ver_header />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Update District User Registration</h4>
                    <form className="form-sample" onSubmit={submitUser}>
                      <p className="card-description">User Info</p>
                      <div className="row">
                        <p> User Type : {userFormData.user_type}</p>
                      </div>
                      
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Mobile Number *</label>
                            <div className="col-sm-12">
                              <input
                                name="mobile_no"
                                type="tel"
                                pattern="[0-9]{10}"
                                className="form-control"
                                value={userFormData.mobile_no}
                                onChange={handleInputChange}
                                required
                                
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="card-description">Geographical Data</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select State *</label>
                            <div className="col-sm-12">
                              <select
                                className="form-control"
                                name="state_id"
                                onChange={(e) => {
                                  handleInputChange(e);
                                  getDistrictList(e.target.value);
                                }}
                                value={userFormData.state_id}
                               disabled
                              >
                                <option>Select</option>
                                {stateData.map(state => (
                                  <option key={state.id} value={state.id}>
                                    {state.state}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select District *</label>
                            <div className="col-sm-12">
                              <select
                                className="form-control"
                                name="district_id"
                                onChange={handleInputChange}
                                value={userFormData.district_id}
                                disabled
                              >
                                <option>Select</option>
                                {districtData.map(district => (
                                  <option key={district.id} value={district.id}>
                                    {district.name} {district.code}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary mr-2">Submit</button>
                      <button type="button" className="btn btn-light" onClick={() => navigate('/allDistrictUser')}>Cancel</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default EditDistrictUser;
