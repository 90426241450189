import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import $, { param, readyException } from 'jquery';
import { toExcel } from 'to-excel';


function Village() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  const [totalData, setTotalData] = useState();

  $('#example').DataTable().destroy();

  $(document).ready(function () {

    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print']
      //  responsive: true
    });
  });

  const customStyles = {
    header: {
      style: {
        minHeight: '35px',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#ddd',
        borderRightColor: '#ddd',

      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: '#ddd',
          borderTopColor: '#ddd',
        },
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderTopColor: '#ddd',
          borderRightColor: '#ddd',
        },
      },
    },
  };
  /*********************get parameter from url*********************************** */
  const params = useParams();

  /******************************************************** */

  useEffect(() => {
    document.title = "Village list";

    getAllSchoolData(pages);
  }, [pages]
  );

  useEffect(() => {
    getTotalSchoolData();
  }, [1]
  );
  /************Get all state data********************** */
  let getAllSchoolData = async (page) => {
    //setUserData([]); 
    let datas = { page: page, per_page: perPage };
    var url = "";

    url = 'admin/getServerSideAllVillage';



    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        //  console.log(ress.data);

        setUserData(ress.data.Rdata);
        setIsLoading(false);


      });
    //  console.log(res.data);
  }

  let getTotalSchoolData = async () => {


    let datas = {};
    //	console.log(datas);
    var url = "";

    url = 'admin/getTotalServerSideAllVillage';

    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        // console.log(ress.data.Rdata.length);
        setTotalRows(ress.data.Rdata.length);
        setTotalData(ress.data.Rdata);

      });

  }

  /****************************Delete Village******************************** */
  let deleteData = async (vals) => {
    const dataDel = { "id": vals };
    const res = await axios.post(API_URL + 'admin/delete-village', dataDel)
      .then(ress => {
        if (ress.status == '200') {
          swal("Village deleted successfully.");

        } else {
          swal("Some error occured, please try after some time. ");
        }
        getAllSchoolData(pages);
        getTotalSchoolData();

      });

  }

  let editVillage = (data, name, storeid, block, district) => {
    return navigat('/edit-villages/' + data + '/' + name + '/' + storeid + '/' + block + '/' + district);
  }


  const columns = [

    {
      name: 'Action',
      //selector: row => row.mobile_no,
      sortable: true,
      cell: (row) => <>
        <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Delete Village" onClick={() => { if (window.confirm('Do you want to delete this item?')) { deleteData(row.id) } }}>
          <i className="typcn typcn-trash text-success"></i>
        </button>

        <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Edit Village" onClick={() => { editVillage(row.id, row.name, row.state_id, row.block_id, row.district_id) }}>
          <i className="typcn typcn-edit  text-primary"></i>
        </button>
      </>

    },

    {
      name: 'Village Name',
      selector: row => row.name,
      sortable: true,

    },

    {
      name: 'Block Name',
      selector: row => row.block_name,
      sortable: true,

    },
    {
      name: 'District Name',
      selector: row => row.district_name,
      sortable: true,
    },
    {
      name: 'State Name',
      selector: row => row.state,
      sortable: true,

    },
    {
      name: "Country",
      selector: row => "India",
      sortable: true,
    }

  ];

  const fetchUsers = async page => {
    setLoading(true);
    getAllSchoolData(page);
    setLoading(false);
  };

  const handlePageChange = page => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    getAllSchoolData(page)
    setPerPage(newPerPage);
    setLoading(false);
  };

  var headers = [
    { label: 'Village Name', field: 'name' },
    { label: 'Block Name', field: 'block_name' },
    { label: 'District Name', field: 'district_name' },
    { label: 'State Name', field: 'state' },
    { label: 'Country', field: 'India' },



  ]

  const dwn = () => {
    var content = toExcel.exportXLS(headers, totalData, 'All Village List');

  }

  // const handlePrint = () => {
  //   const originalContents = document.body.innerHTML;
  //   const printContents = document.getElementById('print-table').innerHTML;

  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // };


  let AllVillageData = async (seeach) => {

    let datas = { page: pages, per_page: perPage, search: seeach };
    var url = "";

    url = 'admin/getSearchServerSideAllVillage';

    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        //  console.log(ress.data);

        setUserData(ress.data.Rdata);
        setIsLoading(false);


      });
    //  console.log(res.data);
  }

  let TotalVillageData = async (serach) => {

    let datas = { search: serach };

    var url = "";

    url = 'admin/getSearchServerSideAllVillageTotal';

    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        // console.log(ress.data.Rdata.length);
        setTotalRows(ress.data.Rdata.length);
        setTotalData(ress.data.Rdata);

      });

  }

  const handleSercahBox = (e) => {
    const inputValue = e.target.value;
    setSearchText(inputValue);
    AllVillageData(inputValue);
    TotalVillageData(inputValue);

  }

  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
          {isLoading ? (<Loader />) : (
            <>
              <div className="content-wrapper">
                <div className="row">


                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">All Villages </h4>


                        <>
                          <div className='checkdtTable'>
                            <div className="table-responsive pt-3">
                              <div className='btntopSection'>
                                <div className='btns-tophead'>
                                  {/* <button className="customTableBtns" >Copy</button> */}
                                  <button className="customTableBtns" onClick={dwn}>CSV</button>
                                  {/* <button className="customTableBtns" onClick={handlePrint}>Print</button> */}
                                  <Link to="/add-villages" className="customTableBtns customwithtbtn" >Add Village</Link>
                                </div>
                                <div className='cusSearch-box'>
                                  <label>Search:
                                    <input type='text' value={searchText} onChange={handleSercahBox} />
                                  </label>
                                </div>
                              </div>
                              <div className='searchToptable'>

                                <div id="print-table">
                                  <DataTable
                                    columns={columns}
                                    data={userData}
                                    customStyles={customStyles}
                                    progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                  //  expandableRows = {true}
                                  //  expandableRowsComponent={ExpandedComponent}
                                  />
                                </div>

                              </div>
                            </div>
                          </div>
                        </>


                      </div>
                    </div>
                  </div>


                </div>
              </div>


              <Footer />
            </>
          )}

        </div>

      </div>

    </div>);
}

export default Village;