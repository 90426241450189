import React from 'react';


function Footer(){
return(<>
{/*<footer className="footer" style={{"marginTop":"-15px"}}>*/}
<footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between"></div>
          </footer>

</>);
}

export default Footer ;