import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from './loading';
import { reactLocalStorage } from 'reactjs-localstorage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { toExcel } from 'to-excel';

import $, { readyException } from 'jquery';


function AllMemberList() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pages, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [totalData, setTotalData] = useState();


  // $('#example').DataTable().destroy();
  /*  
  $(document).ready(function () {
   
     $('#example').DataTable({
         destroy: true,
        'aoColumnDefs': [{
          'bSortable': false,
          'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: [ 'copy', 'csv', 'excel', 'pdf', 'print'],
      responsive: true
    });
    var dt = $('#example').DataTable();
     dt.columns([3]).visible(false);
  });   */
  /*********************get parameter from url*********************************** */
  const params = useParams();

  /******************************************************** */

  useEffect(() => {
    document.title = "Benificary Data";
    getAllPatientData();
  }, [1]
  );

  useEffect(() => {

    getAllPatientData_limit(pages);

  }, [pages]);


  /************Get all patient user********************** */
  let getAllPatientData = async () => {
    // console.log(params.val);
    var fromDate = ""; var toDate = ""; var datas = "";
    if (params.val == 'custom') {
      datas = { 'typeOfData': params.typ, 'btype': params.val, 'fromDate': reactLocalStorage.get("fromDate"), 'toDate': reactLocalStorage.get("toDate") };
    }
    else {
      datas = { 'typeOfData': params.typ, 'btype': params.val };
    }
    var url = "";
    if (reactLocalStorage.get('userLoginType') == 'Admin') {
      url = 'admin/allTypeOfMemberData';
    } else {
      url = 'chc/allTypeOfMemberData';
      datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
    }
    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        // console.log(ress.data);
        //  setUserData(ress.data.Rdata);
        //  setIsLoading(false);
        setTotalRows(ress.data.Rdata.length);
        setTotalData(ress.data.Rdata);

      });
    //  console.log(res.data);
  }


  /************Get all patient user********************** */
  let getAllPatientData_limit = async (page) => {
    // console.log(params.val);
    setUserData([]);
    var fromDate = ""; var toDate = ""; var datas = "";
    if (params.val == 'custom') {
      datas = { 'typeOfData': params.typ, 'btype': params.val, 'fromDate': reactLocalStorage.get("fromDate"), 'toDate': reactLocalStorage.get("toDate"), 'page': page, 'per_page': perPage };
    }
    else {
      datas = { 'typeOfData': params.typ, 'btype': params.val, 'page': page, 'per_page': perPage };
    }
    var url = "";
    if (reactLocalStorage.get('userLoginType') == 'Admin') {
      url = 'admin/allTypeOfMemberData_server';
    } else {
      url = 'chc/allTypeOfMemberData_server';
      datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
    }
    const res = await axios.post(API_URL + url, datas)
      .then(ress => {
        // console.log(ress.data);
        setUserData(ress.data.Rdata);
        setIsLoading(false);
      });
    //  console.log(res.data);
  }




  /***************** on click of mobile no************************************* */

  const getTestResult = (pid, mob, name) => { //console.log(('/ref_patient_all_test_list/'+pid+'/'+mob+'/'+name));
    return navigat('/ref_patient_all_test_list/' + pid + '/' + mob + '/' + name);
  }
  /***************** Anm details************************************ */

  const getAnmDetails = (anm_id, patient_mob) => {
    return navigat('/anm_mediacal_team_user/' + anm_id + '/' + patient_mob);
  }

  /*****************Back************************ */
  let backto = () => {
    return navigat('/all_patient/');
  }
  /****************************************** */


  /********************************************************************* */


  var headers = [
    { label: 'Beneficiary Type', field: 'beneficiary_type' },
    { label: 'Name', field: 'name' },
    { label: 'Patient id', field: 'patient_id' },
    { label: 'Gender', field: 'gender' },
    { label: 'Pregnancy Duration', field: 'preg_duration' },
    { label: 'HB Value', field: 'haemoglobin_value' },
    { label: 'HB Status', field: 'haemoglobin_based_status' },
    { label: 'Reffered To', field: 'reffered_center' },
    { label: 'Test At', field: 'venue' },
    { label: 'Age', field: 'age' },
    { label: 'Aadhar No.', field: 'aadhar_no' },
    { label: 'Mobile No', field: 'mobile_no' },
    { label: 'Address', field: 'address' },
    { label: 'District', field: 'district' },
    { label: 'Block', field: 'block' },
    { label: 'CHC/PHC', field: 'chc' },
    { label: 'Village', field: 'village' },
    { label: 'School', field: 'school' },
    { label: 'Added On', field: 'added_on' },

  ]


  const dwn = () => {
    var content = toExcel.exportXLS(headers, totalData, 'BenificaryDataList');

  }


  /*********************************************************** */

  const fetchUsers = async page => {
    setLoading(true);
    getAllPatientData_limit(page);
    setLoading(false);
  };

  const handlePageChange = page => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    getAllPatientData_limit(page)
    setPerPage(newPerPage);
    setLoading(false);
  };



  const columns = [
    {
      name: 'Beneficiary Type',
      selector: row => row.beneficiary_type,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => <Link to={'/all_data_member_test/' + row.patient_id + '/' + row.mobile_no + '/' + row.name + '/' + params.typ + '/' + params.val} >{row.name}</Link>,
      sortable: true,
    },
    {
      name: 'Gender',
      selector: row => row.gender,
      sortable: true,
      cell: (row) => <> {row.gender != null ? row.gender.toUpperCase() : ""}</>

    },

    {
      name: 'Pregnancy Duration',
      selector: row => row.preg_duration,
      sortable: true,

    },
    {
      name: 'HB Value',
      selector: row => row.haemoglobin_value,
      sortable: true,
    },
    {
      name: 'HB Status',
      selector: row => row.haemoglobin_based_status,
      sortable: true,
      cell: (row) => <>{row.haemoglobin_based_status != null ? row.haemoglobin_based_status.toUpperCase() : ""}</>
    },
    {
      name: "Reffered To",
      selector: row => row.reffered_center,
      sortable: true,

    },
    {
      name: "Test At",
      selector: row => row.venue,
      sortable: true,
    },
    {
      name: "Age",
      selector: row => row.age,
      sortable: true,

    },
  ];

  const ExpandedComponent = ({ data }) => {
    return (

      <>
        <table className='table table-striped  sadi'>
          <tr><td> <b>Aadhar No. - </b> {data.aadhar_no}</td></tr>
          <tr><td> <b>Guardian Name - </b> {data.guardian_name}</td></tr>
          <tr><td> <b>Mobile No. - </b> {data.mobile_no}</td></tr>
          <tr><td> <b>Address</b>  - {data.address}</td></tr>
          <tr><td> <b>District - </b> {data.district}</td></tr>
          <tr><td> <b>Block - </b> {data.block}</td></tr>
          <tr><td> <b>CHC/PHC - </b> {data.chc}</td></tr>
          <tr><td> <b>Village - </b> {data.village}</td></tr>
          <tr><td> <b>School - </b> {data.school}</td></tr>
          <tr><td> <b>Added On - </b> <Moment format="DD-MM-YYYY" >{data.added_on}</Moment></td></tr>
          <tr><td> <b>Action - </b>  <Link to={'/all_data_member_test/' + data.patient_id + '/' + data.mobile_no + '/' + data.name + '/' + params.typ + '/' + params.val} >View Test Details</Link></td></tr>
        </table>
      </>

    )


  }




  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">


              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Benificary Data</h4>
                    <button className="btn btn-success" onClick={dwn} >Export</button>
                  
                      <div className="table-responsive pt-3">
                        <DataTable
                          columns={columns}
                          data={userData}
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          expandableRows={true}
                          expandableRowsComponent={ExpandedComponent}
                        />
                      </div>
                    
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>

    </div>);
}

export default AllMemberList;


