import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { param, readyException } from 'jquery';


function CHC() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
 

  $('#example').DataTable().destroy();

  $(document).ready(function () {

    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print', {
        text: 'Add CHC',
        className: 'btn btn-xxm',
        action: function (e, dt, button, config) {
            window.open("/add-chc","_self");
        }
    }],
    
      initComplete: function() { 
        var btns = $('.dt-button');
        btns.removeClass('dt-button');
      },
        //responsive: true
    });
  });
  /*********************get parameter from url*********************************** */
  const params = useParams();

  /******************************************************** */

  useEffect(() => {
    document.title = "CHC list";
    getAllChcData();
  }, [1]
  );
  /************Get all state data********************** */
  let getAllChcData = async () => {
    // const datas = {'pid':params.pid} ;
    //  console.log(datas);
    const res = await axios.post(API_URL + 'admin/getAllCHC')
      .then(ress => {
        //console.log(ress.data);
        setUserData(ress.data.Rdata);
        setIsLoading(false);
      
      });
    //  console.log(res.data);
  }

  let deleteData = async (vals) => {
    const dataDel = { "id": vals };
    const res = await axios.post(API_URL + 'admin/delete-chc', dataDel)
      .then(ress => {
        if (ress.status == '200') {
          swal("CHC deleted successfully.");

        } else {
          swal("Some error occured, please try after some time. ");
        }
        getAllChcData();

      });

  }

  let editChc = (data, name, storeid, block, district) => {
    return navigat('/edit_chc/' + data + '/' + name + '/' + storeid + '/' + block + '/' + district);
  }


  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">


              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">All CHCs </h4>

                   

                      <>
                        {/* <Link to="/add-chc" className="addButton">Add CHC</Link> */}
                        <div className="table-responsive pt-3">
                          {/* <table className="table table-bordered display responsive nowrap" id="example"> */}
                          <table className="table table-bordered display  nowrap" id="example">
                            <thead>
                              <tr>
                                <th className="nosort"> Action </th>
                                <th>CHC Name </th>
                                <th>Block Name </th>
                                <th>District Name </th>
                                {/* <th>District Code </th> */}
                                <th>State Name </th>
                                <th>Country</th>

                              </tr>
                            </thead>
                            <tbody>

                              {userData.map((udata, index) => (
                                <tr key={index}>
                                  <td>
                                    <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Delete CHC" onClick={() => { if (window.confirm('Do you want to delete this item?')) { deleteData(udata.id) } }} >
                                      <i className="typcn typcn-trash text-success"></i>
                                    </button>

                                    <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Edit CHC" onClick={() => { editChc(udata.id, udata.name, udata.state_id, udata.block_id, udata.district_id) }}>
                                      <i className="typcn typcn-edit  text-primary"></i>
                                    </button></td>
                                  <td> {udata.name}</td>
                                  <td> {udata.block_name}</td>
                                  <td> {udata.district_name}</td>
                                  {/* <td> {udata.dis_code}</td> */}
                                  <td> {udata.state}</td>
                                  <td> India </td>


                                </tr>
                              )
                              )}

                            </tbody>
                          </table>
                        </div>
                      </>
                    
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>

    </div>);
}

export default CHC;