import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { param, readyException } from 'jquery';


function AnmArchive() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  $('#example').DataTable().destroy();

  $(document).ready(function () {

    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
      responsive: true
    });
  });
  /*********************get parameter from url*********************************** */
  const params = useParams();

  /******************************************************** */

  useEffect(() => {
    document.title = "Transfer Beneficiary Archives";
    getAllBenArchiveData();
  }, [1]
  );
  /************Get all state data********************** */
  let getAllBenArchiveData = async () => {
    // const datas = {'pid':params.pid} ;
    //  console.log(datas);
    const res = await axios.post(API_URL + 'admin/anmTransfer_archive')
      .then(ress => {
        console.log(ress.data);
        setUserData(ress.data.Rdata);
        setIsLoading(false);
      });
    //  console.log(res.data);
  }




  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
        {isLoading ? (<Loader />) : (
          <>
          <div className="content-wrapper">
            <div className="row">


              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Transferred Anm/Medical Team Data</h4>
                   
                      <div className="table-responsive pt-3">
                        {/* <table className="table table-bordered display responsive nowrap" id="example"> */}
                        <table className="table table-bordered display  nowrap" id="example">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name </th>
                              {/* <th>Anm/mediacl team id </th> */}
                              <th>Mobile </th>
                              <th>Transfer from </th>
                              <th>Transfer To</th>
                              <th>Transfer Date</th>
                            </tr>
                          </thead>
                          <tbody>

                            {userData.map((udata, index) => (
                              <tr key={index}>
                                <td> </td>
                                <td> {udata.anm_medical_name}</td>
                                {/* <td> {udata.anm_medical_id}</td> */}
                                <td> {udata.mobile}</td>
                                <td> State - {udata.state_from}, District - {udata.district_from}, Block - {udata.block_from}, CHC/PHC - {udata.chc_from}</td>
                                <td> State - {udata.state_to}, District - {udata.district_to}, Block - {udata.block_to}, CHC/PHC - {udata.chc_to}</td>
                                <td><Moment format="DD-MM-YYYY">{udata.transfer_date}</Moment></td>

                              </tr>
                            )
                            )}

                          </tbody>
                        </table>
                      </div>
                   
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />
          </>
          )}
        </div>

      </div>

    </div>);
}

export default AnmArchive;