import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';


import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { readyException } from 'jquery';

import ResetModal from "./resetPasswordModel";



function AllDistrictUser() {

  const navigat = useNavigate();

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showLogin, setShowLogin] = useState(false);
  const [mobid, setMobid] = useState();

  $('#example').DataTable().destroy();

  $(document).ready(function () {


    $('#example').DataTable({
      destroy: true,
      'aoColumnDefs': [{
        'bSortable': false,
        'aTargets': ['nosort']
      }],
      dom: 'Bfrtip',
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
      responsive: true
    });
  });

  useEffect(() => {
    document.title = "All District Users";
    getAllUsersData();
  }, []
  );
  /************Get all register user********************** */
  let getAllUsersData = async () => {

    const res = await axios.get(API_URL + 'chc/getAllRegisteredDistrictUser')
      .then(ress => {
        // console.log(ress.data);

        setUserData(ress.data.Rdata);

        setIsLoading(false);
      });
    // console.log(res.data);EXIT;
  }
  /******************Delete user****************************** */
  let deleteData = async (vals) => {
    const dataDel = { "user_id": vals };

    const res = await axios.post(API_URL + 'chc/deleteDistrictUserData', dataDel)
      .then(ress => {
        if (ress.status == "200") {
          swal("User Deleted");
        } else {
          swal("Some error occured, please try after some time. ");
        }
        getAllUsersData();

      });

  }
  /************Update CHC Activation status ********************** */
  let updateStatus = async (vals, status) => {
    const dataStatusUpdate = { 'user_id': vals, 'status': status }
    const res = await axios.post(API_URL + 'chc/updateDistrictStatus', dataStatusUpdate)
      .then(ress => {
        if (ress.status == '200') {
          swal("Status Changed.");
          getAllUsersData();
        }

      });

  }
  /******************Redirecting to edit page***************************** */
  let editUser = (data) => {
    //console.warn("id="+data);
    return navigat('/editDistrictUser/' + data);
  }


  return (

    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">

        <Ver_header />

        <div className="main-panel">
          {isLoading ? (<Loader />) : (
            <>
              <div className="content-wrapper">
                <div className="row">


                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">All District User Lists</h4>

                        <div className="table-responsive pt-3">
                          {/* <table className="table table-bordered display responsive nowrap" id="example"> */}
                          <table className="table table-bordered display  nowrap" id="example">
                            <thead>
                              <tr>
                                {/* <th className="nosort"> S.No.</th> */}
                                <th> Status </th>
                                <th className="nosort"> Action </th>
                                <th> District Name</th>
                                <th> User Type </th>
                                {/* <th> User Id </th> */}
                                <th> Mobile </th>
                                {/* <th>Auto Analyser</th>  */}
                                {/* <th> Created On </th> */}

                              </tr>
                            </thead>
                            <tbody>

                              {userData.map((udata, index) => (
                                <tr key={index} >
                                  <td>
                                    <button className={udata.status ? 'btn btn-success btn-sm cust_btn' : 'btn btn-danger btn-sm cust_btn'} onClick={() => { if (window.confirm(udata.status ? 'Are you sure to Inactivate user?' : 'Are you sure to Activate user?')) { updateStatus(udata.user_id, udata.status ? '0' : '1') } }}  >{udata.status ? 'Active' : 'Inactive'}</button>
                                  </td>

                                  <td>
                                    <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Delete User" onClick={() => { if (window.confirm('Delete the item?')) { deleteData(udata.user_id) } }}>
                                      <i className="typcn typcn-trash text-success"></i>
                                    </button>

                                    <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Edit User" onClick={() => { editUser(udata.user_id) }}>
                                      <i className="typcn typcn-edit  text-primary"></i>
                                    </button>
                                    <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon" title="Reset password" onClick={() => { setShowLogin(true); setMobid(udata.mobile_no); }}>
                                      <i className="typcn typcn-key-outline  text-warning"></i>
                                    </button>
                                  </td>
                                  <td> {udata.district_name}</td>
                                  <td> {udata.user_type != null ? udata.user_type.toUpperCase() : ""}</td>
                                  {/* <td> {udata.user_id }</td> */}
                                  <td> {udata.mobile_no}</td>
                                  {/* <td>{udata.auto_analyser}</td> */}
                                  {/* <td><Moment format="DD-MM-YYYY">{udata.created_on }</Moment> </td> */}


                                </tr>

                              ))}

                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                  <ResetModal show={showLogin} data={mobid} close={() => setShowLogin(false)} />

                </div>
              </div>

              <Footer />
            </>
          )}
        </div>

      </div>

    </div>




  );

}

export default AllDistrictUser;