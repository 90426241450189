import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import $, { readyException } from 'jquery';
import { toExcel } from 'to-excel';
import { format } from 'date-fns';

function AllFollowUpReports() {

    const navigat = useNavigate();

    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // const [stateData, setStateData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const [chcData, setChcData] = useState([]);
    const [staffData, setStaffData] = useState('all');
    const [typ, setTyp] = useState({ ftype: "all", id: "" });

    const [pages, setPage] = useState(1);

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [totalData, setTotalData] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);



    useEffect(() => {
        document.title = "Follow-Up List";
        getTotalPatientData()
        getDistrictList();
        getExportPatientData();
    }, [1]
    );

    useEffect(() => {

        getAllPatientData(pages);

    }, [pages]);


    const getFilterUserData = () => {
        getTotalPatientData()
        getAllPatientData(pages);
        setPerPage(10);
        getExportPatientData();
    }



    /********District based on state*********** */
    let getDistrictList = async (e) => {
        setDistrictData([]);
        //   let state_id = e.target.value;
        let state_id = 1;
        const regDataDistrict = {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'state_id': state_id })
        }
        const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
        const getdataDistrict = await getDistrict.json();
        // console.log(getdataDistrict.Rdata);
        if (getdataDistrict.Rdata.length > 0) {
            setDistrictData(getdataDistrict.Rdata);

        }

    }
    /********Block based on district*********** */
    let getBlock = async (e) => {
        setBlockData([]);
        let district_id = e.target.value;
        //   console.log(district_id);
        const reDataDistrict = {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'district_id': district_id })
        }
        const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
        const getBlockData = await getBlock.json();
        //console.log(getBlockData);
        if (getBlockData.Rdata.length > 0) {
            setBlockData(getBlockData.Rdata);

        }

    }



    /****************Get Chc on block**************************** */

    let getChc = async (e) => {
        setChcData([]);

        let block_id = e.target.value;
        //console.log(district_id);
        const reDataBlock = {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'block_id': block_id })
        }
        /************chc************** */
        const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
        const getChcData = await getChc.json();
        //console.log(getBlockData);
        if (getChcData.Rdata.length > 0) {
            setChcData(getChcData.Rdata);

        }


    }

    /*****************csv export************************ */

    let getExportPatientData = async () => {


        let datas = { ftype: typ.ftype, data: typ.id, staff: staffData, fromDate: startDate ? format(startDate, 'dd-MM-yyyy') : null, toDate: endDate ? format(endDate,'dd-MM-yyyy'):null };
        //	console.log(datas);
        var url = "";
        if (reactLocalStorage.get('userLoginType') == 'Admin') {
            url = 'admin/getFollowUpBeneficiary_export_csv';
        } else {
            url = 'chc/getFollowUpBeneficiary_export_csv';
            datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
        }
        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                // console.log(ress.data.Rdata[0].total_r);
                setTotalData(ress.data.Rdata);

            });

    }



    /************Get total user********************** */
    let getTotalPatientData = async () => {


        let datas = { ftype: typ.ftype, data: typ.id, staff: staffData, fromDate: startDate ? format(startDate, 'dd-MM-yyyy') : null, toDate: endDate ? format(endDate, 'dd-MM-yyyy'):null };
        //	console.log(datas);
        var url = "";
        if (reactLocalStorage.get('userLoginType') == 'Admin') {
            url = 'admin/getFollowUpBeneficiaryTotal';
        } else {
            url = 'chc/getFollowUpBeneficiaryTotal';
            datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
        }
        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
               
                setTotalRows(ress.data.Rdata[0].totolRec);

            });

    }



    /************Get all patient user********************** */
    let getAllPatientData = async (page) => {
        setUserData([]);

        let datas = { ftype: typ.ftype, data: typ.id, staff: staffData, page: page, per_page: perPage, fromDate: startDate ? format(startDate, 'dd-MM-yyyy') : null, toDate: endDate? format(endDate, 'dd-MM-yyyy'):null };


        var url = "";
        if (reactLocalStorage.get('userLoginType') == 'Admin') {
            url = 'admin/getFollowUpBeneficiaryAllData';
        } else {
            url = 'chc/getFollowUpBeneficiaryAllData';
            datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }
        }

        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                // console.log(ress.data.Rdata[0].total_r);
                //console.log("result", ress.data.Rdata);

                setUserData(ress.data.Rdata);
                setIsLoading(false);
            });

    }

    /***************** on click of mobile no************************************* */

    let getMobileNo = (typ, data) => {
        navigat('/patient_all_member_list/' + typ + '/' + data);
    }


    const fetchUsers = async page => {
        setLoading(true);
        getAllPatientData(page);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        getAllPatientData(page)
        setPerPage(newPerPage);
        setLoading(false);
    };




    var headers = [
        { label: 'Name', field: 'name' },
        { label: 'Beneficiary Type', field: 'beneficiary_type' },
        { label: 'Patient id', field: 'patient_id' },
        { label: 'Family Head Name', field: 'guardian_name' },
        { label: 'Mobile No', field: 'mobile_no' },
        { label: 'Gender', field: 'gender' },
        { label: 'Caste', field: 'caste' },
        { label: 'Age id', field: 'age' },
        { label: 'Pregnancy Duration', field: 'preg_duration' },
        { label: 'Address', field: 'address' },
        { label: 'Staff Type', field: 'staff_type' },
        { label: 'Added By Anm/Medical Team Id', field: 'anm_med_nur_name' },
        { label: 'Rch id', field: 'rch_uid' },
        { label: 'District', field: 'district_name' },
        { label: 'Block', field: 'block_name' },
        { label: 'CHC/PHC', field: 'chc_name' },
        { label: 'Village', field: 'village_name' },
        { label: 'School', field: 'school_name' },
        { label: 'Haemoglobin value', field: 'haemoglobin_value' },
        { label: 'Haemoglobin based status', field: 'haemoglobin_based_status' },
        { label: 'Height', field: 'height' },
        { label: 'Weight', field: 'weight' },
        { label: 'BMI value', field: 'bmi_value' },
        { label: 'BMI Status', field: 'bmi_category' },
        { label: 'Pregnancy Duration(At time of test)', field: 'pregnancy_duration' },
        { label: 'Latitude', field: 'ts_lat' },
        { label: 'Longitude', field: 'ts_log' },
        { label: 'Venue', field: 'venue' },
        { label: 'Current Address', field: 'curr_address' },
        { label: 'Referral status', field: 'referral_status' },
        { label: 'Referred At', field: 'referred_center_name' },
        { label: 'Test done on', field: 'investigated_on' },
        { label: 'Test Done By (Not ref.)', field: 'anm_med_nur_name' },
        { label: 'Test Done By (ref.)', field: 'test_hos_name' },
        { label: 'Referred By Hospital', field: 'referred_by_hos_name' },
        { label: 'Medician', field: 'medician' },
        { label: 'Dose', field: 'dose' },
        { label: 'Treatment given by', field: 'treat_giv_by_name' },
        { label: 'Follow up date', field: 'follow_up_date' },
        { label: 'Treatment Given on', field: 'treatment_on' },
        { label: 'Added On', field: 'added_on' },


    ]


    const dwn = () => {

        var content = toExcel.exportXLS(headers, totalData, 'AllFllowUpReport');
        // in node you must open or save the content
        //require('fs').writeFileSync('filename.xls', content);


    }


    const ExpandedComponent = ({ data }) => {
        return (

            <>

                <table className='table table-striped  sadi'>
                    <tr><td> <b>Pregnancy Duration - </b>  {data.preg_duration}</td></tr>

                    <tr><td> <b>Address</b>  - {data.address}</td></tr>
                    <tr><td> <b>Staff Type - </b> {data.staff_type}</td></tr>
                    <tr><td> <b>Added By Anm/Medical Team Id - </b> {data.anm_med_nur_name}</td></tr>
                    <tr><td> <b>Rch id - </b> {data.rch_uid}</td></tr>
                    <tr><td> <b>District - </b> {data.district_name}</td></tr>
                    <tr><td> <b>Block - </b> {data.block_name}</td></tr>
                    <tr><td> <b>CHC/PHC - </b> {data.chc_name}</td></tr>
                    <tr><td> <b>Village - </b> {data.village_name}</td></tr>
                    <tr><td> <b>School - </b> {data.school_name}</td></tr>
                    <tr><td> <b>Haemoglobin value - </b> {data.haemoglobin_value}</td></tr>
                    <tr><td> <b>Haemoglobin based status - </b> {data.haemoglobin_based_status != null ? data.haemoglobin_based_status.toUpperCase() : ""}</td></tr>

                    <tr><td> <b>Height - </b> {data.height}</td></tr>
                    <tr><td> <b>Weight - </b> {data.weight}</td></tr>
                    <tr><td> <b>BMI value - </b> {data.bmi_value}</td></tr>
                    <tr><td> <b>BMI Status - </b>{data.bmi_category}</td></tr>
                    <tr><td> <b>Pregnancy Duration(At time of test) - </b> {data.pregnancy_duration}</td></tr>
                    <tr><td> <b>Geo Location - Test - </b>  Latitude - {data.ts_lat} , Longitude - {data.ts_log}</td></tr>
                    <tr><td> <b>Venue - </b> {data.venue}</td></tr>
                    <tr><td> <b>Current Address - </b> {data.curr_address}</td></tr>
                    <tr><td> <b>Referral status - </b> {data.referral_status == 'reffered' ? "Referred" : "Not Referred"}</td></tr>
                    <tr><td> <b>Referred At - </b> {data.referred_center_name}</td></tr>
                    <tr><td> <b>Test done on - </b> <Moment format="DD-MM-YYYY" >{data.investigated_on}</Moment></td></tr>
                    <tr><td> <b>Test Done By - </b> {data.referral_status == 'reffered' ? data.test_hos_name : data.anm_med_nur_name}</td></tr>
                    <tr><td> <b>Referred By Hospital - </b> {data.referred_by_hos_name}</td></tr>
                    <tr><td> <b>Medician - </b> {data.medician}</td></tr>
                    <tr><td> <b>Dose - </b> {data.dose}</td></tr>
                    <tr><td> <b>Treatment given by - </b>{data.treat_giv_by_name}</td></tr>
                    <tr><td> <b>Age - </b> {data.age}</td></tr>
                    <tr><td> <b>Treatment Given on - </b> <Moment format="DD-MM-YYYY" >{data.treatment_on}</Moment></td></tr>
                    <tr><td> <b>Added On - </b> <Moment format="DD-MM-YYYY" >{data.added_on}</Moment></td></tr>
                </table>
            </>

        )


    }




    const columns = [

        {
            name: 'Name',
            selector: row => row.name,
            //   cellExport: row => row.name,
            sortable: true,
            // width: '110px',

        },

        {
            name: 'Beneficiary Type',
            selector: row => row.beneficiary_type,
            //   cellExport: row => row.beneficiary_type,
            sortable: true,

        },
        {
            name: 'Patient id',
            selector: row => row.patient_id,
            sortable: true,
            //   cellExport: row => row.patient_id,
            // width: '100px',
        },
        {
            name: 'Family Head Name',
            selector: row => row.guardian_name,
            //  cellExport: row => row.guardian_name,
            sortable: true,

        },

        {
            name: 'Mobile No',
            selector: row => row.mobile_no,
            sortable: true,
            //width: '75px',
            //  cellExport: row =>row.mobile_no,
            cell: (row) => <Link to={'/patient_all_member_list/rp/' + row.mobile_no}>{row.mobile_no}</Link>
        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true,
            //   cellExport: row =>row.gender,
            cell: (row) => <>{row.gender != null ? row.gender.toUpperCase() : ""}</>,

        },
        {
            name: "Caste",
            selector: row => row.caste,
            sortable: true,
            // cellExport: row =>row.caste,
            cell: (row) => <>{row.caste != null ? row.caste.toUpperCase() : ""}</>,

        },
        {
            name: "Follow up date",
            selector: row => row.follow_up_date,
            sortable: true,


        },



    ];

    // const handleDateChange = (date) => {
    //    // setSelectedDate(date);
    //     if (date) {
    //       const formatted = format(date, 'dd-MM-yyyy');
    //       return formatted;
    //     } else {
    //      return null;
    //     }
    //   };

     

    const handleStartDateChange = (date) => {
        //console.log("data", date, );
        setStartDate(date);
    };

    


    const handleEndDateChange = (date) => {
        setEndDate(date);
    };





    return (

        <div className="container-scroller">

            <Hor_header />

            <div className="container-fluid page-body-wrapper">

                <Ver_header />

                <div className="main-panel">
                    {isLoading ? (<Loader />) : (
                        <>
                            <div className="content-wrapper">

                                <div className="row">

                                    <div className="col-md-2">
                                        <div className="form-group row">
                                            <label className="col-sm-12">Select District </label>
                                            <div className="col-sm-12">
                                                <select className="form-control" name="district" onChange={(e) => { getBlock(e); setTyp({ ftype: 'district_id', id: e.target.value }); }} required>
                                                    <option value='0' >Select</option>
                                                    {districtData.map(district => (
                                                        ((reactLocalStorage.get("userLoginType") == "Chc") || (reactLocalStorage.get("userLoginType") == "Block") || (reactLocalStorage.get("userLoginType") == "District")) ? ((reactLocalStorage.get("district") == district.id) ? <option value={district.id}>{district.name} </option> : "") : <option value={district.id}>{district.name} </option>

                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group row">
                                            <label className="col-sm-12">Select Block </label>
                                            <div className="col-sm-12">
                                                <select className="form-control" name="block" onChange={(e) => { getChc(e); setTyp({ ftype: 'block_id', id: e.target.value }); }} required>
                                                    <option value='0'>Select</option>
                                                    {blockData.map(blocks => (
                                                        ((reactLocalStorage.get("userLoginType") == 'Chc') || (reactLocalStorage.get("userLoginType") == 'Block')) ? (reactLocalStorage.get("block") == blocks.id) ? <option value={blocks.id}>{blocks.name}</option> : "" : <option value={blocks.id}>{blocks.name}</option>

                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group row">
                                            <label className="col-sm-12">Select CHC/PHC </label>
                                            <div className="col-sm-12">
                                                <select className="form-control" name="chc" onChange={(e) => { setTyp({ ftype: 'chc_phc_id', id: e.target.value }); }} required>
                                                    <option value='0'>Select</option>
                                                    {chcData.map(chc => (
                                                        (reactLocalStorage.get("userLoginType") == 'Chc') ? ((reactLocalStorage.get("userType") == chc.id) ? <option value={chc.id}>{chc.name}</option> : "") : <option value={chc.id}>{chc.name}</option>

                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group row">
                                            <label className="col-sm-12">Select Staff Type </label>
                                            <div className="col-sm-12">
                                                <select className="form-control" name="userType" onChange={(e) => { setStaffData(e.target.value) }} required>
                                                    <option value="all">Select</option>
                                                    <option value="anm">ANM</option>
                                                    <option value="medicalteam">RBSK Medical Team</option>
                                                    <option value="nurse">Staff Nurse</option>
                                                    <option value="LT">LT</option>
                                                    <option value="CHO">CHO</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 date-selection">
                                        <div class="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">Select Date </label>
                                                    <div className="col-sm-12">
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={handleStartDateChange}
                                                            name="dob"
                                                            dateFormat="dd-MM-yyyy"
                                                            dropdownMode="select"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            adjustDateOnChange
                                                            minDate={new Date('01-01-1950')}
                                                            maxDate={new Date()}
                                                            placeholderText="From"
                                                            className="form-control"
                                                            id="dobpicker"
                                                            tabIndex="5"
                                                            onKeyDown={e => e.preventDefault()}
                                                            autoComplete="off"
                                                            

                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12">End Date </label>
                                                    <div className="col-sm-12">
                                                        <DatePicker
                                                            selected={endDate}
                                                            onChange={handleEndDateChange}
                                                            name="dob"
                                                            dateFormat="dd-MM-yyyy"
                                                            dropdownMode="select"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            adjustDateOnChange
                                                            minDate={new Date('01-01-1950')}
                                                            maxDate={new Date()}
                                                            placeholderText="To"
                                                            className="form-control"
                                                            id="dobpicker"
                                                            tabIndex="5"
                                                            onKeyDown={e => e.preventDefault()}
                                                            autoComplete="off"

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-12 text-right">
                                        <div className="form-group row">

                                            <div className="col-sm-12">
                                                <button className="btn btn-primary" onClick={getFilterUserData} >Go</button>&nbsp;
                                                <button className="btn btn-success" onClick={dwn} >Export</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">


                                    <div className="col-lg-12 grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="card-title">Follow-Up Reports</h4>


                                                <div className="table-responsive pt-3">



                                                    {/* <DataTableExtensions
                                                        columns={columns}
                                                        data={userData}
                                                         print={false}>      */}

                                                    <DataTable

                                                        columns={columns}
                                                        data={userData}
                                                        progressPending={loading}
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={totalRows}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}
                                                        expandableRows={true}
                                                        expandableRowsComponent={ExpandedComponent}
                                                    //fixedHeader
                                                    //fixedHeaderScrollHeight="500px"
                                                    />
                                                    {/* </DataTableExtensions> */}


                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <Footer />
                        </>
                    )}
                </div>

            </div>

        </div>);
}

export default AllFollowUpReports;