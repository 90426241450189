import React , {useEffect,useState} from "react";
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import $, { readyException } from 'jquery';
import { v4 as uuid } from 'uuid';
function AddInvItemsRequest(){
  const [hideDiv, setHideDiv] = useState(false);
  const [itemAV, setItemAV] = useState([]);
  const [high, setHigh] = useState([]);
  const [reqTo, setReqTo] = useState([]);
  const [len,setLen]=useState(0);
  const navegate = useNavigate();
  let clickCancel =()=>{
    return navegate('/');
  }
  const [startDate, setStartDate] = useState();
  useEffect( () => {
     document.title='Add Request For Inventory';
     getInvItems();
     //getListOfHigherLevel();
  },[1]);
  let getInvItems = async () => {
    const res = await axios.post(API_URL+'admin/getInvItems').then(ress => {
      setItemAV(ress.data.Rdata);
    });
  }
  /*let getListOfHigherLevel = async () => {
    let disid=''; 
    let type_of_hospital="";
    if(reactLocalStorage.get("userLoginType")=="Chc"){
      disid=reactLocalStorage.get("block");
        var usrName = reactLocalStorage.get("user_name").split("-");
        type_of_hospital = $.trim(usrName[0]);
      }
      let datas={"dist":disid ,"type_of_hospital":type_of_hospital}
      const res = await axios.post(API_URL+'admin/getListOfHigherLevel',datas).then(ress => {
        var len= (ress.data.Rdata.length);
        if(len>0){
          setLen(1);
        }
        setHigh(ress.data.Rdata);
      });
    }*/
    const submitRequest = async(events)=>{
      events.preventDefault();
      const data = new FormData(events.target);
        const temp = [];
        let user_type_id = reactLocalStorage.get("user_id");
        let send_to = '';
        let send_type = '';
        if( reactLocalStorage.get('userLoginType') == 'Chc' || reactLocalStorage.get("userLoginType") == "Store"){
          send_to = reactLocalStorage.get("district");
          send_type = 'district';
        } 
        if( reactLocalStorage.get('userLoginType') == 'District'  || reactLocalStorage.get("userLoginType") == "DistrictStore" ){
          send_to = 'admin';
          send_type = 'Admin';
        } 
        itemAV.forEach(function(val, key){
          if(data.get(val.id) > 0){
            temp.push([val.id, data.get(val.id)]);
          }          
        });
        let formData = {
          data: temp,
          user_id:user_type_id,
          req_to:send_to,
          req_user_typ:send_type,
          req_user_by:reactLocalStorage.get('user_name'),
          unique_id : uuid()
        };
        const reqData = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData)
        }
        const getResps = await fetch(API_URL+'admin/send-request-to-higher',reqData);
        const getDatas = await getResps.json();
        swal(getDatas.msg);
        if(getDatas.status == 200){
          document.getElementById("request_form").reset();
        }
    }
    return (
      <div className="container-scroller">
      <Hor_header/>
      <div className="container-fluid page-body-wrapper">
        <Ver_header/>
        <div className="main-panel">        
          <div className="content-wrapper">
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Request For Inventory</h4>
                    <form className="inventory-class" id="request_form" onSubmit={submitRequest}>
                    <p><b>Consumable</b></p>
                    {itemAV.map( itm => (
                      (itm.type == "Consumable")?
                      <div className="form-inline">
                        <div className="form-group">
                          <label >{itm.item_name}</label>
                          <input type="number" min="0" className="form-control" name={itm.id} placeholder={itm.item_name} />
                        </div>
                      </div>:""
                      ))}
                    <hr />
                      <p><b>Non Consumable</b></p>
                      {itemAV.map( itm => (
                        (itm.type == "Non consumable")?
                        <div className="form-inline">
                          <div className="form-group">
                            <label >{itm.item_name}</label>
                            <input type="number" min="0" className="form-control" name={itm.id} placeholder={itm.item_name} />
                          </div>
                        </div>:""
                        ))}
                      <br />
                      <button type="submit" className="btn btn-primary mr-2">Submit</button>
                      <button type="reset" className="btn btn-light">Cancel</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </div>
    );
}

export default AddInvItemsRequest;