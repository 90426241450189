import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import { reactLocalStorage } from 'reactjs-localstorage';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import $, { readyException } from 'jquery';

function AssignInventoryItem() {

  /********************************************* */
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [chcData, setChcData] = useState([]);

  const [chcName, setChcName] = useState("");

  //console.log("test",chcName);
  /******************pass & Cpass************************** */


  /********************medicalteam Hide Show************************* */

  const [hideDiv, setHideDiv] = useState(false);
  const [itemAV, setItemAV] = useState([]);
  const [high, setHigh] = useState([]);

  //const [reqBy, setReqBy] = useState('');
  const [reqTo, setReqTo] = useState([]);

  const navegate = useNavigate();
  let clickCancel = () => {
    return navegate('/');
  }

  /*****************Date****************************** */
  const [startDate, setStartDate] = useState();
  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    document.title = 'Issue inventory items without request';
    getInvItems();
    //  getListOfHigherLevel();
    getStateData();
    //getMyInvItem();
  }, [1]);



  /********State*********** */
  let getStateData = async () => {
    const regDataState = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' }
    }
    const getState = await fetch(API_URL + 'admin/state', regDataState);
    const getdatastate = await getState.json();
    setStateData(getdatastate);
  }

  /********District based on state*********** */
  let getDistrictList = async (e) => {
    setDistrictData([]);
    let state_id = e.target.value;
    const regDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    }
    const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
    const getdataDistrict = await getDistrict.json();
    // console.log(getdataDistrict.Rdata);
    if (getdataDistrict.Rdata.length > 0) {
      setDistrictData(getdataDistrict.Rdata);
    }
  }
  /********Block based on district*********** */
  let getBlock = async (e) => {
    setBlockData([]);
    let district_id = e.target.value;
    //console.log(district_id);
    const reDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'district_id': district_id })
    }
    const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
    const getBlockData = await getBlock.json();
    //console.log(getBlockData);
    if (getBlockData.Rdata.length > 0) {
      setBlockData(getBlockData.Rdata);
    }

  }



  /****************Get Chc ,school,village based on block**************************** */

  let getChc = async (e) => {
    setChcData([]);
    let block_id = e.target.value;
    //console.log(district_id);
    let hospital_type = "";
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      hospital_type = 'Admin';
    }
    if (reactLocalStorage.get("userLoginType") == 'Chc') {
      let hospital = reactLocalStorage.get("user_name").split("-");
      hospital_type = $.trim(hospital[0]);
    }


    const reDataBlock = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'block_id': block_id, "type_of_hospital": hospital_type })
    }
    /************chc************** */
    const getChc = await fetch(API_URL + "admin/getHospitalsChc", reDataBlock);
    const getChcData = await getChc.json();
    //console.log(getBlockData);
    if (getChcData.Rdata.length > 0) {
      setChcData(getChcData.Rdata);
    }

  }







  /******************get all inventory items ******************** */
  let getInvItems = async () => {

    const res = await axios.post(API_URL + 'admin/getInvItems')
      .then(ress => {
        //console.log(ress.data);
        setItemAV(ress.data.Rdata);
        // setIsLoading(false);
      });
    //  console.log(res.data);
  }

  /******************list of higher level ******************** */
  /* let getListOfHigherLevel = async () => {
    let disid='';
    if(reactLocalStorage.get("userLoginType")=="Chc"){
        disid=reactLocalStorage.get("block");
      //  console.log(disid);
    }

    

   let datas={"dist":disid
      //"req_by":reactLocalStorage.get("userType")
    }
    const res = await axios.post(API_URL+'admin/getListOfHigherLevel',datas)
     .then(ress => {
           //console.log(ress.data);
           setHigh(ress.data.Rdata);
         // setIsLoading(false);
      });
        //  console.log(res.data);
   }    */

  /******************get all inventory items ******************** */

  /*****************submit******************************* */
  const submitUser = async (events) => {
    events.preventDefault();
    if (validate(events)) {
      const data = new FormData(events.target);
      let user_type_id = '';
      if (reactLocalStorage.get("userLoginType") == "Admin") {
        user_type_id = 'admin';
      }
      if (reactLocalStorage.get("userLoginType") == "Chc") {
        user_type_id = reactLocalStorage.get("user_id");
      }

      let req_by_data = data.get('chc').split("&&");
      let req_by = $.trim(req_by_data[0]);
      let req_user_by = $.trim(req_by_data[1]);

      //   console.log(reqTo);
      let formData = {
        'req_to': user_type_id,
        'item_id': data.get('item'),
        'req_by': req_by,
        'qty': data.get('qty'),
        'req_user_typ': "hospital",
        'req_user_to': reactLocalStorage.get('user_name'),
        'req_user_by': req_user_by
      };
      //  console.log(formData);

      const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      //   const getResps = await fetch(API_URL+'admin/req_for_item',regDatas);
      const getResps = await fetch(API_URL + 'admin/assign_items_wr', regDatas);

      const getDatas = await getResps.json();

      swal(getDatas.msg);

    }
  }


  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    // console.log(data.get);
    let validat = true;


    if (data.get('item') == "Select") {
      swal("Please select item");
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('qty') == "") {
      swal("Please enter quantity");
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('chc') == "Select") {
      swal("Please select Chc");
      return validat = false;
    } else {
      validat = true;
    }


    return validat;
  }


  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Issue Inventory Item </h4>
                    <form className="form-sample" onSubmit={submitUser}>
                      <p className="card-description">

                      </p>


                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select State *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="state" onChange={getDistrictList} required>
                                <option>Select State</option>
                                {stateData.map(states => (
                                  <option value={states.id}>{states.state}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select District *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="district" onChange={getBlock} required>
                                <option>Select</option>
                                {districtData.map(district => (
                                  ((reactLocalStorage.get("userLoginType") == "Chc") || (reactLocalStorage.get("userLoginType") == "Block") || (reactLocalStorage.get("userLoginType") == "District")) ? ((reactLocalStorage.get("district") == district.id) ? <option value={district.id}>{district.name} </option> : "") : <option value={district.id}>{district.name} </option>

                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select Block *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="block" onChange={getChc} required>
                                <option>Select</option>
                                {blockData.map(blocks => (
                                  ((reactLocalStorage.get("userLoginType") == 'Chc') || (reactLocalStorage.get("userLoginType") == 'Block')) ? (reactLocalStorage.get("block") == blocks.id) ? <option value={blocks.id}>{blocks.name}</option> : "" : <option value={blocks.id}>{blocks.name}</option>

                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select CHC/PHC *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="chc" onchange={(e) => { setChcName(e.target.label) }} required>
                                <option>Select</option>
                                {chcData.map(chc => (
                                  //(reactLocalStorage.get("userLoginType")=='Chc')?((reactLocalStorage.get("userType")==chc.id)?<option value={chc.id}>{chc.name}</option>:""):<option value={chc.id}>{chc.name}</option>
                                  <option value={chc.user_id + " && " + chc.name}>{chc.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                      </div>



                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group row">


                            <label className="col-sm-12">Select Item*</label>
                            <div className="col-md-12">

                              <select className="form-control" name="item" required>
                                <option>Select</option>
                                {itemAV.map((itm, inx) => (
                                  <option key={inx} value={itm.id}>{itm.item_name}</option>

                                ))}
                              </select>
                            </div>
                          </div>

                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Quantity*</label>
                            <div className="col-md-12">
                              <input name="qty" type="number" className="form-control" min="0" autoComplete="off" required />
                            </div>
                          </div>
                        </div>

                      </div>


                      <button type="submit" className="btn btn-primary mr-2">Send </button>
                      <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}

export default AssignInventoryItem;