import React, { useRef, useLayoutEffect } from 'react';
//import logo from './logo.svg';
//import './App.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);


function AmPiesRef(props) {


  const{options}=props;
     
    
  let chart = am4core.create("chartdivsRef", am4charts.PieChart);
  chart.data=options; 
  // Add and configure Series
  let pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = "val";
  pieSeries.dataFields.category = "category";
  pieSeries.colors.list = [
      am4core.color("#845EC2"),
      am4core.color("#97b12c"),
      am4core.color("#FF6F91"),
      am4core.color("#8ab2db"),
      am4core.color("#FFC75F"),
      am4core.color("#F9F871"),
  ];

//chart.innerRadius = am4core.percent(50);
//menu
chart.exporting.menu = new am4core.ExportMenu();
// Put a thick white border around each Slice
pieSeries.slices.template.stroke = am4core.color("#4a2abb");
pieSeries.slices.template.strokeWidth = 1;
pieSeries.slices.template.strokeOpacity = 5;

pieSeries.labels.template.text ="{value.percent.formatNumber('#.0')}%";
pieSeries.labels.template.maxWidth = 130;
pieSeries.labels.template.wrap = true;
pieSeries.labels.template.fontSize = 12;

//pieSeries.legendSettings.labelText = "{category} : {value.percent.formatNumber('#.0')}%";
pieSeries.legendSettings.labelText = "{category}";

// Add a legend
 chart.legend = new am4charts.Legend();
 //chart.legend.labels.template.maxWidth = 100;
chart.legend.labels.template.truncate = false;
chart.legend.labels.template.wrap = true;
chart.legend.labels.template.fontSize = 12;

chart.legend.valueLabels.template.disabled = true;

let markerTemplate = chart.legend.markers.template;
markerTemplate.width = 8;
markerTemplate.height = 8;

//remove logo
am4core.addLicense("ch-custom-attribution");

    return (
      <div id="chartdivsRef" style={{ width: "100%", height: "220px" }}></div>
    );
}
export default AmPiesRef;